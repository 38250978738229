import { Component, Input, Output, EventEmitter, ChangeDetectionStrategy, ChangeDetectorRef, OnDestroy, OnChanges, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

import { HeaderService } from '@/services/header.service';
import { StorageKey, StorageService } from '@/services/storage.service';
import { RouteObserverService } from '@/services/route-observer.service';
import { AuthService } from '@/services/auth.service';
import { Agence, AgenceGroupe, Annonce, ConnectedUser, ListDisplayMode } from '@/models';
import { MainRoutes } from '@/constants';

@Component({
  selector: 'app-annonce-card',
  templateUrl: './annonce-card.component.html',
  styleUrls: ['./annonce-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AnnonceCardComponent implements OnChanges, OnDestroy, OnInit {
  @Input() annonce: Annonce;
  @Input() listAnnonces: boolean;
  @Input() groupe?: AgenceGroupe;
  @Input() agence?: Agence;
  @Input() profile: boolean;
  @Input() displayMode?: ListDisplayMode;

  @Output() hidePropertyButtonClickEmt: EventEmitter<number> = new EventEmitter();
  @Output() showTab = new EventEmitter();

  private subscriptions = new Subscription();

  public MainRoutes = MainRoutes;

  public sold = false;
  public emptyHeart = true;
  public activeImageIndex = 0;
  public alreadySeen = false;
  public annonceLink?: string;
  public connectedUser: ConnectedUser;

  constructor(
    private routeObserver: RouteObserverService,
    private storageService: StorageService,
    private headerService: HeaderService,
    private authService: AuthService,
    private cd: ChangeDetectorRef
  ) {
    this.sold = this.routeObserver.currentRoute.url.endsWith('/vendu');
  }

  ngOnInit(): void {
    this.subscriptions.add(
      this.authService.connectedUser$.subscribe((user) => {
        this.connectedUser = user;
        this.cd.markForCheck();
      })
    );
  }

  ngOnChanges(): void {
    if (!this.profile) {
      const annoncesListSeen: number[] = this.storageService.getObject(StorageKey.AlreadySeen) ?? [];
      this.alreadySeen = annoncesListSeen.indexOf(this.annonce.id) > -1;
    }

    if (this.groupe) {
      this.annonceLink = `${MainRoutes.Groupes}/${this.groupe.slug}${MainRoutes.Annonce}/${this.annonce.id}`;
    } else if (this.agence) {
      this.annonceLink = `/${this.agence.slug}${MainRoutes.Annonce}/${this.annonce.id}`;
    } else {
      this.annonceLink = `${MainRoutes.Annonce}/${this.annonce.id}`;
    }

    this.emptyHeart = !this.annonce.is_favorite;
    this.cd.markForCheck();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  removeFromfavoris(ev: Event): void {
    ev.stopPropagation();

    if (this.connectedUser) {
      this.authService.removeFavorisFromUserAccount(this.annonce.id).subscribe(() => {
        this.emptyHeart = !this.emptyHeart;
        this.cd.markForCheck();
      });
    } else {
      this.headerService.showNeedConnectModal();
    }
  }

  addToFavoris(ev: Event): void {
    ev.stopPropagation();

    if (this.connectedUser) {
      this.authService.addFavorisToUserAccount(this.annonce.id).subscribe(() => {
        this.emptyHeart = !this.emptyHeart;
        this.cd.markForCheck();
      });
    } else {
      this.headerService.showNeedConnectModal();
    }
  }

  hideProperty(annonceId: number): void {
    this.hidePropertyButtonClickEmt.emit(annonceId);
  }

  previousImage(): void {
    this.activeImageIndex =
			this.activeImageIndex > 0
			  ? this.activeImageIndex - 1
			  : ((this.annonce.images && this.annonce.images.little.length) || this.annonce.photo.length) - 1;
  }

  nextImage(): void {
    this.activeImageIndex =
			this.activeImageIndex <
			((this.annonce.images && this.annonce.images.little.length) || this.annonce.photo.length) - 1
			  ? this.activeImageIndex + 1
			  : 0;
  }

  onImgError(event: any): void {
    event.target.src = '/assets/imgs/erabw.png';
  }

  showTabAnnonce(annonceId): void {
    this.showTab.emit(annonceId);
  }
}
