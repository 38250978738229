<div class="img-agence {{  agenceImmobiliere ? 'immo' : '' }}">
	<div class="cursor-pointer" [routerLink]="['/' + agence.slug]">
		<div class="red-tag annonces-transition display-text-12px" *ngIf="agence?.nb_annonces && agence?.nb_annonces > 0">
			<span>{{ agence?.nb_annonces_vente }} {{ 'properties_to_sell' | transloco | uppercase }}</span>
		</div>
		<img
			[src]="agence?.images?.little || agence?.photo ? agence?.images?.little || agence?.photo : '/assets/imgs/agence.jpg'"
			(error)="onImgError($event)"
			class="w-full object-cover"
			alt=""
		/>
	</div>
</div>

<div class="my-10px flex flex-col flex-wrap">
	<div class="flex w-full min-h-260px">
		<div class="cursor-pointer flex flex-col">
			<hgroup>
				<a [routerLink]="['/' + agence.slug]">
					<h3 *ngIf="agence.statut !== 2; else statut" class="display-text-18px mb-14px enseigne">
						{{ agence?.enseigne }}
					</h3>
					<ng-template #statut>
						<h3 class="display-text-18px mb-14px enseigne">
							({{ 'shortly' | transloco }}) ERA Immobilier {{ agence?.ville }}, {{ agence?.codepostal }}
						</h3>
					</ng-template>
				</a>
				<div class="mt-auto mb-30px">
					<div class="adresse flex mb-12px items-start min-h-50px">
						<span class="icon-location fs-20px mr-10px grey-icon"></span>
						<h4 *ngIf="agence.adresse">
							@if (agence.adresse) {
								{{ agence?.adresse }}, {{ agence?.codepostal }}, {{ agence?.ville }}
							} @else {
								{{ 'opening' | transloco }}
							}
						</h4>
					</div>
					<app-agence-horaires [now]="true" [horaires]="agence?.horaires" />
				</div>
			</hgroup>
		</div>
	</div>

	<div class="contact-content display-text-16px w-full">
		<app-button-telephone
			[telephone]="agence?.telephone"
			aspect="arrow-blue"
			size="large"
		/>
		<app-button
			[link]="['/' + agence.slug]"
			aspect="arrow-blue"
			size="large"
			text="web"
		/>
	</div>
</div>
