import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { Agence } from '@/models';

@Component({
  selector: 'app-statement-agence-card',
  templateUrl: './statement-agence-card.component.html',
  styleUrls: ['./statement-agence-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StatementAgenceCardComponent {
  @Input() agence!: Agence;
  @Input() notes: number[] = [];

  showMore: boolean;
}
