import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { VendreResponse } from '@/models';
import { ConfigService } from './config.service';

@Injectable({
  providedIn: 'root'
})
export class VendreService {
  private baseUrl: string;

  public address_name: string;
  public city_name: string;
  public city_zip: number;
  public postcode: number;

  constructor(
    private http: HttpClient,
    configService: ConfigService
  ) {
    this.baseUrl = `${configService.config.url}/estimations`;
  }

  getNotation(estimation_id, rating_level): Observable<any> {
    return this.http.post(`${this.baseUrl}/notation?estimation_id=${estimation_id}&rating_level=${rating_level}`, null);
  }

  getEstimation(details: any): Observable<VendreResponse> {
    return this.http.post<VendreResponse>(this.baseUrl, details);
  }
}
