import { Component, Input, Output, EventEmitter } from '@angular/core';

import { Agence, Annonce, Negociateur } from '@/models';
import { MainRoutes } from '@/constants';

@Component({
  selector: 'app-contact-agency-card',
  templateUrl: './contact-agency-card.component.html',
  styleUrls: ['./contact-agency-card.component.scss']
})
export class ContactAgencyCardComponent {
  @Input() annonce?: Annonce;
  @Input() agence?: Agence;
  @Input() pathUrl?: string;
  @Input() team?: Negociateur;
  @Input() annoncesAgenceMere?: Annonce[];

  @Output() openModalLegalNotice = new EventEmitter();

  public MainRoutes = MainRoutes;

  averageStar(): string | undefined {
    if (this.annonce && this.annonce.agence.avis) {
      const total = this.annonce.agence.avis.map((score) => score.avg_score).reduce((a, b) => a + b, 0);
      const sum = total / this.annonce.agence.avis.length;

      return sum.toFixed(2);
    }

    if (this.agence && this.agence.avis) {
      const total = this.agence.avis.map((score) => score.avg_score).reduce((a, b) => a + b, 0);
      const sum = total / this.agence.avis.length;

      return sum.toFixed(2);
    }

    return undefined;
  }

  onOpenModalLegalNotice() {
    this.openModalLegalNotice.emit();
  }
}
