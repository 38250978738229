import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { BlogImmobilierService } from '@/services/blog.service';
import { ContentService } from '@/services/content.service';
import { AnnoncesService } from '@/services/annonces.service';
import { AgencesService } from '@/services/agences.service';
import { ConfigService } from '@/services/config.service';
import { Actualite, SearchFilters, SeoFaqEntry } from '@/models';
import { ANNONCE_STATE } from '@/constants';

@Component({
  selector: 'app-metiers',
  templateUrl: './metiers.component.html',
  styleUrls: ['./metiers.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MetiersComponent implements OnInit, OnDestroy {
  private subscriptions = new Subscription();

  public metier: string;
  public actualites: Actualite[];
  public faqs: SeoFaqEntry[] = [];
  public isOpen = false;
  public gestion_locative;
  public syndic_corpro;
  public locaux_commerciaux;
  public annonces = [];
  public metiers;
  public topAvisAgences;
  public agence;
  public eraAgenceIds = [];
  public pathUrl;
  public source = 'SOURCE.SITE_NATIONAL';
  public url_photos_from_BO: string;
  public totalBiens = 0;
  public allAvisCount;
  private is_groupe = false;

  public typeBiens = {
    particulier: [
      'Maison',
      'Appartement',
      'Duplex',
      'Terrain',
      'Bien de Vacances',
      'Commerce',
      'Immeuble',
      'Propriété',
      'Ferme',
      'Parking-Box'
    ],
    professionnel: [
      'Local commercial',
      'Fonds de commerce',
      'Bureaux',
      'Entrepôts',
      'Terrains',
      'Cession de bail',
      'Murs'
    ]
  };

  constructor(
    private route: ActivatedRoute,
    private agencesService: AgencesService,
    private blogService: BlogImmobilierService,
    private annoncesService: AnnoncesService,
    private contentService: ContentService,
    private cd: ChangeDetectorRef,
    configService: ConfigService,
    router: Router
  ) {
    this.url_photos_from_BO = configService.config.url_photos_from_BO;
    this.pathUrl = router.url;
  }

  ngOnInit(): void {
    const slug = this.route.snapshot.paramMap.get('slug');
    const { metier } = this.route.snapshot.data;
    this.metier = metier;

    if (slug) {
      const agenceId = +(slug.split(/[-]+/).pop());

      if (agenceId) {
        if (this.pathUrl.startsWith('/groupes')) {
          this.is_groupe = true;
          this.agenceGroupesById(agenceId);
          this.topAvisAllAgences(agenceId);
        } else {
          this.agenceById(agenceId);
          this.topAvisAllAgences(agenceId);
        }
        this.getAnnoncesHomePage(agenceId);
      }
    } else {
      if (this.metier === 'gestion locative') {
        this.getActualites(null, '4');
      } else if (this.metier === 'gestion de syndic') {
        this.getActualites(null, '5');
      } else {
        this.topAvisAllAgences();
      }

      this.agencesAvisCount();
      this.getAnnoncesHomePage(null);
    }

    this.getContentGestionLocative();
    this.getContentSyndicCopro();
    this.getContentLocauxCommerciaux();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  agenceById(agenceId) {
    this.subscriptions.add(
      this.agencesService.getAgenceById(agenceId).subscribe((data) => {
        this.agence = data.data;

        if (this.metier === 'gestion locative') {
          this.getActualites(this.agence.era_id, '4');
        } else if (this.metier === 'gestion de syndic') {
          this.getActualites(this.agence.era_id, '5');
        } else {
          this.getActualites(this.agence.era_id, null);
        }

        this.cd.markForCheck();
      })
    );
  }

  agenceGroupesById(id: number) {
    this.subscriptions.add(
      this.agencesService.getAgenceGroupesById(id).subscribe((data) => {
        this.agence = data.data;

        for (let i = 0; i < this.agence.agences.length; i++) {
          this.eraAgenceIds.push(this.agence.agences[i].era_id);
        }

        if (this.metier === 'gestion locative') {
          this.getActualites(this.eraAgenceIds, '4');
        } else if (this.metier === 'gestion de syndic') {
          this.getActualites(this.eraAgenceIds, '5');
        } else {
          this.getActualites(this.eraAgenceIds.toString(), null);
        }

        this.cd.markForCheck();
      })
    );
  }

  agencesAvisCount() {
    this.subscriptions.add(
      this.agencesService.getAllAgenceAvis().subscribe((data) => {
        this.allAvisCount = data.nbr_avis;
        this.cd.markForCheck();
      })
    );
  }

  topAvisAllAgences(agenceId: number = null) {
    this.subscriptions.add(
      this.agencesService.getTopAvisAllAgences(agenceId).subscribe((data) => {
        this.topAvisAgences = data.slice(0, 2);
        this.cd.markForCheck();
      })
    );
  }

  getContentGestionLocative() {
    this.subscriptions.add(
      this.contentService.getContentPages('GESTION_LOCATIVE').subscribe((data) => {
        this.gestion_locative = data.GESTION_LOCATIVE;
        this.cd.markForCheck();
      })
    );
  }

  getContentSyndicCopro() {
    this.subscriptions.add(
      this.contentService.getContentPages('SYNDIC_COPROPRIETE').subscribe((data) => {
        this.syndic_corpro = data.SYNDIC_COPROPRIETE;
        this.cd.markForCheck();
      })
    );
  }

  getContentLocauxCommerciaux() {
    this.subscriptions.add(
      this.contentService.getContentPages('LOCAUX_COMMERCIAUX').subscribe((data) => {
        this.locaux_commerciaux = data.LOCAUX_COMMERCIAUX;

        for (const [key, value] of Object.entries(this.locaux_commerciaux)) {
          const index = key.slice(-1);
          const content = this.locaux_commerciaux[`accordeon_title_${index}`];

          if (key.startsWith('accordeon_content') && content) {
            this.faqs.push({
              question: content,
              reponse: value as string
            });
          }
        }

        this.cd.markForCheck();
      })
    );
  }

  getActualites(eraId = null, tags = null) {
    this.subscriptions.add(
      this.blogService.getActualites(2, 1, 'desc', 'published_at', eraId ? 'SOURCE.AGENCE' : 'SOURCE.ERA', tags, eraId).subscribe((data) => {
        this.actualites = data.data;
        this.cd.markForCheck();
      })
    );
  }

  getAnnoncesHomePage(agenceId: number) {
    const options: SearchFilters = {
      type_annonce: 'vente',
      exclusif: false,
      coup_coeur: false,
      typeBien: this.typeBiens.professionnel
    };

    this.subscriptions.add(
      this.annoncesService.getAnnonces(4, 'desc', ANNONCE_STATE, options, false, agenceId, this.is_groupe).subscribe((data) => {
        this.totalBiens = data.meta.total;
        this.annonces = data.data;
        this.cd.markForCheck();
      })
    );
  }
}
