<div class="contact-content display-text-16px">
  <app-button-telephone
    aspect="arrow-blue"
    [telephone]="phone"
    size="large"
  />

  @if (contact) {
    <app-button
      [link]="contact"
      aspect="arrow-blue"
      text="contact"
      size="large"
    />
  }

  @if (team && team.status && isList) {
    <app-button
      [text]="(team.civilite === 'Monsieur') ? 'see_advisor_male' : 'see_advisor_female'"
      [link]="MainRoutes.Effectifs + '/' + (team.website_slug ?? team.id)"
      [fragment]="pathUrl.startsWith('/agence-') ? 'agence' : undefined"
      size="large"
    />
  }
</div>
