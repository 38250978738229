@if (agence) {
  <div class="statement-agence-card">
    <div class="statement-card-title">
      <div class="statement-agence-card-title-photo">
        <img src="{{ agence.photo ?? '/assets/imgs/agence.jpg' }}" alt="" />
      </div>
      <div class="statement-agence-card-star" [routerLink]="['/' + agence.slug + '/avis']">
        <app-rating-stars [rate]="agence.nbr_avis" />
        <span>{{ agence.nbr_avis | number:'':'fr-FR' }} {{ 'avis' | transloco }}</span>
      </div>
      <div class="mb-20px">
        @if (agence.enseigne) {
          <p class="display-text-20px enseigne">{{ agence.enseigne }}</p>
        }
      </div>
    </div>

    <div class="statement-agence-card-general">
      <div class="hours" [hidden]="!agence.horaires">
        <div class="icon-clock grey-icon"></div>
        <div>
          <div class="statement-hours">
            <p class="display-text-16px">
              Lundi-Vendredi :
              {{
                agence.horaires && agence.horaires.length === 7 && agence.horaires[1].statut === 'o'
                  ? agence.horaires[1].horaires.length > 1
                    ? agence.horaires[1].horaires[0].substring(0, 5) +
                      '-' +
                      agence.horaires[1].horaires[1].substring(6, 11)
                    : agence.horaires[1].horaires[0].substring(0, 5) +
                      '-' +
                      agence.horaires[1].horaires[0].substring(6, 11)
                  : ('closed' | transloco)
              }}
            </p>

            <p class="display-text-16px">
              Samedi :
              {{
                agence.horaires && agence.horaires.length === 7
                  ? agence.horaires[5].statut === 'f'
                    ? ('closed' | transloco)
                    : agence.horaires[5].horaires.length > 1
                    ? agence.horaires[5].horaires[0].startsWith('-')
                      ? agence.horaires[5].horaires[1].substring(0, 11)
                      : agence.horaires[5].horaires[0].substring(0, 5) +
                        '-' +
                        agence.horaires[5].horaires[1].substring(6, 11)
                    : agence.horaires[5].horaires[0].startsWith('-')
                    ? ('closed' | transloco)
                    : agence.horaires[5].horaires[0].substring(0, 5) +
                      '-' +
                      agence.horaires[5].horaires[0].substring(6, 11)
                  : ('closed' | transloco)
              }}
            </p>

            <p class="display-text-16px">
              Dimanche :
              {{
                agence.horaires && agence.horaires.length === 7
                  ? agence.horaires[6].statut === 'f'
                    ? ('closed' | transloco)
                    : agence.horaires[6].horaires.length > 1
                    ? agence.horaires[6].horaires[0].startsWith('-')
                      ? agence.horaires[6].horaires[1].substring(0, 11)
                      : agence.horaires[6].horaires[0].substring(0, 5) +
                        '-' +
                        agence.horaires[6].horaires[1].substring(6, 11)
                    : agence.horaires[6].horaires[0].startsWith('-')
                    ? ('closed' | transloco)
                    : agence.horaires[6].horaires[0].substring(0, 5) +
                      '-' +
                      agence.horaires[6].horaires[0].substring(6, 11)
                  : ('closed' | transloco)
              }}
            </p>
          </div>
        </div>
      </div>
      <div class="address">
        <div class="flex mb-12px items-center">
          <span class="icon-location fs-20px mr-10px grey-icon"></span>
          <p class="display-text-16px adresse">
            {{ agence.adresse }} <span class="display-block">{{ agence.codepostal }} {{ agence.ville }}</span>
          </p>
        </div>
      </div>
    </div>

    @if (agence.opinion_id) {
      <div class="statement-agence-card-recommandation">
        <app-button
          href="https://www.opinionsystem.fr/fr-fr/certificate/{{ agence.opinion_id }}#review"
          text="see_attestation"
          aspect="arrow-red"
          size="large"
        />
      </div>
    }

    <div class="statement-agence-card-coords">
      <app-button-telephone
        [telephone]="agence.telephone"
        aspect="arrow-red"
      />
      <app-button
        [link]="'/' + agence.slug"
        aspect="arrow-red"
        text="web"
      />
    </div>
  </div>
}
