export const SEARCH_MIN_LENGTH = 3;
export const SEARCH_DEBOUNCE = 300;

export const CRITERES: { key: string, value: string, icon?: string, img?: string }[] = [
  { key: 'kitchen', value: 'critere_cuisine' },
  { key: 'box_garage', value: 'critere_garage', icon: 'icon-parking' },
  { key: 'cellar', value: 'critere_cave', img: '/assets/imgs/box-gray.svg' },
  { key: 'elevator', value: 'critere_ascenseur' },
  { key: 'balcony', value: 'critere_balcon', icon: 'icon-balcon' },
  { key: 'garden', value: 'critere_jardin' },
  { key: 'terrace', value: 'critere_terrasse', icon: 'icon-terrasse' },
  { key: 'swimming_pool', value: 'critere_piscine' },
  { key: 'seaview', value: 'critere_vue_mer', img: '/assets/imgs/box-gray.svg' },
  { key: 'works', value: 'critere_travaux' },
  { key: 'handicap_access', value: 'acces_handicape', img: '/assets/imgs/norme-pmr-gray.svg' },
  { key: 'new', value: 'critere_neuf' },
  { key: 'prestige', value: 'prestige' }
] as const;

export const CRITERES_FLAT = CRITERES.reduce((o, { key, value, icon, img }) => ({ ...o, [value]: { key, icon, img } }), {});
