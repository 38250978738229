@if (annonce) {
	@let image = annonce.images?.little?.length ? annonce.images.little[activeImageIndex] : annonce.photo[activeImageIndex];
	@let typeBien = annonce.type_bien.toLowerCase();

	@if (annonce.statut === 2) {
		<div class="annonce-type">
			<div class="img-container">

				<div class="red-tag-container">
					<div class="{{ annonce.statut === 2 ? 'red-tag display-text-12px' : 'hidden' }}">
						<span>{{ 'sold' | transloco }}</span>
					</div>
					<div class="{{ alreadySeen ? 'red-tag display-text-12px seen' : 'hidden' }}">
						<span>{{ 'alreadySeen' | transloco }}</span>
					</div>
				</div>

				<a [routerLink]="annonceLink" [attr.aria-label]="annonce.ville">
					<img
						class="h-full w-full object-cover absolute"
						(error)="onImgError($event)"
						[src]="image"
						alt=""
					/>
				</a>

				<div class="flex flex-wrap container-img-tags">
					<div class="img-tag display-text-12px">
						<span class="icon-image fs-16px mr-10px"></span>
						<span>{{ annonce.photo?.length }}</span>
					</div>
					@if (annonce.visite_virtuelle_url) {
						<div class="img-tag display-text-12px">
							<span class="fs-16px mr-10px">
								<img src="/assets/imgs/visite_3d_white.svg" alt="visite virtuelle" />
							</span>
							<span>{{ 'virtual_tour' | transloco }}</span>
						</div>
					}
				</div>

				@if (annonce.statut !== 2) {
					<div (click)="hideProperty(annonce.id)" class="hide-property display-text-12px">
						<span class="icon-eye-off fs-16px mr-10px"></span>
						<span class="uppercase">{{ 'hide_property' | transloco }}</span>
					</div>
				}
				@if ((annonce.images?.little?.length > 1) || (annonce.photo?.length > 1)) {
					<span (click)="previousImage()" class="icon-chevron-left chevron chevron-left"></span>
					<span (click)="nextImage()" class="icon-chevron-right chevron chevron-right"></span>
				}

			</div>

			<a [routerLink]="annonceLink" [attr.aria-label]="annonce.ville">
				<div class="typeBien">
					<span class="display-text-16px">{{ annonce.type_bien | mapTypebienApiToDisplay | transloco }}</span>
				</div>

				@if (typeBien !== 'terrain') {
					<span class="details">
						{{ annonce.nb_pieces }} {{ 'rm' | transloco }}
						<app-text-round-separator />
						{{ annonce.nb_chambres }}
						{{ 'bdrm' | transloco }}
						<app-text-round-separator />
						{{ annonce.surface_habitable }}m²
					</span>
				} @else {
					<span class="details">
						{{ annonce.surface_habitable }}m²
					</span>
				}

				<div class="mt-12px flex items-center location">
					<span class="icon-location color-location"></span>
					<span class="display-text-16px ville">
						{{ annonce.ville }} ({{ annonce.code_postal }})
					</span>
				</div>

				<div class="mt-10px {{ (sold || annonce.statut === 2) && 'hidden' }}">
					<span class="display-text-24px prix">{{ annonce.prix | number:'':'fr-FR' }}€ </span>
					@if (annonce.type_annonce === 'Vente') {
						<span class="display-text-14px squareMeter">
							{{ annonce.prix / annonce.surface_habitable | number:'1.0-0':'fr-FR' }} € / m²
						</span>
					}
				</div>
			</a>
		</div>

	} @else if (listAnnonces) {

		<div class="annonce-card transition-all" [ngClass]="{ 'profile': profile, 'map': displayMode === 'map' }">
			<div class="img-container">

				<div class="red-tag-container">
					<div class="{{ annonce.mandat_exclusif === 1 && annonce.statut === 0 ? 'red-tag display-text-12px' : 'hidden' }}">
						<span>{{ 'exclusivity' | transloco }}</span>
					</div>
					<div class="{{ annonce.statut === 1 ? 'red-tag display-text-12px' : 'hidden' }}">
						<span>{{ 'preliminary_sale_agreement_c' | transloco }}</span>
					</div>
					<div class="{{ annonce.statut === 11 ? 'red-tag display-text-12px' : 'hidden' }}">
						<span>{{ 'preliminary_sale_agreement' | transloco }}</span>
					</div>
					<div class="{{ alreadySeen ? 'red-tag display-text-12px seen' : 'hidden' }}">
						<span>{{ 'alreadySeen' | transloco }}</span>
					</div>
				</div>

				@if (!profile) {
					<a [routerLink]="annonceLink" [attr.aria-label]="annonce.ville">
						<img
							class="h-full w-full object-cover absolute"
							(error)="onImgError($event)"
							[src]="image"
							alt=""
						/>
					</a>
				} @else {
					<img
						class="h-full w-full object-cover absolute"
						(click)="showTabAnnonce(annonce.id)"
						(error)="onImgError($event)"
						[src]="image"
						alt=""
					/>
				}

				<div class="flex flex-wrap container-img-tags">
					<div class="img-tag display-text-12px">
						<span class="icon-image fs-16px mr-10px"></span>
						<span>{{ annonce.photo?.length }}</span>
					</div>

					@if (annonce.visite_virtuelle_url) {
						<div class="img-tag display-text-12px">
							<span class="fs-16px mr-10px">
								<img src="/assets/imgs/visite_3d_white.svg" alt="visite virtuelle" />
							</span>
							<span>{{ 'virtual_tour' | transloco }}</span>
						</div>
					}

					@if (!profile && annonce.statut !== 2) {
						<div (click)="hideProperty(annonce.id)" class="hide-property display-text-12px">
							<span class="icon-eye-off fs-16px mr-10px"></span>
							<span class="uppercase">{{ 'hide_property' | transloco }}</span>
						</div>
					}

				</div>

				@if (annonce.photo?.length > 1) {
					<span (click)="previousImage()" class="icon-chevron-left chevron chevron-left"></span>
					<span (click)="nextImage()" class="icon-chevron-right chevron chevron-right"></span>
				}
			</div>

			@if (profile) {

				<div class="annonce-details profile">
					<div (click)="showTabAnnonce(annonce.id)">
						<span class="display-text-16px type-annonce">
							{{ annonce.type_bien }}
						</span>

						@if (typeBien !== 'terrain') {
							<span class="details">
								@if (annonce.nb_pieces !== '0') {
									<span>{{ annonce.nb_pieces }} p.</span>
									<app-text-round-separator />
								}
								@if (annonce.nb_chambres !== '0') {
									<span>{{ annonce.nb_chambres }} {{ 'bdrm' | transloco }}</span>
									<app-text-round-separator />
								}
								{{ annonce.surface_habitable }} m²
							</span>
						} @else {
							<span class="details">
								{{ annonce.surface_habitable }} m²
							</span>
						}

						<span class="display-text-16px ville mt-5px">
							<span class="icon-location color-location"></span>
							{{ annonce.ville }} ({{ annonce.code_postal }})
						</span>
						<div class="flex mt-30px flex-row items-center price" [ngClass]="[profile ? 'profile' : '']">
							<span class="display-text-24px prix mr-10px">
								{{ annonce.prix | number:'':'fr-FR' }} €
							</span>
							<span class="display-text-14px squareMeter">
								{{ annonce.prix / annonce.surface_habitable | number:'1.0-0':'fr-FR' }} € / m²
							</span>
						</div>
					</div>

					<app-button
						aspect="arrow-blue"
						extraClasses="profile"
						[link]="annonceLink"
						text="see_ad"
					/>
				</div>

			} @else {

				<div class="annonce-details" [routerLink]="annonceLink" [attr.aria-label]="annonce.ville">
					<span class="display-text-16px type-annonce">
						{{ annonce.type_bien | mapTypebienApiToDisplay | transloco }}
					</span>

					@if (typeBien !== 'terrain') {
						<span class="details">
							@if (annonce.nb_pieces !== '0') {
								<span>{{ annonce.nb_pieces }} {{ 'rm' | transloco }}</span>
								<app-text-round-separator />
							}
							@if (annonce.nb_chambres !== '0') {
								<span>{{ annonce.nb_chambres }} {{ 'bdrm' | transloco }}</span>
								<app-text-round-separator />
							}
							{{ annonce.surface_habitable }} m²
						</span>
					} @else {
						<span class="details">
							{{ annonce.surface_habitable }} m²
						</span>
					}

					<span class="display-text-16px ville mt-5px">
						<span class="icon-location color-location"></span>
						{{ annonce.ville }} ({{ annonce.code_postal }})
					</span>

					<div class="flex mt-30px flex-row items-center price" [ngClass]="[profile ? 'profile' : '']">
						<span class="display-text-24px prix mr-10px">
							{{ annonce.prix | number:'':'fr-FR' }} €
						</span>
						<span class="display-text-14px squareMeter">
							{{ annonce.prix / annonce.surface_habitable | number:'1.0-0':'fr-FR' }} € / m²
						</span>
					</div>

					@if (emptyHeart) {
						<div (click)="addToFavoris($event)" class="icon-heart-empty heart"></div>
					} @else {
						<div (click)="removeFromfavoris($event)" class="icon-heart-fill heart heart-filled"></div>
					}
				</div>
			}

		</div>

	} @else {

		<div class="annonce-type">
			<div class="img-container">

				<div class="red-tag-container">
					<div class="red-tags">
						<div class="{{ annonce.mandat_exclusif === 1 && annonce.statut === 0 ? 'red-tag display-text-12px' : 'hidden' }}">
							<span>{{ 'exclusivity' | transloco }}</span>
						</div>
						<div class="{{ annonce.statut === 1 ? 'red-tag display-text-12px' : 'hidden' }}">
							<span>{{ 'preliminary_sale_agreement_c' | transloco }}</span>
						</div>
						<div class="{{ annonce.statut === 11 ? 'red-tag display-text-12px' : 'hidden' }}">
							<span>{{ 'preliminary_sale_agreement' | transloco }}</span>
						</div>
						<div class="{{ annonce.coup_coeur === 1 ? 'red-tag display-text-12px heart-throb' : 'hidden' }}">
							<span>{{ 'heart_throb' | transloco }}</span>
						</div>
					</div>
					<div class="{{ alreadySeen ? 'red-tag display-text-12px seen' : 'hidden' }}">
						<span>{{ 'alreadySeen' | transloco }}</span>
					</div>
				</div>

				<a [routerLink]="annonceLink">
					<img
						class="h-full w-full object-cover absolute"
						(error)="onImgError($event)"
						[src]="image"
						alt=""
					/>
				</a>

				<div class="flex flex-wrap container-img-tags">
					<div class="img-tag display-text-12px">
						<span class="icon-image fs-16px mr-10px"></span>
						<span>{{ annonce.photo?.length }}</span>
					</div>

					@if (annonce.visite_virtuelle_url) {
						<div class="img-tag display-text-12px">
							<span class="fs-16px mr-10px">
								<img src="/assets/imgs/visite_3d_white.svg" alt="visite virtuelle" />
							</span>
							<span>{{ 'virtual_tour' | transloco }}</span>
						</div>
					}

					@if (annonce.statut !== 2) {
						<div (click)="hideProperty(annonce.id)" class="hide-property display-text-12px">
							<span class="icon-eye-off fs-16px mr-10px"></span>
							<span class="uppercase">{{ 'hide_property' | transloco }}</span>
						</div>
					}
				</div>

				@if (annonce.photo?.length > 1) {
					<span (click)="previousImage()" class="icon-chevron-left chevron chevron-left"></span>
					<span (click)="nextImage()" class="icon-chevron-right chevron chevron-right"></span>
				}
			</div>

			<div [routerLink]="annonceLink" class="relative">
				<div class="annonce-description">
					<div class="typeBien">
						<h3 class="display-text-16px">{{ annonce.type_bien | mapTypebienApiToDisplay | transloco }} </h3>
					</div>

					@if (typeBien !== 'terrain' && annonce.type_bien !== 'Parking / box') {
						<h4 class="details">
							@if (annonce.nb_pieces !== '0') {
								<span>{{ annonce.nb_pieces }} {{ 'rm' | transloco }}</span>
								<app-text-round-separator />
							}
							@if (annonce.nb_chambres !== '0') {
								<span>{{ annonce.nb_chambres }} {{ 'bdrm' | transloco }}</span>
								<app-text-round-separator />
							}
							{{ annonce.surface_habitable }}m²
						</h4>
					}

					@if (typeBien === 'terrain' || annonce.type_bien === 'Parking / box') {
						<h4 class="details">
							{{ annonce.surface_habitable }}m²
						</h4>
					}

					<div class="mt-12px flex items-center location">
						<span class="icon-location color-location"></span>
						<h4 class="display-text-16px ville">
							{{ annonce.ville }} ({{ annonce.code_postal }})
						</h4>
					</div>

					<div class="mt-10px">
						<h4 class="display-text-24px prix">
							{{ annonce.prix | number: '':'fr-FR' }} €
						</h4>
						@if (annonce.type_annonce === 'Vente') {
							<span class="display-text-14px squareMeter">
								{{ annonce.prix / annonce.surface_habitable | number:'1.0-0':'fr-FR' }} € / m²
							</span>
						}
					</div>
				</div>

				@if (emptyHeart) {
					<div (click)="addToFavoris($event)" class="icon-heart-empty heart mobile-home"></div>
				} @else {
					<div (click)="removeFromfavoris($event)" class="icon-heart-fill heart heart-filled mobile-home"></div>
				}

			</div>
		</div>

	}
}
