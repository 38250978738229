import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

import { ContentService } from '@/services/content.service';

@Component({
  selector: 'app-opinion-global',
  templateUrl: './opinion-global.component.html',
  styleUrl: './opinion-global.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OpinionGlobalComponent implements OnInit, OnDestroy {
  public total?: number;
  public rate?: number;

  public satisfactionText: string;
  public first_slice_satisfaction: number;
  public second_slice_satisfaction: number;

  public recommandationText: string;
  public first_slice_recommandation: number;
  public second_slice_recommandation: number;

  private subscriptions = new Subscription();

  constructor(
    private contentService: ContentService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.subscriptions.add(
      this.contentService.getContentOpinion().subscribe((({ total, rate, satisfaction, recommandation }) => {
        this.total = total;
        this.rate = rate;

        this.satisfactionText = `${satisfaction} %`;
        this.first_slice_satisfaction = satisfaction / 100;
        this.second_slice_satisfaction = (100 - satisfaction) / 100;

        this.recommandationText = `${recommandation} %`;
        this.first_slice_recommandation = recommandation / 100;
        this.second_slice_recommandation = (100 - recommandation) / 100;

        this.cd.markForCheck();
      }))
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
