@if (agence?.social_network_url || agenceGroup?.social_network_url || header === true) {
	<div [ngClass]="{ header: header === true }"	class="network">
		@if (agenceGroup?.social_network_url.facebook) {
			<a href="https://{{ agenceGroup?.social_network_url.facebook }}" target="_blank" class="network-item" rel="nofollow">
				<span class="item"><img src="/assets/imgs/facebook.svg" alt="facebook" /></span>
			</a>
		} @else if (agence?.social_network_url.facebook) {
			<a href="https://{{ agence?.social_network_url.facebook }}" target="_blank" class="network-item" rel="nofollow">
				<span class="item"><img src="/assets/imgs/facebook.svg" alt="facebook" /></span>
			</a>
		} @else {
			<a href="https://www.facebook.com/erafrance" target="_blank" class="network-item" rel="nofollow">
				<span class="item"><img src="/assets/imgs/facebook.svg" alt="facebook" /></span>
			</a>
		}

		@if (agenceGroup?.social_network_url.instagram) {
			<a href="https://{{ agenceGroup?.social_network_url.instagram }}" target="_blank" class="network-item" rel="nofollow">
				<span class="item"><img src="/assets/imgs/instagram.svg" alt="instagram" /></span>
			</a>
		} @else if (agence?.social_network_url.instagram) {
			<a href="https://{{ agence?.social_network_url.instagram }}" target="_blank" class="network-item" rel="nofollow">
				<span class="item"><img src="/assets/imgs/instagram.svg" alt="instagram" /></span>
			</a>
		} @else {
			<a href="https://www.instagram.com/era_immobilier/" target="_blank" class="network-item" rel="nofollow">
				<span class="item"><img src="/assets/imgs/instagram.svg" alt="instagram" /></span>
			</a>
		}

		@if (agenceGroup?.social_network_url.linkedin) {
			<a href="https://{{ agenceGroup?.social_network_url.linkedin }}" target="_blank" class="network-item" rel="nofollow">
				<span class="item"><img src="/assets/imgs/linkedin.svg" alt="linkedin" /></span>
			</a>
		} @else if (agence?.social_network_url.linkedin) {
			<a href="https://{{ agence?.social_network_url.linkedin }}" target="_blank" class="network-item" rel="nofollow">
				<span class="item"><img src="/assets/imgs/linkedin.svg" alt="linkedin" /></span>
			</a>
		} @else {
			<a href="https://www.linkedin.com/company/era-immobilier?originalSubdomain=fr" target="_blank" class="network-item" rel="nofollow">
				<span class="item"><img src="/assets/imgs/linkedin.svg" alt="linkedin" /></span>
			</a>
		}

		@if (agenceGroup?.social_network_url.twitter) {
			<a href="https://{{ agenceGroup?.social_network_url.twitter }}" target="_blank" class="network-item" rel="nofollow">
				<span class="item"><img src="/assets/imgs/twitter.svg" alt="twitter" /></span>
			</a>
		} @else if (agence?.social_network_url.twitter) {
			<a href="https://{{ agence?.social_network_url.twitter }}" target="_blank" class="network-item" rel="nofollow">
				<span class="item"><img src="/assets/imgs/twitter.svg" alt="twitter" /></span>
			</a>
		}

		@if (agenceGroup?.social_network_url.viadeo) {
			<a href="https://{{ agenceGroup?.social_network_url.viadeo }}" target="_blank" class="network-item" rel="nofollow">
				<span class="item"><img src="/assets/imgs/viadeo.svg" alt="viadeo" /></span>
			</a>
		} @else if (agence?.social_network_url.viadeo) {
			<a href="https://{{ agence?.social_network_url.viadeo }}" target="_blank" class="network-item" rel="nofollow">
				<span class="item"><img src="/assets/imgs/viadeo.svg" alt="viadeo" /></span>
			</a>
		}

		@if (agenceGroup?.social_network_url.youtube) {
			<a href="https://{{ agenceGroup?.social_network_url.youtube }}" target="_blank" class="network-item" rel="nofollow">
				<span class="item"><img src="/assets/imgs/youtube.svg" alt="youtube" /></span>
			</a>
		} @else if (agence?.social_network_url.youtube) {
			<a href="https://{{ agence?.social_network_url.youtube }}" target="_blank" class="network-item" rel="nofollow">
				<span class="item"><img src="/assets/imgs/youtube.svg" alt="youtube" /></span>
			</a>
		} @else {
			<a href="https://www.youtube.com/user/ERAIMMOBILIERFRANCE" target="_blank" class="network-item" rel="nofollow">
				<span class="item"><img src="/assets/imgs/youtube.svg" alt="youtube" /></span>
			</a>
		}
	</div>
} @else {
	<div class="network flex-row" [ngClass]="footer || profile ? 'footer' : 'general'">
		<a href="https://www.facebook.com/erafrance" target="_blank" class="network-item" rel="nofollow">
			<span class="item"><img src="/assets/imgs/facebook.svg" alt="facebook" /></span>
		</a>
		<a href="https://www.instagram.com/era_immobilier/" target="_blank" class="network-item" rel="nofollow">
			<span class="item"><img src="/assets/imgs/instagram.svg" alt="instagram" /></span>
		</a>
		<a href="https://www.linkedin.com/company/era-immobilier?originalSubdomain=fr" target="_blank" class="network-item" rel="nofollow">
			<span class="item"><img src="/assets/imgs/linkedin.svg" alt="linkedin" /></span>
		</a>
		<a href="https://www.youtube.com/user/ERAIMMOBILIERFRANCE" target="_blank" class="network-item" rel="nofollow">
			<span class="item"><img src="/assets/imgs/youtube.svg" alt="youtube" /></span>
		</a>
	</div>
}
