<div class="w-full flex justify-between items-center flex-wrap my-40px avis">
  <h2 class="display-h2">{{ 'words about clients' | transloco }}</h2>
  <a class="flex items-center" [routerLink]="avisLink">
    <p class="display-text-12px cursor-pointer underline whitespace-nowrap pr-15px">
      {{ 'see x opinion' | transloco: { opinion_numbers: total | number:'':'fr-FR' } }}
    </p>
    @if (rate) {
      <app-rating-stars [rate]="rate" />
    }
  </a>
</div>
