import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { Agence, Annonce, Emploi, Negociateur } from '@/models';

@Component({
  selector: 'app-presentation-agency-card',
  templateUrl: './presentation-agency-card.component.html',
  styleUrls: ['./presentation-agency-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PresentationAgencyCardComponent {
  @Input() agence?: Agence;
  @Input() annonce?: Annonce;
  @Input() emploi?: Emploi;
  @Input() team?: Negociateur;
  @Input() type: 'era' | 'negociateur' | 'agence' | 'annonce' | 'team' | 'noPhone';
}
