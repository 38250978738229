<div class="agence-card animate-pulse">
	<div class="br-10px img-container">
		<ngx-skeleton-loader
			count="1"
			appearance="circle"
			[theme]="{
				width: '100%',
				'border-radius': '10px',
				height: '100%',
				margin: 0
			}"
		/>
	</div>
	<div class="agence-details">
		<ngx-skeleton-loader
			count="1"
			[theme]="{
				width: '20%',
				'margin-top': '5px'
			}"
			appearance="line"
		/>
		<ngx-skeleton-loader
			count="1"
			[theme]="{
				width: '50%'
			}"
			appearance="line"
		/>
		<ngx-skeleton-loader
			count="1"
			[theme]="{
				width: '40%',
				'margin-top': '40px'
			}"
			appearance="line"
		/>
		<ngx-skeleton-loader
			count="1"
			[theme]="{
				width: '20%'
			}"
			appearance="line"
		/>
	</div>
</div>
