import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input, ViewChild } from '@angular/core';

import { Agence, AgenceGroupe, Avis, Negociateur } from '@/models';

@Component({
  selector: 'app-statement-card',
  templateUrl: './statement-card.component.html',
  styleUrls: ['./statement-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StatementCardComponent implements AfterViewInit {
  @ViewChild('commentText') commentText!: ElementRef;

  constructor(private cd: ChangeDetectorRef) {
  }

  @Input() avis!: Avis;
  @Input() agence?: Agence;
  @Input() groupeAgence?: AgenceGroupe;
  @Input() team?: Negociateur;

  public showMore = false;
  public showMoreButton = false;

  ngAfterViewInit(): void {
    if (this.avis.comment && this.avis.comment.length) {
      this.checkOverflow();
    }
  }

  checkOverflow(): void {
    const element = this.commentText.nativeElement;
    this.showMoreButton = element.scrollHeight > element.clientHeight;
    this.cd.detectChanges();
  }
}
