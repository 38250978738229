import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiEmploiPosteResponse, ApiEmploiResponse, ApiEmploisResponse, ApiEvenementsResponse, ApiPosteResponse } from '@/models';
import { ConfigService } from './config.service';
import { ToolsService } from './tools.service';

@Injectable({
  providedIn: 'root'
})
export class OffreEmploiService {
  private baseUrl: string;

  constructor(
    private http: HttpClient,
    private toolsService: ToolsService,
    configService: ConfigService
  ) {
    this.baseUrl = `${configService.config.url}/emplois`;
  }

  getEmplois(options: Record<string, any> = {}): Observable<ApiEmploisResponse> {
    return this.http.get<ApiEmploisResponse>(this.baseUrl, {
      params: this.toolsService.buildHttpParams(options)
    });
  }

  getEmploi(id: number): Observable<ApiEmploiResponse> {
    return this.http.get<ApiEmploiResponse>(`${this.baseUrl}/${id}`);
  }

  getEmploiFromBO(id: number): Observable<ApiEmploiPosteResponse> {
    return this.http.get<ApiEmploiPosteResponse>(`${this.baseUrl}/offres/${id}`);
  }

  getPostes(source?: number[]): Observable<any> {
    return this.http.get(`${this.baseUrl}/postes${source ? `?source=${source.join(',')}` : ''}`);
  }

  getPoste(id: number): Observable<ApiPosteResponse> {
    return this.http.get<ApiPosteResponse>(`${this.baseUrl}/postes/${id}`);
  }

  postEmploi(data: Record<string, any>): Observable<any> {
    return this.http.post(`${this.baseUrl}/postuler`, data);
  }

  postEmploiSiege(data: Record<string, any>): Observable<any> {
    return this.http.post(`${this.baseUrl}/siege/postuler`, data);
  }

  getEvents(
    itemsPerPage: number,
    currentPage: number,
    order: string,
    era_agence_id: string | number,
    geo_ville_id: string | number
  ): Observable<ApiEvenementsResponse> {
    return this.http.get<ApiEvenementsResponse>(`${this.baseUrl}/evenements`, {
      params: this.toolsService.buildHttpParams({
        per_page: itemsPerPage,
        page: currentPage,
        order_dir: order,
        era_agence_id,
        geo_ville_id
      })
    });
  }

  getEvent(id: number): Observable<any> {
    return this.http.get(`${this.baseUrl}/evenements/${id}`);
  }

  subscribeToEvent(options: Record<string, any>): Observable<any> {
    return this.http.post(`${this.baseUrl}/evenements/inscription`, options);
  }
}
