@if (field?.dirty && field?.errors) {
  <div class="form-error">
    @if (field.errors.required) {
      <div>{{ 'field_required' | transloco }}</div>
    }
    @if (field.errors.min) {
      <div>{{ 'field_min' | transloco: { min: field.errors.min.min } }}</div>
    }
    @if (field.errors.max) {
      <div>{{ 'field_max' | transloco: { max: field.errors.max.max } }}</div>
    }
    @if (field.errors.email) {
      <div>{{ 'incorrect email' | transloco }}</div>
    }
    @if (field.errors['validatePhoneNumber']?.valid === false) {
      <div>{{ 'SELL.PHONE_WRONG' | transloco }}</div>
    }
    @if (field.errors['mustMatch']) {
      <p>{{ 'PROFILE.password_and_confirm_not_match' | transloco }}</p>
    }
  </div>
}
