<div class="gallery" [ngClass]="{ lightbox: lightbox }">
  <ng-content />
</div>

@if (showDots) {
  <div class="dots">
    @for (dot of dots(); track dot; let index = $index) {
      <div
        class="dot"
        (click)="changeActiveSlide(index)"
        [ngClass]="{ active: index === currentSlide }"
      ></div>
    }
  </div>
}

@if (arrows) {
  <app-button-circle icon="icon-chevron-left" [shadow]="false" (click)="onPrevClick()" />
  <app-button-circle icon="icon-chevron-right" [shadow]="false" (click)="onNextClick()" />
}
