import { Inject, Injectable, InjectionToken } from '@angular/core';

export const BROWSER_STORAGE = new InjectionToken<Storage>('Browser Storage', {
  providedIn: 'root',
  factory: () => localStorage
});

export enum StorageKey {
  AnnoncesToHide = 'era-annoncesToHide',
  AlreadySeen = 'era-alreadySeen',
  DisplayMode = 'era-displayMode',
  Favoris = 'era-favoris',
  LastSearch = 'era-lastSearch',
  Locale = 'era-locale',
  ConnectedUser = 'era-connectedUser',
  AuthToken = 'era-authToken',
  SocialUser = 'era-socialUser',
  LoginType = 'era-type'
}

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  constructor(@Inject(BROWSER_STORAGE) private storage: Storage) {}

  getString(key: StorageKey): string | undefined {
    return this.storage.getItem(key) ?? undefined;
  }

  setString(key: StorageKey, value: string): void {
    this.storage.setItem(key, value);
  }

  getObject<T>(key: StorageKey): T | undefined {
    try {
      const str = this.storage.getItem(key);
      return str ? JSON.parse(str) as T : undefined;
    } catch (e) {
      console.error('Storage error', e);
    }
    return undefined;
  }

  setObject<T>(key: StorageKey, value: T): void {
    this.storage.setItem(key, JSON.stringify(value));
  }

  remove(key: StorageKey): void {
    this.storage.removeItem(key);
  }
}
