import {
  AfterContentInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ContentChildren, Input, QueryList, ViewEncapsulation
} from '@angular/core';

import { TabComponent } from './tab/tab.component';

@Component({
  selector: 'app-tabs',
  templateUrl: './tabs.component.html',
  styleUrl: './tabs.component.scss',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TabsComponent implements AfterContentInit {
  @Input() contentClass = '';

  @ContentChildren(TabComponent) tabs: QueryList<TabComponent>;

  constructor(private cd: ChangeDetectorRef) {}

  ngAfterContentInit(): void {
    this.selectTab(this.tabs.first);
  }

  selectTab(tab: TabComponent) {
    if (tab) {
      this.tabs.toArray().forEach((tab) => tab.setActive(false));
      tab.setActive(true);
      this.cd.markForCheck();
    }
  }
}
