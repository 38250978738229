import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { Recherche } from '@/models';

@Component({
  selector: 'app-search-bar-details',
  templateUrl: './search-bar-details.component.html',
  styleUrl: './search-bar-details.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SearchBarDetailsComponent {
  @Input() item!: Recherche;
}
