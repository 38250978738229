import { ChangeDetectionStrategy, Component, EventEmitter, forwardRef, Input, OnDestroy, OnInit, Output, signal } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { debounceTime, distinctUntilChanged, filter, map, Subscription, switchMap } from 'rxjs';

import { GeographyService } from '@/services/geography.service';
import { GeoDepartement, GeolocSuggestions, GeoVille, SearchLocation } from '@/models';

const MAX_SUGGESTIONS = 5;

@Component({
  selector: 'app-geoloc-input',
  templateUrl: './geoloc-input.component.html',
  styleUrl: './geoloc-input.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => GeolocInputComponent),
    multi: true
  }]
})
export class GeolocInputComponent implements ControlValueAccessor, OnDestroy, OnInit {
  @Input() mode: 'city' | 'department' | 'where' | 'address' = 'address';
  @Input() showError = false;
  @Input() showCloseButton = false;

  @Output() geolocChanged = new EventEmitter<GeoVille | GeoDepartement | SearchLocation>();
  @Output() closeClicked = new EventEmitter;

  public address = new FormControl('', Validators.required);
  public suggestions = signal<GeolocSuggestions>({});
  public showDropdown = signal(false);

  private subscriptions = new Subscription();

  private onChange: any = () => {};
  private onTouched: any = () => {};

  constructor(private geographyService: GeographyService) {}

  ngOnInit(): void {
    this.subscriptions.add(
      this.address.valueChanges.pipe(
        filter((value) => value?.length >= 3),
        distinctUntilChanged(),
        debounceTime(300),
        switchMap((value) => (
          (this.mode === 'city') ? (
            this.geographyService.getAddressVilleAutocomplete(value).pipe(
              map((response) => ({ villes: this.slice(response?.features) }))
            )
          ) : (this.mode === 'department') ? (
            this.geographyService.getAutoCompleteList(value).pipe(
              map((response) => ({
                departements: this.slice(response?.departements).map((d: GeoDepartement) => ({ properties: d })),
                villes: this.slice(response?.villes).map((v: GeoVille) => ({ properties: v }))
              }))
            )
          ) : (this.mode === 'where') ? (
            this.geographyService.getAllAutoComplete(value).pipe(
              map((response) => ({
                departements: this.slice(response?.departements).map((d) => ({ properties: { ...d, nom: d.name } })),
                villes: this.slice(response?.villes).map((v) => ({ properties: { ...v, nom: v.name } }))
              }))
            )
          ) : (
            this.geographyService.getAddressAutocomplete(value).pipe(
              map((response) => ({ villes: this.slice(response?.features) }))
            )
          )
        ))
      ).subscribe({
        next: (suggestions) => {
          this.suggestions.set(suggestions);
          this.showDropdown.set(true);
        },
        error: (error) => {
          console.error('GeolocInputComponent error', error);
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  handleSelect(address): void {
    const name = address.properties.nom ?? address.properties.label;
    this.address.setValue(name, { emitEvent: false });
    this.geolocChanged.emit(address);
    this.showDropdown.set(false);
    this.onChange(name);
    this.onTouched();
  }

  handleClose(type: 'input' | 'dropdown'): void {
    this.address.reset();
    switch (type) {
      case 'input':
        this.closeClicked.emit();
        break;
      case 'dropdown':
        this.showDropdown.set(false);
        break;
    }
  }

  private slice(tab: any[]): any[] {
    return tab?.slice(0, MAX_SUGGESTIONS);
  }

  writeValue(value: string): void {
    this.address.setValue(value, { emitEvent: false });
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
}
