import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiActualiteResponse, ApiActualitesResponse, ApiActualiteTagsResponse } from '@/models';
import { ConfigService } from './config.service';
import { SortType } from '@/constants';

@Injectable({
  providedIn: 'root'
})
export class BlogImmobilierService {
  private baseUrl: string;

  constructor(
    private http: HttpClient,
    configService: ConfigService
  ) {
    this.baseUrl = `${configService.config.url}/actualites`;
  }

  getActualites(
    itemsPerPage?: number,
    currentPage?: number,
    orderDir?: SortType,
    orderBy?: string,
    source?: string,
    tags?: string,
    eraEffectifIdOrAgenceId?: string | number
  ): Observable<ApiActualitesResponse> {
    const params: any = {};

    params.per_page = itemsPerPage || 8;
    params.page = currentPage || 1;

    if (orderDir) params.order_dir = orderDir;
    if (orderBy) params.order_by = orderBy;
    if (source) params.source = source;
    if (tags) params.tags = tags;

    if (eraEffectifIdOrAgenceId) {
      if (source === 'SOURCE.NEGO') {
        params.era_effectif_id = eraEffectifIdOrAgenceId;
      } else {
        params.era_agence_id = eraEffectifIdOrAgenceId;
      }
    }

    return this.http.get<ApiActualitesResponse>(this.baseUrl, {
      params: new HttpParams({ fromObject: params })
    });
  }

  getActualitesTags(): Observable<ApiActualiteTagsResponse> {
    return this.http.get<ApiActualiteTagsResponse>(`${this.baseUrl}/tags`);
  }

  getActualite(id: number): Observable<ApiActualiteResponse> {
    return this.http.get<ApiActualiteResponse>(`${this.baseUrl}/${id}`);
  }

  getActualitesAgence(agenceId: number): Observable<ApiActualitesResponse> {
    return this.http.get<ApiActualitesResponse>(`${this.baseUrl}?era_agence_id=${agenceId}`);
  }
}
