import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-carousel-arrows',
  templateUrl: './carousel-arrows.component.html',
  styleUrl: './carousel-arrows.component.scss'
})
export class CarouselArrowsComponent {
  @Output() next = new EventEmitter();
  @Output() prev = new EventEmitter();

  handlePrev(): void {
    this.prev.emit();
  }

  handleNext(): void {
    this.next.emit();
  }
}
