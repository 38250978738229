import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { OverlayModule } from '@angular/cdk/overlay';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { DirectivesModule } from '../directives/directives.module';

import { TranslocoModule } from '@jsverse/transloco';
import { NgxIntlTelInputModule } from '@capgo/ngx-intl-tel-input';
import { NgxPaginationModule } from 'ngx-pagination';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

import { TextRoundSeparatorComponent } from './components/text-round-separator/text-round-separator.component';
import { ButtonTelephoneComponent } from './components/buttons/button-telephone/button-telephone.component';
import { ButtonCircleComponent } from './components/buttons/button-circle/button-circle.component';
import { ButtonLoadingComponent } from './components/buttons/button-loading/button-loading.component';
import { ButtonTagComponent } from './components/buttons/button-tag/button-tag.component';
import { ButtonComponent } from './components/buttons/button/button.component';
import { LoadingComponent } from './components/loading/loading.component';
import { DotLoaderComponent } from './components/dot-loader/dot-loader.component';
import { TagsContainerComponent } from './components/tags-container/tags-container.component';
import { PaginationLinkComponent } from './components/pagination-link/pagination-link.component';
import { PaginationComponent } from './components/pagination/pagination.component';
import { ModalContainerComponent } from './components/modal-container/modal-container.component';
import { GeolocInputComponent } from './components/geoloc-input/geoloc-input.component';
import { CrossLinksComponent } from './components/cross-links/cross-links.component';
import { ViewModeComponent } from './components/view-mode/view-mode.component';
import { SliderComponent } from './components/slider/slider.component';
import { LightboxContainerComponent } from './components/lightbox-container/lightbox-container.component';
import { CarouselArrowsComponent } from './components/carousel/carousel-arrows/carousel-arrows.component';
import { CarouselSlideComponent } from './components/carousel/carousel-slide/carousel-slide.component';
import { CarouselComponent } from './components/carousel/carousel.component';
import { RatingStarsComponent } from './components/rating-stars/rating-stars.component';
import { RatingBarsComponent } from './components/rating-bars/rating-bars.component';
import { FormInputComponent } from './components/forms/form-input/form-input.component';
import { FormInputTelephoneComponent } from './components/forms/form-input-telephone/form-input-telephone.component';
import { FormErrorsComponent } from './components/forms/form-errors/form-errors.component';
import { FormSelectComponent } from './components/forms/form-select/form-select.component';
import { FormCivilityComponent } from './components/forms/form-civility/form-civility.component';
import { FormSwitchComponent } from './components/forms/form-switch/form-switch.component';
import { FormCheckboxComponent } from './components/forms/form-checkbox/form-checkbox.component';
import { FormQuantityComponent } from './components/forms/form-quantity/form-quantity.component';
import { PasswordTipsComponent } from './components/forms/password-tips/password-tips.component';
import { TabsComponent } from './components/tabs/tabs.component';
import { TabComponent } from './components/tabs/tab/tab.component';
import { DonutComponent } from './components/donut/donut.component';

const MODULES = [
  OverlayModule,
  ScrollingModule,
  NgxPaginationModule,
  NgxIntlTelInputModule,
  NgxSkeletonLoaderModule
];

const COMPONENTS = [
  TagsContainerComponent,
  TextRoundSeparatorComponent,
  DotLoaderComponent,
  LoadingComponent,
  ButtonTagComponent,
  ButtonLoadingComponent,
  ButtonComponent,
  ButtonCircleComponent,
  PaginationComponent,
  PaginationLinkComponent,
  ModalContainerComponent,
  GeolocInputComponent,
  CrossLinksComponent,
  ViewModeComponent,
  SliderComponent,
  LightboxContainerComponent,
  CarouselArrowsComponent,
  CarouselSlideComponent,
  CarouselComponent,
  ButtonTelephoneComponent,
  RatingStarsComponent,
  RatingBarsComponent,
  FormInputComponent,
  FormInputTelephoneComponent,
  FormErrorsComponent,
  FormSelectComponent,
  FormCivilityComponent,
  FormSwitchComponent,
  FormCheckboxComponent,
  FormQuantityComponent,
  TabsComponent,
  TabComponent,
  DonutComponent
];

@NgModule({
  declarations: [
    PasswordTipsComponent,
    ...COMPONENTS
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    TranslocoModule,
    DirectivesModule,
    ...MODULES
  ],
  exports: [
    ...MODULES,
    ...COMPONENTS
  ]
})
export class UiModule { }
