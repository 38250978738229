<button class="abtn {{ extraClasses }} {{ size }}" [ngClass]="[aspect ? 'abtn-' + aspect : '', disabled ? 'disabled' : '']" data-testid="button">
  @if (text) {
    @let btnText = translate ? (text | transloco) : text;

    @if (link) {
      <a #ref [routerLink]="link" [queryParams]="queryParams" [fragment]="fragment" data-testid="link" [ngClass]="{ wrap }">
        <p [ngClass]="{ 'abtn-text-transition': !disabled, wrap }" data-testid="text">
          {{ btnText }}
        </p>
        @if (icon && !disabled) {
          <span class="{{ icon }} fs-24px abtn-opacity-transition" data-testid="icon"></span>
        }
      </a>
    } @else if (href) {
      <a #ref [href]="href" [target]="target" data-testid="href" [ngClass]="{ wrap }">
        <p [ngClass]="{ 'abtn-text-transition': !disabled, wrap }" data-testid="text">
          {{ btnText }}
        </p>
        @if (icon && !disabled) {
          <span class="{{ icon }} fs-24px abtn-opacity-transition" data-testid="icon"></span>
        }
      </a>
    } @else {
      <p [ngClass]="{ 'abtn-text-transition': !disabled, wrap }" data-testid="text">
        {{ btnText }}
      </p>
      @if (icon && !disabled) {
        <span class="{{ icon }} fs-24px abtn-opacity-transition" data-testid="icon"></span>
      }
    }
  }
  <ng-content />
</button>
