import { Component, EventEmitter, Input, Output } from '@angular/core';

import { Agence } from '@/models';

@Component({
  selector: 'app-agences-properties',
  templateUrl: './agences-properties.component.html',
  styleUrls: ['./agences-properties.component.scss']
})
export class AgencesPropertiesComponent {
  @Input() agences!: Agence[];
  @Input() itemsPerPage!: number;
  @Input() totalItems!: number;
  @Input() currentPage!: number;
  @Input() loading = true;
  @Input() navByLink = false;

  @Output() pageChanged = new EventEmitter<number>();

  changePage(page: number): void {
    this.currentPage = page;
    this.pageChanged.emit(this.currentPage);
  }
}
