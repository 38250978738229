<div class="porte-ouverte" *ngIf="openings">
	<div class="breadcrumb w-full">
		<div class="breadcrumb-content">
			<div class="breadcrumb-back" (click)="goBack()">
				<div class="breadcrumb-back-arrow"><span class="icon-arrow-left"></span></div>
				<p class="display-text-16px">{{ 'previous' | transloco }}</p>
			</div>
			<div class="breadcrumb-links">
				<a routerLink="/">{{ 'homepage' | transloco }}</a>
				<span class="icon-chevron-right"></span>
				@if (agence?.enseigne) {
					<a [routerLink]="['/' + agence?.slug]">{{ agence?.enseigne }}</a>
					<span class="icon-chevron-right"></span>
				}
				@if (agence?.nom) {
					<a [routerLink]="[MainRoute.Groupe + '/' + agence?.slug]">{{ agence?.nom }}</a>
					<span class="icon-chevron-right"></span>
				}
				<span>{{ 'our' | transloco }} {{ 'open doors' | transloco }} </span>
			</div>
		</div>
	</div>

	<div class="main-containers" [ngClass]="!openings?.length && 'no-event'">
		<div class="map-loader" *ngIf="loading && !openings?.length">
			<div class="background-opacifier"></div>
			<div class="map-loader">
				<app-dot-loader />
			</div>
		</div>

		<div class="flex w-full title">
			<h1 class="display-h2 mr-auto mb-40px">
				<em>{{ 'our' | transloco }} {{ 'open doors' | transloco }} </em>
			</h1>
		</div>

		<div class="portes-ouvertes flex flex-row flex-wrap w-full" [ngClass]="openings?.length && 'mb-80px'">
			<app-open-houses-card
				class="mb-30px mr-30px"
				*ngFor="let opening of openings | paginate: {
					itemsPerPage: itemsPerPage,
					currentPage: currentPage,
					totalItems: totalItems
				}"
				[opening]="opening"
				[agence]="agence"
				[team]="team"
				[groupementAgence]="agenceResult"
			/>
		</div>

		<div class="not-found" [hidden]="openings?.length">
			<span class="display-h2 mr-10px">0 </span> <span>{{ 'open doors' | transloco }}</span>
			<img src="/assets/imgs/no-event.png" />
			<p class="display-text-15px">{{ 'no open doors' | transloco }}</p>
		</div>

		<app-pagination (pageChange)="changePage($event)" prompt="open doors" [itemsPerPage]="itemsPerPage" />
	</div>
</div>
