export enum MainRoutes {
  Estimation = '/estimation',
  Estimer = '/estimer',
  ToutesNosAgences = '/toutes-nos-agences',
  AgenceImmobiliere = '/agence-immobiliere',
  Agences = '/agences',
  Annonce = '/annonces',
  Contact = '/contact',
  Groupes = '/groupes',
  Effectifs = '/effectifs',
  Recrutement = '/recrutement',
  OffreEmploi = '/emploi-immobilier',
  Faqs = '/questions-frequentes',
  SitePlan = '/plan-site',
  PersonalData = '/donnees-personnelles',
  Favoris = '/mes-favoris',
  Guide = '/guide',
  Blog = '/blog-immobilier',
  Avis = '/avis',
  MentionsLegales = '/mentions-legales',
  GestionLocative = '/gestion-locative',
  Syndic = '/syndic',
  LocauxCommerciaux = '/locaux-commerciaux',
  ResetPassword = '/nouveau-mot-passe',
  Profile = '/profile',
  NotFound = '/page-non-trouvee',
  Acheter = '/acheter',
  Louer = '/louer',
  Vendre = '/vendre',
  Metiers = '/metiers',
  Achat = '/achat',
  Location = '/location'
}

export type MainPath = `${MainRoutes}`;
