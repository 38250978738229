import {
  afterNextRender, ChangeDetectionStrategy, ChangeDetectorRef, Component,
  Input, OnDestroy, OnInit, QueryList, ViewChildren
} from '@angular/core';

import { debounceTime, distinctUntilChanged, filter, Observable, Subscription, switchMap } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { FormControl } from '@angular/forms';

import { StorageKey, StorageService } from '@/services/storage.service';
import { GeographyService } from '@/services/geography.service';
import { VendreService } from '@/services/vendre.service';
import { FilterService } from '@/services/filter.service';
import { GeolocService } from '@/services/geoloc.service';
import { AuthService } from '@/services/auth.service';
import { MainRoutes, SEARCH_DEBOUNCE, SEARCH_MIN_LENGTH } from '@/constants';

import {
  Agence, AgenceGroupe, ConnectedUser, GeolocFeature, GeolocSearch, Recherche, SearchFilters,
  SearchGeoAutoCompletion, SearchLocation, SearchMode
} from '@/models';

import { SearchBarSuggestionComponent } from './search-bar-suggestion/search-bar-suggestion.component';

export type SearchType = 'estimer' | 'location' | 'vente';

@Component({
  selector: 'app-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SearchBarComponent implements OnInit, OnDestroy {
  @Input() agenceGroup?: AgenceGroupe;
  @Input() agence?: Agence;

  @ViewChildren(SearchBarSuggestionComponent) suggestions: QueryList<SearchBarSuggestionComponent>;

  private subscriptions = new Subscription();
  private currentId?: number;
  private geoCoder: string;
  private address_name: string;
  private city_name: string;
  private city_zip: string;
  private postcode: string;

  public MainRoutes = MainRoutes;

  public showDropdown = false;
  public searchType: SearchType = 'estimer';
  public autocompleteCities: SearchLocation[] = [];
  public autocompleteDepartments: SearchLocation[] = [];
  public autocompleteAddresses: GeolocFeature[] = [];
  public connectedUser?: ConnectedUser;
  public savedSearchTypeSell: Recherche[] = [];
  public savedSearchTypeRent: Recherche[] = [];
  public searchInput = new FormControl('');

  constructor(
    private geolocService: GeolocService,
    private vendreService: VendreService,
    private activatedRoute: ActivatedRoute,
    private filterService: FilterService,
    private storageService: StorageService,
    private geographyService: GeographyService,
    private authService: AuthService,
    private cd: ChangeDetectorRef,
    private router: Router
  ) {
    afterNextRender(() => {
      this.changeSearchType((window.innerWidth < 820) ? 'vente' : 'estimer');
      this.cd.markForCheck();
    });
  }

  ngOnInit(): void {
    this.subscriptions.add(
      this.authService.connectedUser$.subscribe((user) => {
        this.connectedUser = user;
        this.setSavedSearches();
        this.cd.markForCheck();
      })
    );

    this.subscriptions.add(
      this.searchInput.valueChanges.pipe(
        filter(this.canAutoComplete),
        distinctUntilChanged(),
        debounceTime(SEARCH_DEBOUNCE),
        switchMap(this.doAutoComplete)
      ).subscribe((suggestions) => {
        this.processReceivedLocations(suggestions);
        this.cd.markForCheck();
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  /**
   * Process submit button click
   */
  onSubmitButton(): void {
    if (this.searchType === 'estimer') {
      let params: Record<string, any>;

      if (this.geoCoder) {
        const geoCoder = this.activatedRoute.snapshot.paramMap.get(this.geoCoder);

        this.geolocService.updateGeoCoder(this.geoCoder);

        this.vendreService.address_name = this.address_name;
        this.vendreService.city_zip = +this.city_zip;
        this.vendreService.city_name = this.city_name;
        this.vendreService.postcode = +this.postcode;

        this.geoCoder = geoCoder;

        this.close();

        params = { fragment: 'step_2' };

        if (this.agence) {
          params['queryParams'] = { agence_id: this.agence.id };
        } else if (this.agenceGroup) {
          params['queryParams'] = { agence_id: this.agenceGroup.agences[0].id };
        }
      }

      this.router.navigate([MainRoutes.Estimer], params);

    } else if (this.searchType === 'vente') {
      this.router.navigate([MainRoutes.Acheter]);
    } else if (this.searchType === 'location') {
      this.router.navigate([MainRoutes.Louer]);
    }
  }

  /**
   * Close the suggestions panel
   */
  close(): void {
    if (this.showDropdown) {
      this.autocompleteDepartments = [];
      this.autocompleteAddresses = [];
      this.autocompleteCities = [];
      this.showDropdown = false;
    }
  }

  /**
   * Change active tab
   * @param type The tab type
   */
  changeSearchType(type: SearchType): void {
    this.searchType = type;
    this.resetInput();
    this.close();
  }

  /**
   * Handle keyboad keys when suggestions dropdown is opened.
   * @param event The keyboad event
   */
  handleKeydown(event: any): void {
    const count = this.suggestions.length;

    if (count > 0) {
      if (event.code === 'Enter') {
        const el = this.suggestions.get(this.currentId);
        if (el) {
          if (el.location) {
            this.selectLocation(el.location);
          } else if (el.address) {
            this.selectAddress(el.address);
          }
          return;
        }
      }

      let position = this.currentId;

      if (event.code === 'ArrowDown') {
        if (++position >= count) {
          position = 0;
        }
      } else if (event.code === 'ArrowUp') {
        if (--position < 0) {
          position = count - 1;
        }
      }

      this.selectSuggestion(position);
    }
  }

  hasLastSearch(type: SearchType): SearchFilters {
    return (this.storageService.getObject<SearchFilters>(StorageKey.LastSearch) ?? [])[type];
  }

  lastSearchTextFromParams(type: SearchType): string[] {
    const lastSearch = this.hasLastSearch(type);
    const lastSearchText: string[] = [];
    let surfaceText: string;

    const locations = lastSearch.searchLocations.map((location) => location.name).join(',');
    lastSearchText.push(locations);

    if (lastSearch.typeBien.length) {
      lastSearchText.push(lastSearch.typeBien.join(','));
    }

    if (lastSearch.nb_pieces) {
      lastSearchText.push(lastSearch.nb_pieces + ' pièces');
    }

    if (lastSearch.surface_from && lastSearch.surface_to) {
      surfaceText = `${lastSearch.surface_from} - ${lastSearch.surface_to}`;
    } else if (lastSearch.surface_from) {
      surfaceText = lastSearch.surface_from;
    } else if (lastSearch.surface_to) {
      surfaceText = lastSearch.surface_to;
    }

    if (surfaceText) {
      lastSearchText.push(surfaceText + ' m2');
    }

    return lastSearchText;
  }

  goToLastSearch(type: SearchType): void {
    // Update params and set last_search, redirect
    const lastSearch = this.hasLastSearch(type);
    this.filterService.updateFilters('annonces', lastSearch);
    this.filterService.buildRouteFromFilters({ mode: 'annonces' });
  }

  searchSavedSearches(searchTerm: Recherche): void {
    this.filterService.resetFilters();

    this.filterService.updateFilters('annonces', searchTerm);
    this.filterService.buildRouteFromFilters({ mode: 'annonces' });
  }

  /**
   * Geolocalize user and route to annonces pages
   */
  handleAskForGeoloc(): void {
    this.subscriptions.add(
      this.geolocService.getSearchLocationFromPosition().subscribe((searchLocation) => {
        this.geolocService.updateGeolocVille(searchLocation);

        this.filterService.updateFilters('annonces', { searchLocations: [searchLocation], type_annonce: this.searchType });
        this.filterService.updateFilters('agences', { searchLocations: [searchLocation] });

        if (this.agenceGroup) {
          this.filterService.updateFilters('annonces', { era_groupe_id: this.agenceGroup.era_agence_principale_id });
        } else if (this.agence) {
          this.filterService.updateFilters('annonces', { agence_id: this.agence.id });
        }

        this.filterService.buildRouteFromFilters({ mode: 'annonces' });
      })
    );
  }

  /**
   * Test if autocompletion can be requested
   * @param query The current input content
   * @returns true when enough characters has been entered, else false
   */
  private canAutoComplete = (query: string): boolean => {
    if (query?.length < SEARCH_MIN_LENGTH) {
      this.close();
      return false;
    }
    return true;
  };

  /**
   * Launch the autocompletion request
   * @param query The current input content
   * @returns The request observable
   */
  private doAutoComplete = (query: string): Observable<GeolocSearch | SearchGeoAutoCompletion> => {
    return (this.searchType === 'estimer') ?
      this.geographyService.getAddressAutocomplete(query) :
      this.geographyService.getAllAutoComplete(query);
  };

  /**
   * Process the received suggestions
   * @param data
   */
  private processReceivedLocations(data: GeolocSearch | SearchGeoAutoCompletion): void {
    if (this.searchType === 'estimer') {
      const { features } = data as GeolocSearch;
      this.autocompleteAddresses = features;
    } else {
      const { villes, departements } = data as SearchGeoAutoCompletion;
      this.autocompleteDepartments = departements;
      this.autocompleteCities = villes;
    }

    this.showDropdown = true;

    // Let redraw list before selecting first element
    setTimeout(() => this.selectSuggestion(0), 0);
  }

  /**
   * Select a suggested location item
   * @param locationItem The selected item
   */
  selectLocation(locationItem: SearchLocation): void {
    const mode: SearchMode = (this.searchType === 'estimer') ? 'agences' : 'annonces';

    this.filterService.updateFilters(mode, {
      searchLocations: [locationItem],
      type_annonce: this.searchType
    });

    if (this.agenceGroup) {
      this.filterService.updateFilters(mode, { era_groupe_id: this.agenceGroup.era_agence_principale_id });
    } else if (this.agence) {
      this.filterService.updateFilters(mode, { agence_id: this.agence.id });
    }

    this.close();
    this.resetInput();

    this.filterService.buildRouteFromFilters({ mode });
  }

  /**
   * Select a suggested address item
   * @param address
   */
  selectAddress(address: GeolocFeature): void {
    const { name, postcode, city, citycode } = address.properties;
    const [lat, long] = address.geometry.coordinates;

    this.geolocService.updateNearestAgency(`${long}%2C${lat}`, long, lat);

    this.geoCoder = name;

    this.address_name = name;
    this.postcode = postcode;
    this.city_name = city;
    this.city_zip = citycode;

    this.showDropdown = false;
    this.autocompleteAddresses = [];

    this.onSubmitButton();
  }

  /**
   * Build saved search lists (one for sells and another for rents)
   */
  private setSavedSearches(): void {
    if (this.connectedUser) {
      const getCity = (recherche: Recherche) => (
        recherche.searchLocations.length === 0 ? 'France' : recherche.searchLocations.map((item) => item.name).join(', ')
      );

      this.savedSearchTypeSell = this.connectedUser.recherches.filter(
        (recherche) => recherche.type_annonce === 'vente'
      ).map(
        (recherche) => ({ ...recherche, city: getCity(recherche) })
      );

      this.savedSearchTypeRent = this.connectedUser.recherches.filter(
        (recherche) => recherche.type_annonce === 'location'
      ).map(
        (recherche) => ({ ...recherche, city: getCity(recherche) })
      );

    } else {
      this.savedSearchTypeSell = [];
      this.savedSearchTypeRent = [];
    }
  }

  /**
   * Reset input field
   */
  private resetInput(): void {
    this.searchInput.setValue('');
    this.geoCoder = '';
  }

  /**
   * Select the suggestion at the given index
   * @param index The suggestion position
   */
  private selectSuggestion(index: number): void {
    let el = this.suggestions.get(this.currentId);
    if (el) {
      el.select(false);
    }

    el = this.suggestions.get(index);
    if (el) {
      el.select(true);
    }

    this.currentId = index;
  }
}
