import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, OnDestroy, Self, ElementRef, ViewEncapsulation } from '@angular/core';
import { AbstractControl, ControlContainer, ControlValueAccessor, NgControl } from '@angular/forms';
import { Subscription } from 'rxjs';

import { ToolsService } from '@/services/tools.service';

@Component({
  selector: 'app-form-input-telephone',
  templateUrl: './form-input-telephone.component.html',
  styleUrls: ['./form-input-telephone.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class FormInputTelephoneComponent implements OnInit, OnDestroy, ControlValueAccessor {
  @Input() id?: string | number;
  @Input() label?: string;
  @Input() icon?: string;
  @Input() options?: Record<string, string>;
  @Input() validationErrors = true;

  public langList = ['fr'];
  public required = false;
  public focus = false;
  public error?: any;
  public parentForm: AbstractControl<any, any>;
  public formControlName?: string | number;

  private subscriptions = new Subscription();

  constructor(
    @Self() public ngControl: NgControl,
    private controlContainer: ControlContainer,
    private cd: ChangeDetectorRef,
    private el: ElementRef,
    private toolsService: ToolsService
  ) {
    ngControl.valueAccessor = this;
  }

  ngOnInit(): void {
    // Store a reference to the parent form group in 'parentForm' by accessing the control container.
    // This allows us to interact with the form group that contains this component's control.
    this.parentForm = this.controlContainer.control;

    // Assign the name of this control (from ngControl) to 'formControlName'.
    // This ensures the control is correctly registered within the parent form for validation and value tracking.
    this.formControlName = this.ngControl.name;

    this.required = this.toolsService.hasRequiredValidator(this.ngControl.control);

    this.subscriptions.add(
      this.ngControl.statusChanges?.subscribe((status) => {
        this.error = this.ngControl.dirty && status === 'INVALID';
        this.cd.markForCheck();
      })
    );

    if (this.options) {
      Object.entries(this.options).forEach(([key, value]) => {
        this.el.nativeElement[key] = value;
      });
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  // ControlValueAccessor methods
  writeValue(): void {}
  registerOnChange(): void {}
  registerOnTouched(): void {}

  // Other methods
  handleFocus(focus: boolean): void {
    this.focus = focus;
  }
}
