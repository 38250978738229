<div class="team-member-card transition-all" [ngClass]="{ 'column': column }">
  <div class="image-container">
    <img [src]="team.photo ? team.photo : '/assets/imgs/era_bordure.svg'" alt="" />
  </div>

  <div class="member-details">
    <p class="display-text-18px name">
      <strong>{{ team.prenom }} {{ team.nom }}</strong>
    </p>

    <p class="display-text-12px uppercase">
      {{ team.fonction }}
    </p>

    <div class="tags">
      @if (team.club_excellence === 1) {
        <p class="display-text-14px">Club Excellence</p>
      }

      @if (team.club_excellence_premium === 1) {
        <p class="display-text-14px">Club Excellence Premium</p>
      }

      @if (langues) {
        <p class="display-text-14px">{{ langues }}</p>
      }
    </div>

    @if (team.agent_co === 1) {
      <p class="agent-co">
        {{ team.agent_co === 1 ? ('commercial_agent' | transloco | titlecase) : '' }}
        {{ team.agent_co === 1 ? 'N° RSAC' + team.rsac + ' - EI' : '' }}
      </p>
    }

    @if ((team.enseigne || agence?.enseigne || team.agence?.enseigne) && showAddress) {
      <div class="team-card-address">
        @if (team.enseigne) {
          <p class="display-text-14px">
            {{ team.enseigne }}

            @if (team.arrondissement || team.ville) {
              <span>{{ team.ville }} {{ team.arrondissement ? '(' + team.arrondissement + ')' : '' }}</span>
            }
          </p>
        }

        @if (agence?.enseigne) {
          <p class="display-text-14px">
            {{ agence.enseigne }}

            @if (agence?.arrondissement || agence?.ville) {
              <span>{{ agence?.ville }} {{ agence?.arrondissement ? '(' + agence?.arrondissement + ')' : '' }}</span>
            }
          </p>
        }

        @if (team.agence?.enseigne) {
          <p class="display-text-14px">
            {{ team?.agence?.enseigne }}

            @if (team?.agence?.arrondissement || team?.agence?.ville) {
              <span>{{ team?.agence?.ville }} {{ team?.agence?.arrondissement ? '(' + team?.agence?.arrondissement + ')' : '' }}</span>
            }
          </p>
        }
      </div>
    }
  </div>

  <div [ngClass]="isList ? 'list' : 'buttons'">
    <app-contact-card [team]="team" [agence]="agence ?? team.agence" [isList]="true" />
  </div>

</div>