import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

import { Agence, Avis, Negociateur, AgenceGroupe } from '@/models';
import { MainRoutes } from '@/constants';

@Component({
  selector: 'app-opinion',
  templateUrl: './opinion.component.html',
  styleUrls: ['./opinion.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OpinionComponent implements OnInit {
  @Input() agence?: Agence;
  @Input() team?: Negociateur;
  @Input() groupeAgence?: AgenceGroupe;

  public MainRoutes = MainRoutes;

  public rate: string;
  public avisLink: string;
  public avisList: Avis[];
  public total = 0;

  ngOnInit(): void {
    if (this.agence) {
      const { avis, avis_moyenne, slug } = this.agence;

      this.setData(avis.length, avis_moyenne, `/${slug}/avis`, avis);
    }

    if (this.groupeAgence) {
      const { agences, avis_total, avis_moyenne, slug } = this.groupeAgence;
      const list = agences.flatMap((agence) => agence.avis);

      this.setData(avis_total, avis_moyenne, `${MainRoutes.Groupes}/${slug}/temoignages`, list);
    }

    if (this.team) {
      const { avis, id } = this.team;
      const rate = this.total > 0 ? avis.map((item) => item.avg_score).reduce((a, b) => a + b, 0) / this.total : 0;

      this.setData(avis.length, rate, `${MainRoutes.Effectifs}/${id}/avis`, avis);
    }
  }

  private setData(total: number, rate: number, link: string, list: Avis[]): void {
    this.total = total;
    this.rate = rate.toFixed(2);
    this.avisLink = link;
    this.avisList = list.filter((item) => item.comment.length > 0);
  }
}
