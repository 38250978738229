<div class="agences-container">
	@if (loading) {
		<app-agence-card-loader *ngFor="let i of [].constructor(8)" />
	} @else {
		<app-agence-card [agence]="agence" *ngFor="let agence of agences | paginate: {
				itemsPerPage: this.itemsPerPage,
				currentPage: this.currentPage,
				totalItems: this.totalItems
			}"
		/>
	}
</div>
@if (!loading) {
	@if (navByLink) {
		<app-pagination-link prompt="Agencies" [itemsPerPage]="itemsPerPage" />
	} @else {
		<app-pagination (pageChange)="changePage($event)" prompt="Agencies" [itemsPerPage]="itemsPerPage" />
	}
}
