import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-modal-legal-notice',
  templateUrl: './modal-legal-notice.component.html',
  styleUrls: ['./modal-legal-notice.component.scss']
})
export class ModalLegalNoticeComponent {
  @Input() agence;
  @Input() modalLegalNoticeOpened;
  @Input() pageType!: 'agence' | 'annonce';

  @Output() closeModalLegalNotice = new EventEmitter();

  onCloseModalLegalNotice() {
    this.closeModalLegalNotice.emit();
  }
}
