<div class="select-field">
  <div class="select-field-label" [ngClass]="{ error: error && validationErrors }">

    <select [id]="id" [(ngModel)]="selected" [disabled]="disabled">
      @if (placeholder) {
        <option value="">{{ placeholder | transloco }}</option>
      }

      @for (item of items; track itemKey ? item[itemKey] : item) {
        @let value = itemKey ? item[itemKey] : item;
        @let label = itemLabel ? item[itemLabel] : item;

        <option [value]="value" [selected]="value === selected">
          {{ label }}

          @if (unit) {
            {{ unit }}
          }
        </option>
      }
    </select>

    <label [for]="id" [ngClass]="{ transformed: !selected }" data-testid="label">
      @if (icon) {
        <span class="{{ icon }} not-color"></span>
      }

      {{ label | transloco }}

      @if (required) {
        <span data-testid="required">*</span>
      }
    </label>
  </div>
</div>

@if (error && validationErrors) {
  <app-form-errors [field]="ngControl" />
}
