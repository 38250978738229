import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-password-tips',
  templateUrl: './password-tips.component.html',
  styleUrl: './password-tips.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PasswordTipsComponent implements OnInit, OnDestroy {
  @Input() field!: AbstractControl | null;

  private subscriptions = new Subscription();

  constructor(private cd: ChangeDetectorRef) {}

  rules = [
    { key: 'MIN_LENGTH_8', label: 'PASSWORD_TIPS.MIN_LENGTH_8', valid: false, regex: /.{8,}/ },
    { key: 'NOT_SPECIAL', label: 'PASSWORD_TIPS.NOT_SPECIAL', valid: true, regex: /^[A-Za-z0-9]*$/ },
    { key: 'ONE_NUMBER', label: 'PASSWORD_TIPS.ONE_NUMBER', valid: false, regex: /\d/ },
    { key: 'ONE_LOWERCASE', label: 'PASSWORD_TIPS.ONE_LOWERCASE', valid: false, regex: /[a-z]/ },
    { key: 'ONE_UPPERCASE', label: 'PASSWORD_TIPS.ONE_UPPERCASE', valid: false, regex: /[A-Z]/ }
  ];

  ngOnInit(): void {
    if (this.field) {
      this.subscriptions.add(
        this.field.valueChanges.subscribe((value: string) => {
          this.validatePassword(value);
          this.cd.markForCheck();
        })
      );
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private validatePassword(value: string): void {
    this.rules.forEach((rule) => rule.valid = rule.regex.test(value));
  }
}
