<div
  class="statement-card"
  [ngClass]="(!avis.satisfaction && !avis.recommandation) || (team && avis.satisfaction && avis.recommandation) || !team ? 'h-full' : 'height-team'"
>

	<div class="statement-card-star">
    <app-rating-stars [rate]="avis.avg_score" />
		<span>{{ avis.avg_score | number:'':'fr-FR' }}</span>
	</div>

  <div class="statement-card-title">
    <div class="statement-card-title-picto">
      @if (avis.firstname && avis.lastname) {
        <p>{{ avis.firstname?.charAt(0) }} {{ avis.lastname?.charAt(0) }}</p>
      } @else {
        <p>{{ avis.name.charAt(0) }} {{ avis.name.charAt(avis.name.length - 2) }}</p>
      }
    </div>

    <div>
      @if (avis.firstname && avis.lastname) {
        <p class="display-text-18px">
          {{ avis.firstname }}
          <span>{{ avis.lastname?.charAt(0) }}.</span>
        </p>
      } @else {
        <p class="display-text-18px">{{ avis.name }}</p>
      }
      <p class="display-text-12px">{{ 'published at' | transloco }} {{ avis.posted_at }}</p>
    </div>
  </div>

  @if (agence || groupeAgence) {
    <div class="statement-card-address">
      @if (agence?.enseigne) {
        <p class="display-text-14px">{{ agence?.enseigne }}</p>
      }
      @if (groupeAgence?.nom) {
        <p class="display-text-14px">{{ groupeAgence.nom }}</p>
      } @else if (agence?.nom) {
        <p class="display-text-14px">{{ agence?.nom }}</p>
      }
    </div>
  }

  <div class="statement-card-contact">
    @if (avis.comment && avis.comment.length) {
      <div>
        <p id="comment">
          <span #commentText >"{{ avis.comment }}"</span>
        </p>
      </div>
    }
  </div>

  @if (showMoreButton) {   
    <div class="show-more" (click)="showMore = !showMore">
      <p class="display-text-14px underline cursor-pointer">{{ 'see_more' | transloco }}</p>
    </div>
  }

  @if (showMore) {
    <div (click)="showMore = !showMore" class="show-more-content">
      <span (click)="showMore = !showMore" class="icon-close"></span>
      <p>{{ avis.comment }}</p>
    </div>
  }

  @if (avis.satisfaction && avis.recommandation) {
    <div class="recommandation">
      <div class="recommandation-content">
        <app-donut
          size="tiny"
          text="{{ avis.satisfaction }} %"
          first="{{ avis.satisfaction/100 }}"
          second="{{ 1 - ( avis.satisfaction/100 ) }}"
        />
        <p class="display-text-12px">{{ 'satisfaction' | transloco }}</p>
      </div>
      <div class="recommandation-content">
        <app-donut
          size="tiny"
          text="{{ avis.recommandation }} %"
          first="{{ avis.recommandation/100 }}"
          second="{{ 1 - ( avis.recommandation/100 ) }}"
        />
        <p class="display-text-12px">{{ 'recommandation' | transloco }}</p>
      </div>
    </div>
  } @else if (team) {
    <div class="statement-card-address red">
      @if (team.agence.enseigne) {
        <p class="display-text-14px">{{ team.agence.enseigne }}</p>
      }
    </div>
  }
</div>
