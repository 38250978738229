import { Component, Input } from '@angular/core';

import { SeoFaqEntry } from '@/models';

@Component({
  selector: 'app-faq-container',
  templateUrl: './faq-container.component.html',
  styleUrls: ['./faq-container.component.scss']
})
export class FaqContainerComponent {
  @Input() faqs: SeoFaqEntry[] = [];
  @Input() title = '';
  @Input() tag: string;
}
