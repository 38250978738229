<div class="legal-notice">
	<div class="breadcrumb mr-auto w-full">
		<div class="breadcrumb-content">
			<div class="breadcrumb-back" routerLink="/">
				<div class="breadcrumb-back-arrow">
					<span class="icon-arrow-left"></span>
				</div>
				<p class="display-text-16px">
					{{ 'previous' | transloco }}
				</p>
			</div>
			<div class="breadcrumb-links">
				<a routerLink="/">{{ 'homepage' | transloco }}</a>
				<span class="icon-chevron-right"></span>
				<span> {{ legal_notice?.mentions_legales_titre }}</span>
			</div>
		</div>
	</div>

	<div
		class="w-full h-450px bg-cover bg-top bg-no-repeat flex items-center justify-center bg-custom p-80px pl-140px pr-140px"
		[ngStyle]="{ 'background-image': 'url(assets/imgs/landing-header.png)' }"
	>
		<h1 class="display-h1 text-white">
			{{ legal_notice?.mentions_legales_titre }}
		</h1>
	</div>
	<div class="main-containers data-legal">
		<div class="flex w-full flex-col max-w-877px">
			<div class="display-text-16px" [innerHTML]="legal_notice?.mentions_legales_contenu"></div>
		</div>
	</div>
</div>
