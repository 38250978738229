<div
	class="block-contact-agency-presentation"
	[ngClass]="type === 'agence' || type === 'negociateur' || type === 'annonce' ? 'flex-col' : 'flex-row'"
>
	<div
		[ngClass]="{
			'emploi': emploi || type === 'era',
			'agence': type === 'agence' || type === 'negociateur'
		}"
		class="block-contact-agency-presentation-img"
	>
		<img
			[ngClass]="{ 'agence': type === 'agence' || type === 'negociateur' }"
			src="{{ agence?.photo ?? '/assets/imgs/agence.jpg' }}"
			alt=""
		/>
	</div>

	<div class="block-contact-agency-presentation-address">
		<h1
			class="display-text-18px"
			[ngClass]="{ 'mt-30px': (type === 'agence' || type === 'negociateur' || type === 'annonce' || type === 'era') }"
		>
			{{ agence?.enseigne || agence?.nom }}
		</h1>

		<div class="display-flex mb-20px">
			@if (emploi || (type === 'era')) {
				<p><span class="icon-location"></span></p>
			}
			<div>
				<p class="display-text-16px">{{ agence?.adresse }}</p>
				<p class="display-text-16px">{{ agence?.codepostal }} {{ agence?.ville }}</p>
			</div>
		</div>

		@if (type !== 'noPhone') {
			@if (emploi) {
				<app-button-telephone
					[telephone]="emploi?.agence?.telephone"
					aspect="arrow-blue"
					size="large"
				/>
			}	@else	if ((type === 'era') && agence) {
				<app-button-telephone
					[telephone]="agence?.telephone"
					aspect="arrow-blue"
					size="large"
				/>
			}
		}

		@if (annonce || agence) {
			@if (type === 'era') {
				<app-button
					[href]="agence?.link"
					[text]="agence.texte"
					[translate]="false"
					aspect="arrow-blue"
					size="large"
				/>
			} @else	if (type !== 'negociateur') {
				<app-button
					[link]="'/' + agence?.slug"
					aspect="arrow-blue"
					size="large"
					text="web"
				/>
			}
		}

	</div>
</div>