import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-button-circle',
  templateUrl: './button-circle.component.html',
  styleUrl: './button-circle.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ButtonCircleComponent {
  @Input() icon!: string;
  @Input() shadow = true;
  @Input() hoverShadow = false;
}
