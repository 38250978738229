<div class="flex flex-row w-full">
	@if (pictureUrl) {
		<div class="picture br-20px">
			<img [src]="pictureUrl" alt="" />
		</div>
	}
	@if (content?.length) {
		<div class="flex flex-col content">
			@if (title) {
				<h2 class="display-h3">{{ title }}</h2>
			}
			<div [ngClass]="{ full: full }" class="content-container">
				@for (c of content; track c.title) {
					<div>
						<h2 class="display-h3">{{ c.title }}</h2>
						<p class="display-text-16px" [innerHTML]="c.text"></p>
					</div>
				}
				@if (!full) {
					<div class="smooth-div"></div>
				}
			</div>
			<p class="display-text-12px underline cursor-pointer" (click)="full = !full">
				{{ (full ? 'see_less' : 'see_more') | transloco }}
			</p>
		</div>
	}
</div>
