import { Injectable, TemplateRef } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { TranslocoService } from '@jsverse/transloco';
import { AbstractControl } from '@angular/forms';

import { MapTypebienApiToDisplayPipe } from '@/modules/shared/pipes/mapTypebienApiToDisplay.pipe';
import { Agence, Annonce, Coordinates, GeoData, Negociateur } from '@/models';
import { MainRoutes } from '@/constants';

@Injectable({
  providedIn: 'root'
})
export class ToolsService {
  private mapTypebienApiToDisplayPipe = new MapTypebienApiToDisplayPipe();

  constructor(private translate: TranslocoService) {}

  floor(value: number): number {
    return Math.floor(value);
  }

  numberWithSpaces(value) {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  }

  nextImage(id, photos: string[]): void {
    const popupId = id;
    const image = document.getElementById('img_popup_' + popupId) as HTMLImageElement;
    const currentIndex = +image.getAttribute('attr-picture-index');
    const nextIndex = currentIndex < photos.length - 1 ? currentIndex + 1 : 0;
    image.setAttribute('src', photos[nextIndex]);
    image.setAttribute('attr-picture-index', '' + nextIndex);
  }

  previousImage(id, photos: string[]): void {
    const popupId = id;
    const image = document.getElementById('img_popup_' + popupId) as HTMLImageElement;
    const currentIndex = +image.getAttribute('attr-picture-index');
    const nextIndex = currentIndex > 0 ? currentIndex - 1 : photos.length - 1;
    image.setAttribute('src', photos[nextIndex]);
    image.setAttribute('attr-picture-index', '' + nextIndex);
  }

  /**
   * Create team map popup HTML
   * @param team The team object
   * @param template the popup Template
   * @returns The popup HTML element
   */
  createTeamMapPopup(team: Negociateur, template: TemplateRef<any>): HTMLElement {
    const divPopup = document.createElement('div');
    const teamPopup = this.createFromTemplate(template, { team: team });
    divPopup.appendChild(teamPopup);
    return divPopup;
  }

  /**
   * Insert data in a template form a context
   * @param template the popup Template
   * @param context The team object
   * @returns The popup HTML element
   */
  private createFromTemplate(template: TemplateRef<any>, context: { team: Negociateur }) {
    const view = template.createEmbeddedView(context);
    view.detectChanges();
    const div = document.createElement('div');
    div.appendChild(view.rootNodes[0]);
    return div;
  }

  /**
   * Create annonces map popup HTML
   * TODO-EL: use Angular ng-template to describe the HTML element
   * @param a The annonce object
   * @returns The popup HTML element
   */
  createAnnonceMapPopup(a: Annonce): HTMLElement {
    const divPopup = document.createElement('div');
    divPopup.setAttribute('id', a.id.toString());
    divPopup.className = 'popup-annonce';

    const div1 = document.createElement('div');
    div1.className = 'img-container-popup';

    const previousBtn = document.createElement('span');
    previousBtn.className = 'icon-chevron-left chevron chevron-left';
    const nextBtn = document.createElement('span');
    nextBtn.className = 'icon-chevron-right chevron chevron-right';

    const localisationTexte = a.precision_geoloc === '1' ? 'approximate_location_property' : 'precise_location_property';

    previousBtn.addEventListener('click', () => {
      this.previousImage(a.id, a.photo);
    });

    nextBtn.addEventListener('click', () => {
      this.nextImage(a.id, a.photo);
    });

    const innerHtmlContent1 = `
			<a href="${MainRoutes.Annonce}/${a.id}">
				<img
          id=img_popup_${a.id}
          attr-picture-index=0
          class="h-full w-full object-cover img-in-popup"
          src="${a.photo.length ? a.photo[0] : ''}"
				  alt=""
				/>
			</a>
		`;

    const innerHtmlContent2 = `
			<a style="text-decoration: none; box-shadow: none; outline: none;" href="${MainRoutes.Annonce}/${a.id}">
				<div class="flex flex-col">
					<span class="display-text-16px type-annonce">
            ${this.translate.translate(this.mapTypebienApiToDisplayPipe.transform(a?.type_bien))}
          </span>
					<span class="display-text-16px details">
            ${a.type_bien !== 'Immeuble' && a.type_bien !== 'Terrain' ? a.nb_pieces + ' ' + this.translate.translate('rm') : ''}
            <app-text-round-separator />
            ${a.type_bien !== 'Immeuble' && a.type_bien !== 'Terrain' ? a.nb_chambres + ' ' + this.translate.translate('bdrm') : ''}
            <app-text-round-separator /> ${a.surface_habitable} m²
          </span>
					<span class="display-text-18px prix">
            ${this.numberWithSpaces(a?.prix)} €
          </span>
					<div class="flex items-center mt-10px">
						<span class="icon-location fs-20px mr-8px grey-icon"></span>
						<span class="display-text-10px font-medium">
              ${this.translate.translate(localisationTexte)}
            </span>
					</div>
				</div>
			</a>
		`;

    div1.innerHTML = innerHtmlContent1;
    if (a.photo.length > 1) {
      div1.appendChild(previousBtn);
      div1.appendChild(nextBtn);
    }

    divPopup.appendChild(div1);

    const div2 = document.createElement('div');
    div2.innerHTML = innerHtmlContent2;
    divPopup.appendChild(div2);

    return divPopup;
  }

  /**
   * Create an agence map popup
   * TODO-EL: use Angular ng-template to describe the HTML element
   * @param agence The agence
   * @returns The popup HTML element
   */
  createAgenceMapPopup(agence: Agence): HTMLElement {
    const divPopup = document.createElement('div');
    divPopup.className = 'popup-agence';

    divPopup.innerHTML = `
      <a href="/${agence.slug}">
        <img
          src=" ${agence.photo ? agence.photo : '/assets/imgs/agence.jpg'}"
          class="h-full w-full object-cover img-in-popup"
          alt=""
        />
        <p class="display-text-16px enseigne-text">
          ${agence.statut === 2 ? '(Prochainement) ERA Immobilier ' + agence.ville + ',' + agence.codepostal : agence.enseigne}
        </p>
        <div class="flex items-center">
          <span class="icon-location fs-20px mr-8px grey-icon"></span>
          <div class=" adresse-text display-text-10px">
            <p class="display-text-10px adresse-text">${agence.adresse ? agence.adresse : 'Non renseigné'}</p>
            <div class="flex">
              <p class="">${agence.codepostal} ${agence.ville}</p>
            </div>
          </div>
        </div>
      </a>
    `;

    return divPopup;
  }

  /**
   * Concat all values in the given objects table corresponding to the given key
   * @example [{ a: 1 }, { a: 12 }, { a: 43 }] => key= 'a' => '1,12,43'
   * @param table The source array
   * @param key The object attribute key
   * @returns The coma separated string
   */
  arrayValueToString(table: any[], key: string): string {
    return table.filter((l) => !!l[key]).map((l) => l[key]).join(',');
  }

  /**
   * Depp compare two objects.
   * https://medium.com/@stheodorejohn/javascript-object-deep-equality-comparison-in-javascript-7aa227e889d4
   * @param obj1 The first one
   * @param obj2 The second one
   * @returns true if objects are equals, else false
   */
  deepEqual<T extends object>(obj1: T, obj2: T): boolean {
    // Base case: If both objects are identical, return true.
    if (obj1 === obj2) {
      return true;
    }
    // Check if both objects are objects and not null.
    if (typeof obj1 !== 'object' || typeof obj2 !== 'object' || obj1 === null || obj2 === null) {
      return false;
    }
    // Get the keys of both objects.
    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);
    // Check if the number of keys is the same.
    if (keys1.length !== keys2.length) {
      return false;
    }
    // Iterate through the keys and compare their values recursively.
    for (const key of keys1) {
      if (!keys2.includes(key) || !this.deepEqual(obj1[key], obj2[key])) {
        return false;
      }
    }
    // If all checks pass, the objects are deep equal.
    return true;
  }

  /**
   * Deep clone the given object
   * @param obj The source object
   * @returns The deep copy of the source object
   */
  deepCopy<T extends object>(obj: T): T {
    return structuredClone(obj);
  }

  /**
   * Remove from the given object any falsy entries
   * @param o The source object
   * @returns A partial version of the given object
   */
  sanityzeObject<T extends object>(o: T): Partial<T> {
    return Object.entries(o).reduce(
      (a, [k, v]) => (Array.isArray(v) ? v.length : !!v) ? { ...a, [k]: v } : a,
      {}
    );
  }

  /**
   * Normalize phone number string
   * @param phone The source string
   * @returns The normalized string
   */
  addSpacePhone(phone: string): string {
    return phone ? phone.replace(/\d{2}(?=.)/g, '$& ').replaceAll('.', '') : '';
  }

  /**
   * Build a new url by updating values of the current one
   * @param document The document object
   * @param params The query parameters that need to be updated
   * @returns An object containing the base route and the queryParams object
   */
  updateSearchParams(document: Document, params: Record<string, string>): { route: string, queryParams: Record<string, string> } {
    const newUrl = new URL(document.location.href);
    const route = decodeURIComponent(newUrl.pathname);

    Object.entries(params).forEach(([key, value]) => {
      newUrl.searchParams.delete(key);
      newUrl.searchParams.set(key, value);
    });

    return { route, queryParams: Object.fromEntries(newUrl.searchParams) };
  }

  /**
   * Filter null values from queryParams list (NEVER emit empty params on the request url)
   * @param queryParams the input queryParams
   * @returns filtered queryParams
   */
  buildQueryParams(queryParams: Record<string, any>): Record<string, any> {
    return Object.entries(queryParams).filter(([_, value]) => !!value).reduce((params, [key, value]) => ({
      ...params,
      [key]: value
    }), {});
  }

  buildHttpParams(queryParams: Record<string, any>): HttpParams {
    return new HttpParams({ fromObject: this.buildQueryParams(queryParams) });
  }

  /**
   * Compute the map center point, only if geoloc is not given via bbox.
   * In such a case, the map service will itself compute the bounding area.
   * @param geoloc The source location (either a string coma separated or a Coordinate object)
   * @returns The map coordinates
   */
  getGeolocCenter(geoloc: Coordinates | GeoData): Coordinates<number> {
    if (Array.isArray(geoloc)) {
      return { lng: geoloc[0], lat: geoloc[1] };
    }

    if (geoloc['lat']) {
      return { lng: +geoloc['lng'], lat: +geoloc['lat'] };
    }

    const geo = geoloc as GeoData;

    if (!geo.bbox) {
      if (geo.geoloc) {
        const [lat, lng] = (geoloc as GeoData).geoloc.split(',');
        return { lng: +lng, lat: +lat };
      }

      if (Array.isArray(geo.coordinates) && (geo.coordinates.length > 0)) {
        const coord = geo.coordinates[0];
        return { lng: +coord[0], lat: +coord[1] };
      }
    }

    return undefined;
  }

  /**
   * Checks if the given form control has a 'required' validator.
   *
   * @param {AbstractControl | null} control - The form control to check.
   * @returns {boolean} - Returns true if the 'required' validator is present, otherwise false.
   */
  hasRequiredValidator(control: AbstractControl | null): boolean {
    return control?.validator ? control.validator({} as AbstractControl)?.['required'] !== undefined : false;
  }
}
