import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { Agence } from '@/models';

@Component({
  selector: 'app-agence-card',
  templateUrl: './agence-card.component.html',
  styleUrls: ['./agence-card.component.scss']
})
export class AgenceCardComponent implements OnInit {
  @Input() agence: Agence;

  public agenceImmobiliere: boolean;
  public showCoords = false;

  constructor(private router: Router) { }

  ngOnInit(): void {
    this.agenceImmobiliere = this.router.url.startsWith('/agence-immobiliere');
  }

  onImgError(event) {
    event.target.src = '/assets/imgs/erabw.png';
  }

  addSpacePhone() {
    if (!this.agence.telephone) {
      return 'Non renseigné';
    }
    return this.agence.telephone.replace(/\d{2}(?=.)/g, '$& ').replaceAll('.', '');
  }
}
