<div class="input-field">
  <div class="input-field-label" [ngClass]="{ error: error && validationErrors }">

    <input readonly [id]="id" type="number" [disabled]="disabled" />

    <label [for]="id" class="transformed">
      @if (icon) {
        <span class="{{ icon }} not-color"></span>
      }

      {{ label | transloco }}

      @if (required) {
        <span>*</span>
      }
    </label>
  </div>

  <div class="input-field-counter" [ngClass]="{ error: error && validationErrors }">
    <div class="input-field-counter-button" (click)="decrement(value)"><span>-</span></div>
    <output>{{ value }}</output>
    <div class="input-field-counter-button plus" (click)="increment(value)"><span>+</span></div>
  </div>
</div>

@if (error && validationErrors) {
  <app-form-errors [field]="ngControl" />
}
