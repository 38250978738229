import { Directive, ElementRef, EventEmitter, Output, AfterViewInit, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appFocusBlur]'
})
export class FocusBlurDirective implements AfterViewInit {
  @Output() focusEvent = new EventEmitter<boolean>();

  constructor(
    private el: ElementRef,
    private renderer: Renderer2
  ) {}

  ngAfterViewInit() {
    const inputElement = this.el.nativeElement.querySelector('input');

    if (inputElement) {
      this.renderer.listen(inputElement, 'focus', () => {
        this.focusEvent.emit(true);
      });

      this.renderer.listen(inputElement, 'blur', () => {
        this.focusEvent.emit(false);
      });
    }
  }
}
