import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { SeoCrossLink } from '@/models';

@Component({
  selector: 'app-cross-links',
  templateUrl: './cross-links.component.html',
  styleUrls: ['./cross-links.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CrossLinksComponent {
  @Input() listes: SeoCrossLink[] = [];
  @Input() prefix = '';
  @Input() load = false;

  hidden: boolean[] = [true, true, true, true];
}
