import { Component, OnInit } from '@angular/core';

import { ContentService } from '@/services/content.service';

@Component({
  selector: 'app-legal-notice',
  templateUrl: './legal-notice.component.html',
  styleUrls: ['./legal-notice.component.scss']
})
export class LegalNoticeComponent implements OnInit {
  public legal_notice;

  constructor(private contentService: ContentService) {}

  ngOnInit() {
    this.getLegalNotice();
  }

  getLegalNotice() {
    this.contentService.getContent().subscribe(((data) => {
      this.legal_notice = data;
    }));
  }
}
