<div class="modal-legal-notice {{ pageType }}">
	<div class="container" [ngClass]="agence?.agences && 'list-agences'">
		<div class="container-header flex mb-15px">
			<h5 class="display-h3-28px">{{ 'legal_notice_agency' | transloco }}</h5>
			<div (click)="onCloseModalLegalNotice()">
				<span class="icon-close closeDrawModal"></span>
			</div>
		</div>

		<div class="container-content" [ngClass]="agence?.agences && 'list'" *ngIf="agence?.agences">
			<div class="mb-40px" *ngFor="let agency of agence?.agences">
				<p class="display-text-14px">
					{{ 'company_name' | transloco }} : {{ agency.raison_sociale ? agency.raison_sociale : "-" }}
				</p>
				<p class="display-text-14px">{{ 'trade_name' | transloco }} : {{ agency?.enseigne ? agency?.enseigne : "-" }}</p>
				<p class="display-text-14px">
					{{ registered_address' | transloco }}: {{ agency?.adresse_siege_social ? agency?.adresse_siege_social : "-" }}
				</p>
				<p class="display-text-14px">{{ 'phone' | transloco }} : {{ agency?.telephone ? agency?.telephone : "-" }}</p>
				<p class="display-text-14px">{{ 'email' | transloco }} : {{ agency?.email ? agency?.email : "-" }}</p>
				<p class="display-text-14px">{{ 'legal_form' | transloco }}: {{ agency?.forme_juridique ? agency?.forme_juridique : "-" }}</p>
				<p class="display-text-14px">{{ 'amount_of_share_capital' | transloco }} : {{ agency?.capital ? agency?.capital + " €" : "-" }}</p>
				<p class="display-text-14px">
					N°SIREN: {{ agency?.siren ? agency?.siren : "-" }} {{ agency?.rcs ? "RCS " + agency?.rcs : "-" }}
				</p>
				<p class="display-text-14px">N°TVA intra : {{ agency?.tva_cee ? agency?.tva_cee : "-" }}</p>
				<p class="display-text-14px">
					{{ 'professional_card_number_transaction' | transloco }} : {{ agency?.carte_transac ? agency?.carte_transac : "-" }} CCI
					{{ agency?.cartes_prefecture ? agency?.cartes_prefecture : "-" }}
				</p>
				<p class="display-text-14px">
					{{ 'professional_card_number_management' | transloco }} : {{ agency?.carte_gestion ? agency?.carte_gestion : "-" }} CCI
					{{ agency?.cartes_prefecture ? agency?.cartes_prefecture : "-" }}
				</p>
				<p class="display-text-14px">
					{{ guarantors_name_and_address' | transloco }} : {{ agency?.caisse_garantie ? agency?.caisse_garantie : "-" }}
					{{ agency?.caisse_garantie_adresse ? agency?.caisse_garantie_adresse : "-" }}
				</p>
				<p class="display-text-14px font-bold">
					{{ agency.non_detention_fond ? ('without_holding_funds' | transloco) : ('with_holding_funds' | transloco) }}
				</p>
				<p class="display-text-14px">
					{{ 'amount_of_the_financial_guarantee' | transloco }} : {{ agency?.montant_garantie ? agency?.montant_garantie + " €" : "-" }}
				</p>
				<div class="display-text-14px">
					<p>
						{{ 'seizing_a_mediator_free' | transloco }} :
					</p>
					<p class="ml-10px">{{ agency?.mediateur?.nom && agency?.mediateur?.nom }}</p>
					<p class="ml-10px">{{ agency?.mediateur?.email }}</p>
					<p class="ml-10px">{{ agency?.mediateur?.adresse }}</p>
				</div>
				<p class="display-text-14px">
					{{ 'franchise_notice' | transloco }}
				</p>
				<a href="{{ agency.bareme }}" target="_blank" class="btn btn-arrow-red-dark mt-30px">
					{{ 'see_fees' | transloco }}
				</a>
			</div>
		</div>

		<div class="container-content" *ngIf="!agence.agences">
			<p class="display-text-14px">{{ 'company_name' | transloco }} : {{ agence.raison_sociale ? agence.raison_sociale : "-" }}</p>
			<p class="display-text-14px">{{ 'trade_name' | transloco }} : {{ agence.enseigne ? agence.enseigne : "-" }}</p>
			<p class="display-text-14px">
				{{ 'registered_address' | transloco }} : {{ agence?.adresse_siege_social ? agence?.adresse_siege_social : "-" }}
			</p>
			<p class="display-text-14px">{{ 'phone' | transloco }} : {{ agence?.telephone ? agence?.telephone : "-" }}</p>
			<p class="display-text-14px">{{ 'email' | transloco }} : {{ agence?.email ? agence?.email : "-" }}</p>
			<p class="display-text-14px">{{ 'legal_form' | transloco }} : {{ agence?.forme_juridique ? agence?.forme_juridique : "-" }}</p>
			<p class="display-text-14px">{{ 'amount_of_share_capital' | transloco }} : {{ agence?.capital ? agence?.capital + " €" : "-" }}</p>
			<p class="display-text-14px">
				N°SIREN: {{ agence?.siren ? agence?.siren : "-" }} {{ agence?.rcs ? "RCS " + agence?.rcs : "-" }}
			</p>
			<p class="display-text-14px">N°TVA intra : {{ agence?.tva_cee ? agence?.tva_cee : "-" }}</p>
			<p class="display-text-14px">
				{{ 'professional_card_number_transaction' | transloco }} : {{ agence?.carte_transac ? agence?.carte_transac : "-" }} CCI
				{{ agence?.cartes_prefecture ? agence?.cartes_prefecture : "-" }}
			</p>
			<p class="display-text-14px">
				{{ 'professional_card_number_management' | transloco }}  : {{ agence?.carte_gestion ? agence?.carte_gestion : "-" }} CCI
				{{ agence?.cartes_prefecture ? agence?.cartes_prefecture : "-" }}
			</p>
			<p class="display-text-14px">
				{{ 'guarantors_name_and_address' | transloco }} : {{ agence?.caisse_garantie ? agence?.caisse_garantie : "-" }}
				{{ agence?.caisse_garantie_adresse ? agence?.caisse_garantie_adresse : "-" }}
			</p>
			<p class="display-text-14px font-bold">
				{{ agence?.non_detention_fond ? ('without_holding_funds' | transloco) : ('with_holding_funds' | transloco) }}
			</p>
			<p class="display-text-14px">
				{{ 'amount_of_the_financial_guarantee' | transloco }} : {{ agence?.montant_garantie ? agence?.montant_garantie + " €" : "-" }}
			</p>
			<div class="display-text-14px">
				<p>{{ 'seizing_a_mediator_free' | transloco }}:</p>
				<p class="ml-10px">{{ agence?.mediateur.nom && agence?.mediateur.nom }}</p>
				<p class="ml-10px">{{ agence?.mediateur.email }}</p>
				<p class="ml-10px">{{ agence?.mediateur.adresse }}</p>
			</div>
			<p class="display-text-14px">
				{{ 'franchise_notice' | transloco }}
			</p>
			<a href="{{ agence?.bareme }}" target="_blank" class="btn btn-arrow-red-dark mt-30px">
				{{ 'see_fees' | transloco }}
			</a>
		</div>
	</div>
</div>
