import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-network',
  templateUrl: './network.component.html',
  styleUrls: ['./network.component.scss']
})
export class NetworkComponent {
  @Input() agence;
  @Input() agenceGroup;
  @Input() pathUrl;
  @Input() header: boolean;
  @Input() footer: boolean;
  @Input() profile: boolean;
}
