import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ConfigService } from './config.service';

@Injectable({
  providedIn: 'root'
})
export class LeadsService {
  private baseUrl: string;

  constructor(
    private http: HttpClient,
    configService: ConfigService
  ) {
    this.baseUrl = `${configService.config.url}/leads`;
  }

  newsletter(email: string): Observable<any> {
    return this.http.post(`${this.baseUrl}/newsletter`, { email });
  }

  contact(form_details: object): Observable<any> {
    return this.http.post(`${this.baseUrl}/contact`, form_details);
  }

  annonceContact(annonce_details: object): Observable<any> {
    return this.http.post(`${this.baseUrl}/annonce-contact`, annonce_details);
  }

  customMetier(form_details: object, metier: string): Observable<any> {
    return this.http.post(`${this.baseUrl}/${metier}`, form_details);
  }

  gestion(form_details: object): Observable<any> {
    return this.http.post(`${this.baseUrl}/gestion`, form_details);
  }

  syndic(form_details: object): Observable<any> {
    return this.http.post(`${this.baseUrl}/syndic`, form_details);
  }

  entrepriseCommerce(form_details: object): Observable<any> {
    return this.http.post(`${this.baseUrl}/entreprise-commerce`, form_details);
  }

  submitAgency(details: any): Observable<any> {
    return this.http.post(`${this.baseUrl}/estimation`, details);
  }
}
