import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input, OnDestroy, OnInit, Self } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { Subscription } from 'rxjs';

import { ToolsService } from '@/services/tools.service';

type InputType = 'text' | 'textarea' | 'password';

@Component({
  selector: 'app-form-input',
  templateUrl: './form-input.component.html',
  styleUrl: './form-input.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormInputComponent implements OnInit, OnDestroy, ControlValueAccessor {
  @Input() id!: string;
  @Input() type: InputType = 'text';
  @Input() label?: string;
  @Input() icon?: string;
  @Input() suffix?: string;
  @Input() options?: Record<string, string>;
  @Input() validationErrors = true;
  @Input() eye = false;
  @Input() passwordTips = false;

  public inputType?: InputType;
  public required = false;
  public disabled = false;
  public focus = false;
  public error?: any;

  private subscriptions = new Subscription();

  private onChange: any = () => {};
  private onTouched: any = () => {};
  private _value: string = '';

  /**
    The constructor uses Dependency Injection (DI) to access the NgControl directive and manually assign
    this component as the control's value accessor. This approach avoids the circular dependency issue
    caused by registering the component via NG_VALUE_ACCESSOR provider. By doing so, we directly set the
    component as the value accessor, allowing it to handle form control interactions (read and write value).
   */
  constructor(
    @Self() private ngControl: NgControl,
    private cd: ChangeDetectorRef,
    private el: ElementRef,
    private toolsService: ToolsService
  ) {
    ngControl.valueAccessor = this;
  }

  ngOnInit() {
    this.inputType = this.type;
    if (this.type === 'password') {
      this.eye = true;
      this.icon = 'icon-lock';
    }

    this.required = this.toolsService.hasRequiredValidator(this.ngControl.control);

    this.subscriptions.add(
      this.ngControl.statusChanges.subscribe((status) => {
        this.error = this.ngControl.dirty && (status === 'INVALID');
        this.cd.markForCheck();
      })
    );

    if (this.options) {
      Object.entries(this.options).forEach(([key, value]) => {
        this.el.nativeElement[key] = value;
      });
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  @Input()
  get value(): string {
    return this._value;
  }

  set value(val: string) {
    if (this._value !== val) {
      this._value = val;
      this.onChange(val);
      this.onTouched();
    }
  }

  // ControlValueAccessor methods

  writeValue(val: string): void {
    this._value = val;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  // Other methods

  togglePasswordVisibility() {
    this.inputType = (this.inputType === 'password') ? 'text' : 'password';
  }
}
