import { Pipe, PipeTransform } from '@angular/core';

import { SearchLocation } from '@/models';

@Pipe({
  name: 'convert'
})
export class Convert implements PipeTransform {
  // Convert array of object to string. Ex: result => Annecy-c29029,Lyon-c36744,Drôme-d31
  transform(input: SearchLocation[]): string {
    const arrayOfString = input.map((item) => {
      return `${item.name}${item.id_dep ? '-d' : '-c'}${item.id}`;
    });
    return arrayOfString.toString();
  }
}

@Pipe({
  name: 'addSpaceAfterComma'
})
export class AddSpaceAfterComa implements PipeTransform {
  // Add space after coma
  transform(input: string): string {
    return input.replace(/(\S)(,)(\S)/gm, '$1$2 $3');
  }
}
