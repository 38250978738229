import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable, shareReplay } from 'rxjs';

import { ConfigService } from './config.service';
import { ApiResponse, Guide, HomePageContent, OpinionGlobal } from '@/models';

@Injectable({
  providedIn: 'root'
})
export class ContentService {
  private contentUrl: string;
  private pagesUrl: string;

  private content$?: Observable<HomePageContent>;

  constructor(
    private http: HttpClient,
    configService: ConfigService
  ) {
    this.contentUrl = `${configService.config.url}/content`;
    this.pagesUrl = `${configService.config.url}/pages`;
  }

  getContentPages(name: string): Observable<any> {
    return this.http.get(`${this.contentUrl}/pages?identifier=${name}`);
  }

  getContent(): Observable<HomePageContent> {
    if (!this.content$) {
      this.content$ = this.http.get<any>(this.contentUrl).pipe(
        shareReplay(1)
      );
    }
    return this.content$;
  }

  getContentOpinion(): Observable<OpinionGlobal> {
    return this.getContent().pipe(
      map(({ era_global_avis: { global, moyenne }, era_global_opinion: { satisfaction, recommandation } }) => ({
        total: global,
        rate: moyenne,
        satisfaction,
        recommandation
      }))
    );
  }

  getAllPages(): Observable<any> {
    return this.http.get(this.pagesUrl);
  }

  getPages(slug: string): Observable<any> {
    return this.http.get(`${this.pagesUrl}/${slug}`);
  }

  getAllPagesSemanticSlave(): Observable<any> {
    return this.http.get(`${this.pagesUrl}/semantic`);
  }

  getPageSemanticById(id: number): Observable<ApiResponse<Guide>> {
    return this.http.get<ApiResponse<Guide>>(`${this.pagesUrl}/semantic/${id}`);
  }

  getPageSemanticBySlug(slug: string): Observable<ApiResponse<Guide>> {
    return this.http.get<ApiResponse<Guide>>(`${this.pagesUrl}/semantic/${slug}`);
  }
}
