import { Component, OnInit, ChangeDetectorRef, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';

import { AgencesService } from '@/services/agences.service';
import { VendreService } from '@/services/vendre.service';
import { OffreEmploiService } from '@/services/offre-emploi.service';
import { LeadsService } from '@/services/leads.service';
import { MetadataService } from '@/services/metadata.service';
import { TeamService } from '@/services/team.service';
import { GeographyService } from '@/services/geography.service';
import { GeolocService } from '@/services/geoloc.service';
import { Agence, AgenceGroupe } from '@/models';

type ContactType = {
  contact_type: string;
  source: string;
  value?: string;
  prestige: boolean;
};

const TAGS: ContactType[] = [
  { contact_type: 'CONTACT_TYPE.SELL_PROPERTY', source: 'eraAndAgence', prestige: true },
  { contact_type: 'CONTACT_TYPE.RENT_PROPERTY', source: 'eraAndAgence', prestige: true },
  { contact_type: 'CONTACT_TYPE.BUY_PROPERTY', source: 'eraAndAgence', prestige: true },
  { contact_type: 'CONTACT_TYPE.OPEN_AGENCY', source: 'era', prestige: false },
  { contact_type: 'CONTACT_TYPE.APPLICATION', source: 'eraAndAgence', prestige: false },
  { contact_type: 'CONTACT_TYPE.PARTNERSHIP', source: 'agence', prestige: false },
  { contact_type: 'CONTACT_TYPE.RENTAL_MANAGEMENT', value: 'gestion_locative', source: 'eraAndAgence', prestige: false },
  { contact_type: 'CONTACT_TYPE.COMMERCIAL_SPACE', value: 'entreprise_commerce', source: 'eraAndAgence', prestige: false },
  { contact_type: 'CONTACT_TYPE.SYNDIC', value: 'syndic_copropriete', source: 'eraAndAgence', prestige: false },
  { contact_type: 'CONTACT_TYPE.OTHER', source: 'agence', prestige: false }
] as const;

@Component({
  selector: 'app-contact-form',
  templateUrl: './contact-form.component.html',
  styleUrls: ['./contact-form.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ContactFormComponent implements OnInit {
  public langList: string[];
  public application = false;
  public searchByCity = true;
  public searchByDepartment = false;
  public long: number;
  public lat: number;
  public coordonate: string;
  public selectedIndex = 0;
  public coordonateResult: any;
  public closeAutocomplete = false;
  public showAutocompleteAddressOnClick = false;
  public addressAutocomplet: any[];
  public geoCoder: string;
  public address_name = '';
  public postcode: any;
  public city_name = '';
  public city_zip: any;
  public department: number;
  public showCoords = false;
  public showCoordsSelectedAgency = [];
  public openModal = false;
  public contact_type = 'CONTACT_TYPE.SELL_PROPERTY';
  public postes: object[];
  public messageSuccess = false;
  public showNearestAgencies = false;
  public type: string;
  public adressSubscribtion: any;
  public pathUrl: any;
  public submitted = false;
  public source: string;
  public negociator;
  public tags: ContactType[] = [];
  public agenceSelected?: Agence | AgenceGroupe;
  public agence?: Agence | AgenceGroupe;

  public defaultAgency = {
    id: 1,
    enseigne: 'ERA FRANCE IMMOBILIER (Siège social)',
    adresse: '18 rue mansart',
    codepostal: '78000',
    city_zip: '78000',
    ville: 'VERSAILLES',
    department: '78',
    telephone: '0139246900'
  };

  public applicationForm = new FormGroup({
    firstname: new FormControl('', Validators.required),
    lastname: new FormControl('', Validators.required),
    email: new FormControl('', [Validators.required, Validators.email]),
    phone: new FormControl('', Validators.required),
    message: new FormControl(''),
    job_type_id: new FormControl(''),
    origine_contact: new FormControl(''),
    contact_agreement: new FormControl(false, Validators.required),
    partner_agreement: new FormControl(false, Validators.required),
    contact_type: new FormControl('CONTACT_TYPE.SELL_PROPERTY', Validators.required),
    call_back: new FormControl(true, Validators.required)
  });

  public addressDetails = new FormGroup({
    address: new FormControl('', Validators.required)
  });

  get firstname() {
    return this.applicationForm.get('firstname') as FormControl;
  }

  get lastname() {
    return this.applicationForm.get('lastname') as FormControl;
  }

  get email() {
    return this.applicationForm.get('email') as FormControl;
  }

  get phone() {
    return this.applicationForm.get('phone') as FormControl;
  }

  get address() {
    return this.addressDetails.get('address') as FormControl;
  }

  constructor(
    private geolocService: GeolocService,
    private vendreService: VendreService,
    private agencesService: AgencesService,
    private offrePostes: OffreEmploiService,
    private route: ActivatedRoute,
    private leadsService: LeadsService,
    private location: Location,
    private cd: ChangeDetectorRef,
    private router: Router,
    private meta: MetadataService,
    private gtmService: GoogleTagManagerService,
    private geographyService: GeographyService,
    private teamService: TeamService
  ) {}

  ngOnInit(): void {
    this.pathUrl = this.router.url;

    this.route.data.subscribe(({ type, title, description, ogTitle, ogDescription, ogUrl }) => {
      // seo
      this.meta.setMetadata({
        title,
        description,
        ogTitle,
        ogDescription,
        ogUrl
      });

      this.type = type;

      switch (this.type) {
        case 'agence': {
          this.searchByCity = false;
          this.searchByDepartment = false;
          const id = +this.route.snapshot.paramMap.get('slug').split(/[-]+/).pop();
          if (this.pathUrl.includes('/groupes')) {
            this.agenceGroupesById(id);
          } else {
            this.agenceById(id);
          }
          break;
        }
        case 'negociateur': {
          const params = this.route.snapshot.paramMap.get('id_or_slug');
          if (params) {
            this.negociatorById(params);
          }
          break;
        }
        default:
          this.filterTags();
          break;
      }
      this.cd.markForCheck();
    });

    this.offrePostes.getPostes([1]).subscribe((postes) => {
      this.postes = postes.data;
      this.cd.markForCheck();
    });

    this.langList = ['fr'];

    this.coordonateResult = this.geolocService.nearestAgency;
    this.geoCoder = this.geolocService.geoCoder;

    this.address_name = this.vendreService.address_name;
    this.postcode = this.vendreService.postcode;
    this.city_name = this.vendreService.city_name;
    this.city_zip = this.vendreService.city_zip;

    this.address.setValue(this.geoCoder);

    this.functionaddressDetails();
  }

  backButton() {
    this.location.back();
  }

  private negociatorById(id_or_slug: string) {
    this.teamService.getNegociateurById(id_or_slug).subscribe((data) => {
      this.negociator = data.data;

      if (this.pathUrl.startsWith('/effectifs')) {
        this.gtmService.pushTag({
          'event': 'Pageview',
          'id-effectif': this.negociator.id,
          'nom-effectif': this.negociator.nom,
          'pagetype': 'Contact'
        });
      }
      this.agenceById(data.data.agence.id);
      this.cd.markForCheck();
    });
  }

  private agenceById(id: number) {
    this.agencesService.getAgenceById(id).subscribe(({ data }) => {
      this.agence = data;
      this.filterTags();

      if (this.pathUrl.startsWith('/agence')) {
        this.gtmService.pushTag({
          'event': 'Pageview',
          'id-agence': this.agence.id,
          'nom-agence': this.agence.enseigne,
          'pagetype': 'Contact'
        });
      }
      this.cd.markForCheck();
    });
  }

  private agenceGroupesById(id: number) {
    this.agencesService.getAgenceGroupesById(id).subscribe(({ data }) => {
      this.agence = data;
      this.filterTags();
    });
  }

  private filterTags() {
    this.tags = TAGS.filter((tag) => {
      return (
        (
          !this.agence && (
            tag.source === this.type ||
						tag.source === 'eraAndAgence' ||
						tag.value === 'gestion_locative' ||
						tag.value === 'entreprise_commerce' ||
						tag.value === 'syndic_copropriete'
          )
        ) ||
				(
				  this.agence && (
				    (tag.value !== 'gestion_locative' && !this.agence?.feature_display.gestion_locative) ||
						(tag.value !== 'gestion_locative' && this.agence?.feature_display.gestion_locative) ||
						(tag.value === 'gestion_locative' && this.agence?.feature_display.gestion_locative)) &&
						((tag.value !== 'entreprise_commerce' && !this.agence?.feature_display.entreprise_commerce) ||
						(tag.value !== 'entreprise_commerce' && this.agence?.feature_display.entreprise_commerce) ||
						(tag.value === 'entreprise_commerce' && this.agence?.feature_display.entreprise_commerce)) &&
						((tag.value !== 'syndic_copropriete' && !this.agence?.feature_display.syndic_copropriete) ||
						(tag.value !== 'syndic_copropriete' && this.agence?.feature_display.syndic_copropriete) ||
						(tag.value === 'syndic_copropriete' && this.agence?.feature_display.syndic_copropriete)
						) && (tag.source === this.type || tag.source === 'eraAndAgence')
				)
      );
    });
  }

  closeAutoComplete() {
    this.closeAutocomplete = false;
  }

  handleAutocompleteSelect(address): void {
    this.addressDetails.controls['address'].setValue(address.properties.label);
    this.getAddressToInput(
      address.properties.name,
      address.properties.postcode,
      address.properties.city,
      address.properties.citycode
    );
    this.getCoordinate(address.geometry);
    this.getAddressChange(address.properties.label);
  }

  handleDepartementAutocomplete(address): void {
    this.addressDetails.controls['address'].setValue(address.nom);
    this.getAddressToInputDepartment(address.nom, address.code);
    this.getAddressChange(address.nom);
  }

  addressRebounceValue(value) {
    if (!this.showAutocompleteAddressOnClick) {
      this.addressAutocomplete(value);
    } else {
      this.showAutocompleteAddressOnClick = false;
    }
  }

  toggleTags(interest: string) {
    this.contact_type = interest;
    this.setjobTypeControlValidators(this.contact_type);
    if (interest === 'CONTACT_TYPE.APPLICATION') {
      this.application = true;
    } else {
      this.application = false;
    }
    if (
      interest === 'CONTACT_TYPE.SELL_PROPERTY' ||
			interest === 'CONTACT_TYPE.RENT_PROPERTY' ||
			interest === 'CONTACT_TYPE.RENTAL_MANAGEMENT' ||
			interest === 'CONTACT_TYPE.COMMERCIAL_SPACE' ||
			interest === 'CONTACT_TYPE.SYNDIC'
    ) {
      this.searchByCity = true;
    } else {
      this.searchByCity = false;
    }

    if (interest === 'CONTACT_TYPE.OPEN_AGENCY') {
      this.searchByDepartment = true;
    }

    if (
      interest === 'CONTACT_TYPE.OTHER' &&
			(!this.pathUrl.startsWith('/agence') || !this.pathUrl.includes('groupes'))
    ) {
      this.selectedIndex = 1;
      this.address_name = this.defaultAgency.adresse;
      this.postcode = this.defaultAgency.codepostal;
      this.city_name = this.defaultAgency.ville;
      this.city_zip = this.defaultAgency.city_zip;
    }
  }

  private functionaddressDetails() {
    this.address.valueChanges.subscribe((res) => {
      this.getAddressChange(res);
      this.cd.markForCheck();
    });
  }

  getAddressToInputDepartment(department, code) {
    this.address_name = department;
    this.department = code;
    this.closeAutocomplete = false;
  }

  getAddressToInput(address, postcode, city_name, city_zip) {
    this.address_name = address;
    this.postcode = postcode;
    this.city_name = city_name;
    this.city_zip = city_zip;
    this.closeAutocomplete = false;
  }

  addressAutocomplete(query: string) {
    // Search for either ville or adresse depending on the type of contact
    if (query === '') {
      return;
    }
    if (this.adressSubscribtion) {
      // cancel previous request if needed
      this.adressSubscribtion.unsubscribe();
    }
    if (this.searchByCity) {
      this.adressSubscribtion = this.geographyService.getAddressVilleAutocomplete(query).subscribe((data) => {
        this.addressAutocomplet = data.features;
        this.closeAutocomplete = true;
        this.cd.markForCheck();
      });
    } else if (this.searchByDepartment) {
      this.adressSubscribtion = this.geographyService.getAutoCompleteList(query).subscribe((data) => {
        this.addressAutocomplet = data.departements;
        this.closeAutocomplete = true;
        this.cd.markForCheck();
      });
    } else {
      this.adressSubscribtion = this.geographyService.getAddressAutocomplete(query).subscribe((data) => {
        this.addressAutocomplet = data.features;
        this.closeAutocomplete = true;
        this.cd.markForCheck();
      });
    }
  }

  openCoords(index) {
    this.showCoordsSelectedAgency[index] = true;
  }

  setIndex(index: number) {
    this.selectedIndex = index;
    this.agencesService.getAgenceById(this.selectedIndex).subscribe((data) => {
      this.agenceSelected = data.data;
      this.cd.markForCheck();
      return this.agenceSelected;
    });
  }

  getAddressChange(event) {
    this.geoCoder = event;
  }

  getCoordinate(event) {
    this.lat = event.coordinates[0];
    this.long = event.coordinates[1];
    this.coordonate = event.coordinates[1] + '%2C' + event.coordinates[0];
    this.showNearestAgencies = false;
    this.agencesService.getNearestAgency(this.coordonate, 1).subscribe((data) => {
      this.coordonateResult = data.data;

      this.showNearestAgencies = true;
      this.cd.markForCheck();
      return this.coordonateResult;
    });
  }

  onlyNumberKey(evt): boolean {
    // Only ASCII character in that range allowed
    const ASCIICode = evt.which ? evt.which : evt.keyCode;
    return (ASCIICode > 31 && (ASCIICode < 48 || ASCIICode > 57)) ? false : true;
  }

  addSpacePhone(agenceOrNegociateur): string | undefined {
    if (agenceOrNegociateur) {
      if (agenceOrNegociateur.telephone) {
        return agenceOrNegociateur.telephone.replace(/\d{2}(?=.)/g, '$& ').replaceAll('.', '');
      }
      return 'Non renseigné';
    }
    return undefined;
  }

  getSelectedAgency() {
    if (this.agence && (this.agence as AgenceGroupe).era_agence_principale_id) {
      return (this.agence as AgenceGroupe).era_agence_principale_id;
    }
    if (this.agenceSelected && this.agenceSelected?.era_id) {
      return this.agenceSelected?.era_id;
    }
    return this.agence?.era_id;
  }

  getDepartment() {
    if (this.contact_type === 'CONTACT_TYPE.OTHER') {
      return this.defaultAgency.department;
    }
    if (this.searchByDepartment) {
      return this.department;
    }
    if (this.agenceSelected) {
      return this.agenceSelected.geo_departement.code;
    }
    return this.agence.geo_departement.code;
  }

  private setjobTypeControlValidators(contactType: string): void {
    const jobTypeControl = this.applicationForm.get('job_type_id');

    if (contactType === 'CONTACT_TYPE.APPLICATION') {
      jobTypeControl.setValidators([Validators.required]);
    } else {
      jobTypeControl.clearValidators();
    }
    jobTypeControl.updateValueAndValidity();
  }

  sendApplicationForm() {
    this.submitted = true;

    if (this.applicationForm.valid) {
      // Submit contact form
      if (this.pathUrl.startsWith('/agence') && !this.pathUrl.includes('groupes')) {
        this.source = 'SOURCE.SITE_AGENCE';
      } else if (this.pathUrl.includes('groupes') && !this.pathUrl.startsWith('/agence')) {
        this.source = 'SOURCE.SITE_GROUPE';
      } else if (this.pathUrl.startsWith('/effectifs')) {
        this.source = 'SOURCE.SITE_NEGO';
      } else {
        this.source = 'SOURCE.SITE_NATIONAL';
      }

      const allDetails: any = {
        ...this.applicationForm.value,
        agence_id:
          this.searchByDepartment ||
          (this.contact_type === 'CONTACT_TYPE.OTHER' && this.source === 'SOURCE.SITE_NATIONAL')
            ? 1
            : this.getSelectedAgency(),
        api_source: this.source,
        phone: this.phone.value.e164Number,
        address: this.searchByDepartment ? '' : this.address_name,
        zip_code: this.postcode,
        city: this.city_name,
        contact_type: this.contact_type,
        department: this.getDepartment()
      };

      if (this.source === 'SOURCE.SITE_NEGO') {
        allDetails.effectif_id = this.negociator.era_id;
      }

      this.leadsService.contact(allDetails).subscribe((success) => {
        if (success) {
          this.messageSuccess = true;
          this.cd.markForCheck();
          setTimeout(() => {
            this.location.back();
          }, 2000);
        }
      });
    }
  }
}
