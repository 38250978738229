import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostBinding, Input, OnChanges, SimpleChanges } from '@angular/core';

import { ButtonAspect, ButtonSize } from '../button/button.component';

@Component({
  selector: 'app-button-telephone',
  templateUrl: './button-telephone.component.html',
  styleUrl: './button-telephone.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ButtonTelephoneComponent implements OnChanges {
  @Input() telephone?: string;
  @Input() aspect: ButtonAspect = 'arrow-red-dark';
  @Input() size: ButtonSize = 'fit';
  @Input() extraClasses = '';

  @HostBinding('style.width') width: string;

  public showTel = false;
  public phone: string;

  constructor(private cd: ChangeDetectorRef) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { telephone, size } = changes;

    if (telephone) {
      this.phone = this.normalyzePhoneString(this.telephone);
      this.showTel = false;
    }

    if (size && (size.currentValue !== size.previousValue)) {
      this.width = (this.size === 'large') ? '100%' : 'fit-content';
    }
  }

  private normalyzePhoneString(phone: string): string {
    return phone ? phone.replace(/\d{2}(?=.)/g, '$& ').replaceAll('.', '') : undefined;
  }

  show() {
    this.showTel = !this.showTel;
    this.cd.detectChanges();
  }
}
