import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { MainRoutes } from '@/constants';

@Component({
  selector: 'app-opinion-title',
  templateUrl: './opinion-title.component.html',
  styleUrl: './opinion-title.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OpinionTitleComponent {
  @Input() avisLink = MainRoutes.Avis;
  @Input() total = 0;
  @Input() rate = 0;
}
