@if (total > 0) {
  <div class="avis">
    <app-opinion-title [rate]="rate" [total]="total" [avisLink]="avisLink" />

    <div class="flex-cards">
      @for (avis of avisList | slice: 0:3; track avis) {
        <app-statement-card [groupeAgence]="groupeAgence" [agence]="agence" [team]="team" [avis]="avis" />
      }
    </div>
  </div>
}
