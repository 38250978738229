<div class="input-switch">
  @if (label) {
    <p class="label">
      {{ translate ? (label | transloco) : label }}
    </p>
  }
  <div class="input-switch-items">
    <p class="text left">{{ 'no' | transloco }}</p>
    <div>
      <label class="switch-label">
        <input type="checkbox" [(ngModel)]="value" />
        <span class="slider round"></span>
      </label>
    </div>
    <p class="text right">{{ 'yes' | transloco }}</p>
  </div>
</div>
