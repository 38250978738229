import { Meta, Title } from '@angular/platform-browser';
import { TranslocoService } from '@jsverse/transloco';
import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';

import { ConfigService } from './config.service';

const DEFAULT_TITLE = 'ERA France';
const DEFAULT_IMAGE = 'https://www.erafrance.com/assets/imgs/era-logo-national.png';
const DEFAULT_ROBOTS = 'follow,index';

export type Metadata = {
  title?: string;
  description?: string;
  url?: string;
  robots?: string;
  ogTitle?: string;
  ogUrl?: string;
  ogImage?: string;
  ogDescription?: string;
  singleArticle?: boolean;
  params?: Record<string, string | number>;
};

@Injectable({
  providedIn: 'root'
})
export class MetadataService {
  private fbDevAppId: string;

  constructor(
    private meta: Meta,
    private title: Title,
    private translate: TranslocoService,
    @Inject(DOCUMENT) private document: any,
    configService: ConfigService
  ) {
    this.fbDevAppId = configService.config.facebook_developper_app_id;
  }

  setMetadata(data: Metadata, translate = true): void {
    const params = data.params ? { ...data.params } : {};

    this.meta.updateTag({ property: 'fb:app_id', content: this.fbDevAppId });
    this.meta.updateTag({ property: 'og:type', content: 'website' });

    if (data.title) {
      this.title.setTitle(translate ? this.translate.translate(data.title, params) : data.title);
    } else {
      this.title.setTitle(DEFAULT_TITLE);
    }

    if (data.description) {
      this.meta.updateTag({
        name: 'description',
        content: translate ? this.translate.translate(data.description, params) : data.description
      });
    } else {
      this.meta.removeTag('name=\'description\'');
    }

    let url = data.ogUrl ?? data.url;
    if (url) {
      url = translate ? this.translate.translate(url, params) : url;
      this.meta.updateTag({ property: 'og:url', content: url });
      this.createLinkForCanonicalURL(data.singleArticle, url);
    }

    if (data.ogTitle) {
      this.meta.updateTag({
        property: 'og:title',
        content: translate ? this.translate.translate(data.ogTitle, params) : data.ogTitle
      });
    } else {
      this.meta.removeTag('property=\'og:title\'');
    }

    if (data.ogDescription) {
      this.meta.updateTag({
        property: 'og:description',
        content: translate ? this.translate.translate(data.ogDescription, params) : data.ogDescription
      });
    } else {
      this.meta.removeTag('property=\'og:description\'');
    }

    this.meta.updateTag({ property: 'og:image', content: data.ogImage ?? DEFAULT_IMAGE });
    this.meta.updateTag({ name: 'robots', content: data.robots ?? DEFAULT_ROBOTS });
  }

  /**
   * Remove canonical links from current HTML document
   */
  private removeAllCanonicalLinks() {
    const canonicalElement = this.document.querySelectorAll('[rel="canonical"]');

    for (const el of canonicalElement) {
      el.parentNode.removeChild(el);
    }
  }

  /**
   * Create a canonical link element into the HTML document
   * @param singleArticle
   * @param url The app url
   */
  private createLinkForCanonicalURL(singleArticle: boolean, url: string): void {
    this.removeAllCanonicalLinks();

    const link: HTMLLinkElement = this.document.createElement('link');
    link.setAttribute('rel', 'canonical');
    this.document.head.appendChild(link);

    if (singleArticle) {
      link.setAttribute('href', url);
    } else {
      // SSR does not know the protocol of the server, force HTTPS
      link.setAttribute('href', this.document.URL.replace('http://', 'https://'));
    }
  }
}
