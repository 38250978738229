import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sortBy'
})
export class SortBy implements PipeTransform {
  transform(array: any[]): any {
    return array.sort(function(a, b) {
      return Date.parse(b.date) - Date.parse(a.date);
    });
  }
}
