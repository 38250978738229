<div class="emploi">
	<div class="breadcrumb w-full">
		<div class="breadcrumb-content">
			<div class="breadcrumb-back" (click)="back()">
				<div class="breadcrumb-back-arrow"><span class="icon-arrow-left"></span></div>
				<p class="display-text-16px">{{ 'previous' | transloco }}</p>
			</div>
			<div class="breadcrumb-links">
				@if (agence?.id) {
					<a [routerLink]="['/' + agence.slug]">{{ agence?.enseigne }}</a>
				} @else {
					<span [routerLink]="['/']">{{ 'homepage' | transloco }}</span>
				}
				<span class="icon-chevron-right"></span>
				<span>{{ 'job offers' | transloco }}</span>
			</div>
		</div>
	</div>

	<div class="main-containers">
		@if (totalOffres !== undefined) {
			<div class="w-full flex items-center justify-between mb-41px">
				<h2 class="display-h2">
					@if (totalOffres === 0) {
						{{ 'no job offer' | transloco }}
					} @else if (totalOffres === 1) {
						{{ 'our job offer' | transloco }}
					} @else {
						{{ 'job x offers' | transloco: { totalOffres: totalOffres } }}
					}
				</h2>
			</div>
		}

		<div class="offres">
			@for (poste of postes; track poste.titre; let isFirst = $first) {
				@if (isFirst) {
					<div class="event">
						<div class="our-events box">
							<img src="/assets/imgs/our-events.png" alt="" />
							<div>
								<h3 class="display-h3">{{ 'recruit events' | transloco }}</h3>
								<p class="display-text-16px lh-32px">
									{{ 'job dating workshop' | transloco }}
								</p>
								<app-button text="see events" [link]="eventsLink" />
							</div>
						</div>
					</div>
				}

				<app-poste-card
					class="mt-20px ml-20px"
					[poste]="poste"
					[emploi]="agence?.id ? poste.emploiId : undefined"
					[siege]="siege"
				/>
			}
		</div>
	</div>
</div>
