<div class="contact-form" [ngClass]="type === 'agence' || type === 'negociateur' ? 'agenceheader' : ''">
	<div class="breadcrumb desktop">
		<div class="breadcrumb-content">
			<div class="breadcrumb-back" (click)="backButton()">
				<div class="breadcrumb-back-arrow"><span class="icon-arrow-left"></span></div>
				<p class="display-text-16px">{{ 'previous' | transloco }}</p>
			</div>
		</div>
	</div>

	<div class="wrapper">
		<div class="wrapper-contact">
			<div class="wrapper-contact-container flex flex-col" [ngClass]="type === 'agence' || type === 'negociateur' ? 'agence' : ''">
				<div *ngIf="negociator" class="negociator mb-40px">
					<div class="flex items-center">
						<div class="image-container mr-20px">
							<img [src]="negociator?.photo" alt="" />
						</div>
						<div>
							<p class="display-text-18px">{{negociator?.nom}} {{negociator?.prenom}}</p>
							<p class="display-text-14px">{{agence?.enseigne}}</p>
						</div>
					</div>
					<div class="contactContent display-text-16px mt-10px" *ngIf="type === 'negociateur'">
						<div (click)="showCoords = true" class="coordBtn contact">
							<div class="btn-phone-blue-border" *ngIf="!showCoords">
								<p class="text-transition">{{ 'tel' | transloco }}</p>
								<span class="icon-call phone-transition"></span>
							</div>
							<div class="flex items-center" *ngIf="showCoords">
								<a href="tel:{{ addSpacePhone(this.negociator) }}" *ngIf="negociator">
									{{ addSpacePhone(this.negociator) }}
								</a>
								<span class="icon-call blue-icon ml-12px"></span>
							</div>
						</div>
					</div>
				</div>

				<app-presentation-agency-card
					*ngIf="type === 'agence'"
					[type]="'agence'"
					[agence]="agence"
				/>

				<app-presentation-agency-card
					*ngIf="type === 'negociateur'"
					[type]="'negociateur'"
					[agence]="agence"
				/>

				<div *ngIf="type === 'negociateur'" class="negociator_buttons flex justify-between mt-30px">
					<button class="btn btn-arrow-white" [routerLink]="['/' + agence?.slug]">
						<p class="text-transition">{{ 'see agency' | transloco }}</p>
						<span class="icon-arrow-right fs-24px opacity-transition"></span>
					</button>
					<button class="btn btn-arrow-red-dark" [routerLink]="['/effectifs/' + (negociator?.website_slug ? negociator?.website_slug : negociator?.id)]">
						<p class="text-transition">{{ 'see property details' | transloco }}</p>
						<span class="icon-arrow-right fs-24px opacity-transition"></span>
					</button>
				</div>

				<div class="contactContent display-text-16px mt-10px" *ngIf="type === 'agence'">
					<div (click)="showCoords = true" class="coordBtn contact">
						<div class="btn-phone-blue-border" *ngIf="!showCoords">
							<p class="text-transition">{{ 'tel' | transloco }}</p>
							<span class="icon-call phone-transition"></span>
						</div>
						<div class="flex items-center" *ngIf="showCoords">
							<a href="tel:{{ addSpacePhone(this.agence) }}" *ngIf="agence">
								{{ addSpacePhone(this.agence) }}
							</a>
							<span class="icon-call blue-icon ml-12px"></span>
						</div>
					</div>
				</div>
			</div>

			<div class="wrapper-contact-container-form">
				<form class="form-contact" [formGroup]="applicationForm" (submit)="sendApplicationForm()">
					<h1 *ngIf="type !== 'agence'" class="display-h2 mb-40px">{{ 'contact_us' | transloco }}</h1>
					<!-- SEO purpose -->
					<h2 *ngIf="type === 'agence'" class="display-h2 mb-40px">{{ 'contact_us' | transloco }}</h2>
					<!-- SEO purpose -->
					<h2 *ngIf="type !== 'agence'" class="display-text-24px font-medium mb-20px">
						{{ 'interested_in' | transloco }}
					</h2>
					<!-- SEO purpose -->
					<h3 *ngIf="type === 'agence'" class="display-text-24px font-medium mb-20px">
						{{ 'interested_in' | transloco }}
					</h3>

					<!-- SEO purpose -->
					<app-tags-container [tags]="tags" [template]="tag" class="mb-30px" />
					<ng-template #tag let-tag let-active="active" let-inactive="inactive">
						<tag (click)="toggleTags(tag.contact_type)" [ngClass]="[contact_type === tag.contact_type ? active : inactive]">
							{{ tag.contact_type | transloco }}
						</tag>
					</ng-template>

					<div class="form-inputs mt-56px">
						<div class="wrapper-contact-container-form-fullname flex">
							<div class="input name mr-30px">
								<div class="input-label" [ngClass]="[firstname.touched && firstname.errors ? 'error' : '']">
									<input
										[ngClass]="!firstname.errors?.['required'] ? 'transformed' : ''"
										formControlName="firstname"
										id="firstname"
										type="text"
									/>
									<label for="firstname">
										<span class="icon-user not-color"></span>{{ 'firstname' | transloco }}<span>*</span>
									</label>
								</div>
								<div class="wrapper-contact-container-form-error" *ngIf="firstname.touched && firstname.errors">
									<p *ngIf="firstname.errors?.['required']">{{ "field_required" | transloco }}</p>
								</div>
							</div>

							<div class="input name">
								<div class="input-label" [ngClass]="[lastname.touched && lastname.errors ? 'error' : '']">
									<input
										[ngClass]="!lastname.errors?.['required'] ? 'transformed' : ''"
										formControlName="lastname"
										id="lastname"
										type="text"
									/>
									<label for="lastname">
										<span class="icon-user not-color"></span>{{ 'lastname' | transloco }}<span>*</span>
									</label>
								</div>
								<div class="wrapper-contact-container-form-error" *ngIf="lastname.touched && lastname.errors">
									<p *ngIf="lastname.errors?.['required']">{{ "field_required" | transloco }}</p>
								</div>
							</div>
						</div>

						<div class="input">
							<div class="input-label" [ngClass]="[email.touched && email.errors ? 'error' : '']">
								<input
									[ngClass]="!email.errors ? 'transformed' : ''"
									formControlName="email"
									type="email"
									id="email"
								/>
								<label for="email">
									<span class="icon-email not-color"></span>{{ 'email' | transloco }}<span>*</span>
								</label>
							</div>
							<div class="wrapper-contact-container-form-error" *ngIf="email.touched && email.errors">
								<p *ngIf="email.errors?.['required']">{{ "field_required" | transloco }}</p>
								<p *ngIf="email.errors?.['email']">{{ "field_incorrect" | transloco }}</p>
							</div>
						</div>

						<div class="input phone">
							<div class="input-label" [ngClass]="[phone.touched && phone.errors ? 'error' : '']">
								<ngx-intl-tel-input
									[ngClass]="!phone.errors?.['required'] ? 'transformed' : ''"
									[cssClass]="'phone'"
									[preferredCountries]="langList"
									[separateDialCode]="true"
									[enablePlaceholder]="true"
									formControlName="phone"
									id="phone"
								/>
								<label class="portable" for="phone">
									{{ 'portable' | transloco }}<span>*</span>
								</label>
							</div>
							<div class="wrapper-contact-container-form-error" *ngIf="phone.touched && phone.errors">
								<p *ngIf="phone.errors?.['required']">{{ "field_required" | transloco }}</p>
								<p *ngIf="phone.errors?.validatePhoneNumber?.valid === false">
									{{ "field_incorrect" | transloco }}
								</p>
							</div>
						</div>

						<div *ngIf="application" class="input">
							<select class="center-select" id="job_type_id" name="job_type_id" formControlName="job_type_id">
								<option *ngFor="let poste of this.postes" value="{{ poste.era_id }}">
									{{ poste.titre | titlecase }}
								</option>
							</select>
							<div class="input-label transformed">
								<label class="select-job-type" for="job_type_id">
									<span class="icon-email not-color"></span>{{ "job_type" | transloco }}<span>*</span>
								</label>
							</div>
						</div>

						<div class="input">
							<textarea
								formControlName="message"
								placeholder="{{ 'message' | transloco }}"
								class="message"
								id="message"
								cols="30"
								rows="10"
							></textarea>
						</div>

						<div
							class="input"
							[formGroup]="addressDetails"
							[hidden]="contact_type === 'CONTACT_TYPE.OTHER' && type === 'era'"
						>
							<div class="input-label" [ngClass]="[address.touched && address.errors ? 'error' : '']">
								<input
									[ngClass]="!address.errors?.['required'] ? 'transformed' : ''"
									formControlName="address"
									class="geocoder"
									id="address"
									type="text"
									(input)="addressRebounceValue($event.target.value)"
								/>
								<label for="address" *ngIf="
									((!searchByCity && !searchByDepartment) || (searchByCity && type === 'agence')) &&
									contact_type !== 'CONTACT_TYPE.RENTAL_MANAGEMENT' &&
									contact_type !== 'CONTACT_TYPE.COMMERCIAL_SPACE' &&
									contact_type !== 'CONTACT_TYPE.SYNDIC'"
								>
									<span class="icon-location not-color"></span>{{ 'address' | transloco }}<span>*</span>
								</label>
								<label for="address" *ngIf="
									(searchByCity && !searchByDepartment && type !== 'agence') ||
									contact_type === 'CONTACT_TYPE.RENTAL_MANAGEMENT' ||
									contact_type === 'CONTACT_TYPE.COMMERCIAL_SPACE' ||
									contact_type === 'CONTACT_TYPE.SYNDIC'"
								>
									<span class="icon-location not-color"></span>{{ 'city' | transloco }}<span>*</span>
								</label>
								<label *ngIf="searchByDepartment && !searchByCity && type !== 'agence'" for="address">
									<span class="icon-location not-color"></span>{{ 'department' | transloco }}<span>*</span>
								</label>
							</div>
							<div class="wrapper-contact-container-form-error" *ngIf="address.touched && address.errors">
								<p *ngIf="
									address.errors?.['required'] &&
									(!searchByCity || (searchByCity && type === 'agence')) &&
									!searchByDepartment"
								>
									{{ 'address_required' | transloco }}
								</p>
								<p *ngIf="
									address.errors?.['required'] &&
									searchByCity &&
									!searchByDepartment &&
									type !== 'agence'"
								>
									{{ 'city_required' | transloco }}
								</p>
								<p *ngIf="
									address.errors?.['required'] &&
									searchByDepartment &&
									!searchByCity &&
									type !== 'agence'"
								>
									{{ 'department_required' | transloco }}
								</p>
							</div>

							<div class="input-geoloc" (clickOutside)="closeAutoComplete()" *ngIf="closeAutocomplete">
								<ul *ngIf="!searchByDepartment">
									<li *ngFor="let address of addressAutocomplet" (click)="handleAutocompleteSelect(address)">
										{{ address.properties.label }}
									</li>
								</ul>
								<ul *ngIf="searchByDepartment">
									<li *ngFor="let address of addressAutocomplet" (click)="handleDepartementAutocomplete(address)">
										{{ address.nom }}
									</li>
								</ul>
							</div>
						</div>

						<div *ngIf="!pathUrl.startsWith('/effectifs')" class="input-toggle">
							<h3 class="display-text-20px">
								{{ type === "agence" ? ('application_form_call_back' | transloco) : ('call_back' | transloco) }}
							</h3>
							<div class="input-toggle-items">
								<p class="display-text-16px">{{ 'no' | transloco }}</p>
								<div>
									<label class="toggle">
										<input type="checkbox" formControlName="call_back" />
										<span class="slider round"></span>
									</label>
								</div>
								<p class="display-text-16px">{{ 'yes' | transloco }}</p>
							</div>
						</div>

						<div
							*ngIf="contact_type === 'CONTACT_TYPE.OTHER' && type === 'era'"
							class="wrapper-contact-container-form-nearestAgency"
						>
							<div
								class="wrapper-contact-container-form-nearestAgency-container"
								[class.active]="selectedIndex === defaultAgency.id"
							>
								<p class="display-text-18px">{{ defaultAgency.enseigne }}</p>
								<div class="address-container">
									<p class="display-text-16px">{{ defaultAgency.adresse }}</p>
									<p class="display-text-16px">{{ defaultAgency.codepostal }} {{ defaultAgency.ville }}</p>
								</div>

								<div class="button mt-auto">
									<div (click)="openCoords(i)" id="{{ i }}" class="coordBtn mb-20px">
										<div class="btn-phone-blue-border" *ngIf="!showCoordsSelectedAgency[i]">
											<p class="text-transition">{{ 'tel' | transloco }}</p>
											<span class="icon-call phone-transition"></span>
										</div>
										<div class="flex items-center btn-phone-blue-border" *ngIf="showCoordsSelectedAgency[i]">
											<a href="tel:{{ defaultAgency.telephone }}">{{ defaultAgency.telephone }}</a>
											<span class="icon-call blue-icon ml-12px"></span>
										</div>
									</div>
								</div>

								<div class="button" [ngClass]="{ selected: selectedIndex === defaultAgency.id }">
									<div class="flex items-center btn-phone-blue-border" (click)="setIndex(defaultAgency.id)">
										<p>{{ 'SELL.SELECT' | transloco }}</p>
									</div>
								</div>
							</div>
						</div>

						<div
							*ngIf="showNearestAgencies && coordonateResult.length && !pathUrl.startsWith('/effectifs')"
							class="wrapper-contact-container-form-nearestAgency"
						>
							<app-carousel>
								@for (agence of coordonateResult | slice: 0:3; track agence.id; let i = $index) {
									<app-carouse-slide>
										<div class="wrapper-contact-container-form-nearestAgency-container" [class.active]="selectedIndex === agence.id">
											<p class="display-text-18px">{{ agence?.enseigne }}</p>
											<div class="address-container">
												<p class="display-text-16px">{{ agence?.adresse }}</p>
												<p class="display-text-16px">{{ agence?.codepostal }} {{ agence?.ville }}</p>
											</div>

											<div class="button mt-auto">
												<div (click)="openCoords(i)" id="{{ i }}" class="coordBtn mb-20px">
													<div class="btn-phone-blue-border" *ngIf="!showCoordsSelectedAgency[i]">
														<p class="text-transition">{{ 'tel' | transloco }}</p>
														<span class="icon-call phone-transition"></span>
													</div>
													<div class="flex items-center btn-phone-blue-border" *ngIf="showCoordsSelectedAgency[i]">
														<a href="tel:{{ agence?.telephone }}">{{ agence?.telephone }}</a>
														<span class="icon-call blue-icon ml-12px"></span>
													</div>
												</div>
											</div>

											<div class="button" [ngClass]="{ selected: selectedIndex === agence.id }">
												<div class="flex items-center btn-phone-blue-border" (click)="setIndex(agence?.id)">
													<p>{{ 'SELL.SELECT' | transloco }}</p>
												</div>
											</div>
										</div>
									</app-carouse-slide>
								}
							</app-carousel>
						</div>

						<div class="input">
							<select
								class="center-select"
								id="origine_contact"
								name="origine_contact"
								formControlName="origine_contact"
							>
								<option value="ORIGINE.BOUCHE" selected>{{ 'word_of_mouth' | transloco }}</option>
								<option value="ORIGINE.PUB">{{ 'publicity' | transloco }}</option>
								<option value="ORIGINE.INTERNET">{{ 'internet' | transloco }}</option>
								<option value="ORIGINE.LOCAL">{{ 'local_agency' | transloco }}</option>
								<option value="ORIGINE.CLIENT">{{ 'by_a_customer' | transloco }}</option>
								<option value="ORIGINE.AUTRE">{{ 'other' | transloco }}</option>
							</select>
							<div class="input-label transformed">
								<label class="select-origine_contact" for="origine_contact"
									><span class="icon-email not-color"></span>{{ 'origine_contact' | transloco }}</label
								>
							</div>
						</div>

						<div class="checkbox">
							<label class="flex items-center">
								<input
									type="checkbox"
									id="contact_agreement"
									name="contact_agreement"
									formControlName="contact_agreement"
								/>
								<p class="checkmark ml-10px">{{ 'authorization' | transloco }}</p>
							</label>
						</div>
						<div class="checkbox mt-20px">
							<label class="flex items-center">
								<input
									type="checkbox"
									id="partner_agreement"
									name="partner_agreement"
									formControlName="partner_agreement"
								/>
								<p class="checkmark ml-10px">{{ 'authorization_data' | transloco }}</p>
							</label>
						</div>

						<div class="wrapper-button mt-80px">
							<button
								[ngClass]="!applicationForm.valid ? 'disabled': ''"
								type="submit"
								class="btn btn-red w-full wrapper-button-submit"
							>
								{{ 'application_form_send' | transloco }}
							</button>
						</div>

						<div *ngIf="messageSuccess" class="mt-20px">
							{{ "form_sent_success" | transloco }}
						</div>

						<div class="block-contact-agency-asterisque">
							<p>{{ 'data' | transloco }} {{ 'data_link' | transloco }}</p>
						</div>
					</div>
				</form>
			</div>
		</div>
	</div>
</div>
