@if (name) {
  <p class="title">{{ name | transloco }}</p>
}
<div class="tags">
  @for (tag of tags; track $index) {
    <ng-container
      [ngTemplateOutlet]="template"
      [ngTemplateOutletContext]="{ $implicit: tag, active: 'active', inactive: 'inactive' }"
    />
  }
</div>
