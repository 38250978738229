import { Injectable } from '@angular/core';
import { BehaviorSubject, filter, forkJoin, from, map, Observable, switchMap } from 'rxjs';

import { ANNONCE_STATE } from '@/constants';
import { Annonce, Coordinates, GeoVille, SearchLocation } from '@/models';
import { GeographyService } from './geography.service';
import { AnnoncesService } from './annonces.service';

export type GeoLoc = {
  coord: string;
  lng?: number;
  lat?: number;
};

@Injectable({
  providedIn: 'root'
})
export class GeolocService {
  private _nearestAgency = new BehaviorSubject<GeoLoc>({ coord: '' });
  private _geolocVille$ = new BehaviorSubject(null);

  private _showAutocompleteAddressOnClick = false;
  private _geoCoder = '';
  private _eraId?: number;

  constructor(
    private geographyService: GeographyService,
    private annoncesService: AnnoncesService
  ) {}

  get currentGeolocVille$() {
    return this._geolocVille$.asObservable();
  }

  get showAutocompleteAddressOnClick(): boolean {
    return this._showAutocompleteAddressOnClick;
  }

  get geoCoder(): string {
    return this._geoCoder;
  }

  get eraId(): number | undefined {
    return this._eraId;
  }

  get nearestAgency$(): Observable<GeoLoc> {
    return this._nearestAgency.asObservable();
  }

  get nearestAgency(): GeoLoc {
    return this._nearestAgency.value;
  }

  updateNearestAgency(coord: string, lng?: number, lat?: number): void {
    this._nearestAgency.next({ coord, lng, lat });
  }

  updateGeoCoder(value: string): void {
    this._geoCoder = value;
  }

  searchAddressAutocomplete(on = true): void {
    this._showAutocompleteAddressOnClick = on;
  }

  updateGeolocVille(data: SearchLocation): void {
    this._geolocVille$.next(data);
  }

  getPosition(): Observable<Coordinates> {
    if (!navigator.geolocation) {
      throw new Error('Geolocation not supported');
    }

    return from(new Promise<Coordinates>((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(
        (resp) => resolve({ lng: resp.coords.longitude, lat: resp.coords.latitude }),
        (err) => reject(err)
      );
    }));
  }

  getAnnoncesAndSearchLocationFromPosition(): Observable<{ annonces: Annonce[], searchLocation: SearchLocation }> {
    return this.getPosition().pipe(
      switchMap(({ lat, lng }) => {
        const coordonate = `${lat}%2C${lng}`;

        return forkJoin({
          annonces: this.annoncesService.getAnnoncesGeoloc('Vente', coordonate, 5, ANNONCE_STATE).pipe(
            filter(({ data }) => !!data.length),
            map(({ data }) => data)
          ),
          searchLocation: this.geographyService.geoVilleSearch(lat, lng).pipe(
            //filter(({ data }) => !!data.length),
            map(({ data }) => this.geoVilleToSearchLocation(data[0]))
          )
        });
      })
    );
  }

  getSearchLocationFromPosition(): Observable<SearchLocation> {
    return this.getPosition().pipe(
      switchMap(({ lat, lng }) => this.geographyService.geoVilleSearch(lat, lng)),
      //filter(({ data }) => !!data.length),
      map(({ data }) => this.geoVilleToSearchLocation(data[0]))
    );
  }

  geoVilleToSearchLocation(ville: GeoVille): SearchLocation {
    return {
      ...ville,
      id_city: ville.id,
      name: ville.nom
    } as unknown as SearchLocation;
  }
}
