import { HttpClient, HttpBackend } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ConfigHttpClient {
  private httpClient: HttpClient;

  /**
   * Special HTTP Client to bypass interceptors
   * @param handler
   */
  constructor(handler: HttpBackend) {
    this.httpClient = new HttpClient(handler);
  }

  getFile<T>(url: string) {
    return this.httpClient.get<T>(url);
  }
}
