import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-donut',
  templateUrl: './donut.component.html',
  styleUrl: './donut.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DonutComponent {
  @Input() size: 'large' | 'small' | 'tiny' = 'small';
  @Input() first: number;
  @Input() second: number;
  @Input() text?: string;
  @Input() textSub?: string;
}
