import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-bottom-content',
  templateUrl: './bottom-content.component.html',
  styleUrls: ['./bottom-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BottomContentComponent {
  @Input() content: { title: string, text: string }[] = [];
  @Input() pictureUrl: string;
  @Input() title?: string;

  public full = false;
}
