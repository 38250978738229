import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';

import { MetadataService } from '@/services/metadata.service';
import { AgencesService } from '@/services/agences.service';
import { MainRoutes } from '@/constants';

@Component({
  selector: 'app-open-houses',
  templateUrl: './open-houses.component.html',
  styleUrls: ['./open-houses.component.scss']
})
export class OpenHousesComponent implements OnInit {
  MainRoutes = MainRoutes;

  totalOffres: number = 0;
  openings;
  itemsPerPage: number = 8;
  currentPage = 1;
  totalItems = 0;
  lastPage = 1;
  agence;
  agenceId = null;
  agenceGroupementId = null;
  public loading = false;
  public agenceResult;
  public team;
  pathUrl;

  @ViewChild('content', { static: true }) content;

  constructor(
    private route: ActivatedRoute,
    private location: Location,
    private meta: MetadataService,
    private agencesService: AgencesService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.pathUrl = this.router.url;
    const pathWithoutHash = this.location.path(false);
    this.location.replaceState(pathWithoutHash);
    this.loading = true;
    // seo
    this.route.data.subscribe(({ title, description, ogTitle, ogDescription, ogUrl }) => {
      this.meta.setMetadata({
        title: title,
        description: description,
        ogTitle: ogTitle,
        ogDescription: ogDescription,
        ogUrl: ogUrl
      });
    });

    this.route.fragment.subscribe((fragment: string) => {
      this.team = fragment;
    });

    if (this.route.routeConfig.path.includes('groupes')) {
      this.agenceGroupementId = +this.route.snapshot.params['slug'].split(/[-]+/).pop();
    } else {
      this.agenceId = +this.route.snapshot.params['slug'].split(/[-]+/).pop();
    }

    this.getPortesOvertes();
  }

  getPortesOvertes() {
    if (this.agenceId) {
      this.agencesService.getAgenceById(this.agenceId).subscribe((data) => {
        this.agence = data.data;
        this.openings = this.agence.annonces.filter((el) => el.openhouse);
        this.totalItems = this.openings.length;
        this.lastPage = Math.ceil(this.openings.length / this.itemsPerPage);
      });
    } else {

      this.agencesService.getAgenceGroupesById(this.agenceGroupementId).subscribe((data) => {
        this.agence = data.data;

        this.openings = this.agence.annonces.filter((el) => el.openhouse);

        const res = this.agence.agences.filter((el) => this.openings.some((x) =>
          x.era_agence_id === el.era_id));

        res.filter((el) => {
          this.agencesService.getAgenceById(el.id).subscribe((data) => {
            this.agenceResult = data.data;
          });
        });

        this.totalItems = this.openings.length;
        this.lastPage = Math.ceil(this.openings.length / this.itemsPerPage);
      });
    }
  }

  goBack() {
    this.location.back();
  }

  changePage(event) {
    this.currentPage = event;
    this.getPortesOvertes();
    window.scrollTo({ top: this.content.nativeElement.getBoundingClientRect().top, behavior: 'smooth' });
  }
}
