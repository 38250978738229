<div class="box flex flex-col">
	<div class="flex items-center mb-20px">
		<img src="/assets/imgs/suitcase.png" />
		<h2 class="display-h5 ml-10px display-text-20px">
			{{ poste.titre }}
		</h2>
	</div>

	<div class="tag mb-20px display-text-12px">
		{{ (poste.siege ? 'headquarters' : 'Agencies') | transloco }}
	</div>

	<p class="display-text-16px lh-32px mb-auto description" [innerHTML]="poste.description | truncate: 20"></p>

	@if (!emploi && !poste.siege) {
		<app-button text="see job offer" [link]="MainRoutes.OffreEmploi + '/poste/' + poste.id" />
	}

	<div class="display-text-16px lh-32px text-left font-bold">
		<p>{{ poste?.enseigne }}</p>
	</div>

	@if (emploi) {
		<app-button text="apply" [link]="MainRoutes.OffreEmploi + '/poste/'  + poste.id + '/postuler/agence/' + emploi" />
	}

	@if (poste.siege) {
		<app-button text="apply" [link]="MainRoutes.OffreEmploi + '/postuler/' + poste.id" fragment="siege" />
	}
</div>
