<form class="form-contact" [formGroup]="personnalDetails" autocomplete="off">
  @if (!estimation) {
    @if (!tag) {
      <p class="display-text-18px mb-45px">
        {{ (currentRoute.type === MainRoutes.Effectifs ? 'contact_me' : 'contact_us') | transloco }}
      </p>
    } @else if ((tag === 'gestion locative' || tag === 'gestion de syndic')) {
      <p class="display-text-18px mb-45px">
        {{ ( 'entrust_project' | transloco ) + tag }}
      </p>
    } @else if ((tag === 'locaux commerciaux')) {
        <p class="display-text-18px mb-45px">
          {{ 'Entrust_us_your_project' | transloco }}
        </p>
    }
  }

	<div class="form-inputs">
    @if (showCivility) {
      <app-form-civility formControlName="civilite" [validationErrors]="submitted" />
    }

    <app-form-input id="firstname" label="firstname" icon="icon-user" formControlName="firstname" [validationErrors]="submitted" />

    <app-form-input id="lastname" label="name" icon="icon-user" formControlName="lastname" [validationErrors]="submitted" />

    <app-form-input-telephone formControlName="phone" label="portable" id="phone" [validationErrors]="submitted" />

    <app-form-input id="email" label="email" icon="icon-email" formControlName="email" [validationErrors]="submitted" />

    @if (showInputGeoloc) {
      <app-geoloc-input mode="address" (geolocChanged)="handleAutocompleteSelect($event)" />
    }

    @if (this.agenciesOfDepartment.length > 0) {
      <app-form-select id="agence_id" formControlName="agence_id" [items]="agenciesOfDepartment" itemKey="era_id" itemLabel="enseigne" label="select agency" />
    }

    @if (tag === 'locaux commerciaux') {
      <div class="switch">
        <label class="switch-item">
          <input type="radio" formControlName="transaction_type" value="TRANSAC_TYPE.FOR_SELL" name="transaction_type" />
          <span> {{ 'to_sell' | transloco }}</span>
        </label>
        <label class="switch-item">
          <input type="radio" formControlName="transaction_type" value="TRANSAC_TYPE.FOR_RENT" name="transaction_type" />
          <span> {{ 'to_rent' | transloco }}</span>
        </label>
      </div>
      <div class="input">
        <app-form-input id="project" formControlName="project" label="describe project" type="textarea" />
      </div>
    }

    @if (showSwitchPropertyToSell) {
      <div class="input-toggle">
        <p class="display-text-16px">{{ 'selling' | transloco }}<span class="colored">*</span></p>
        <div class="input-toggle-items">
          <p class="display-text-16px">{{ 'no' | transloco }}</p>
          <div>
            <label class="toggle">
              <input type="checkbox" formControlName="has_property_to_sell" />
              <span class="slider round"></span>
            </label>
          </div>
          <p class="display-text-16px">{{ 'yes' | transloco }}</p>
        </div>
      </div>
    }
	</div>

	<div class="checkbox">
		<label class="flex items-center">
			<input type="checkbox" id="contact_agreement" formControlName="contact_agreement" />
			<p class="checkmark ml-10px">{{ 'authorization' | transloco }}</p>
		</label>
	</div>
	<div class="checkbox mt-20px">
		<label class="flex items-center">
			<input type="checkbox" id="partner_agreement" formControlName="partner_agreement" />
			<p class="checkmark ml-10px">{{ 'authorization_data' | transloco }}</p>
		</label>
	</div>

	<div class="block-contact-agency-asterisque">
		<p>{{ 'data' | transloco }} {{ 'data_link' | transloco }}</p>
	</div>

  @if (showSubmitButtonAnnonceAgence) {
    <div class="input-submit">
      @if (messageSuccess) {
        <div class="mt-20px mb-20px">{{ 'form_sent_success' | transloco }}</div>
      }
      @if (noAgencies) {
        <div class="mt-20px mb-20px text-error">{{ 'form_sent_error' | transloco }}</div>
      }

      @if (annonce) {
        <app-button-loading
          (click)="sendApplicationFormAnnonce()"
          [disabled]="personnalDetails.invalid && submitted"
          [loading]="loading"
          text="appointment"
        />
      }

      @if (agence) {
        <app-button-loading
          (click)="sendApplicationFormAgence()"
          [disabled]="personnalDetails.invalid && submitted"
          [loading]="loading"
          text="appointment"
        />
      }
    </div>
  }

  @if (estimation) {
    <div class="input-submit">
      <app-button-loading
        (click)="sendApplicationFormEstimation()"
        [disabled]="personnalDetails.invalid && submitted"
        [loading]="loading"
        text="submit"
      />
    </div>
  }

  @if (showSubmitButtonMetier) {
    <div class="input-submit">
      @if (messageSuccess) {
        <div class="mt-20px mb-20px">{{ 'form_sent_success' | transloco }}</div>
      }
      @if (noAgencies) {
        <div class="mt-20px mb-20px text-error">{{ 'form_sent_error' | transloco }}</div>
      }

      <app-button-loading
        (click)="sendApplicationFormMetier()"
        [disabled]="personnalDetails.invalid && submitted"
        [loading]="loading"
        text="submit"
      />
    </div>
  }

  @if (annonce) {
    <p class="display-text-16px reference text-center">
      {{ 'reference' | transloco }} {{ 'ad' | transloco }} : <span>{{ annonce.reference }}</span>
    </p>
  }

	<input type="hidden" id="g-recaptcha-response" name="g-recaptcha-response" />
	<input type="hidden" name="action" value="validate_captcha" />
</form>
