<div
  class="image-container"
  [ngClass]="{
    firstCardBlog: index === 0 && page === 'blog',
    secondAndThirdCardBlog: (index === 1 || index === 2) && page === 'blog',
    otherCardBlog: index > 2 && page === 'blog',
    firstCardHome: index === 0 && page === 'home',
    secondAndThirdCardHome: (index === 1 || index === 2) && page === 'home',
    fourthCardHome: index === 3 && page === 'home',
    cardAgence: page === 'agence'
  }"
>
  <a [routerLink]="[link]" [attr.aria-label]="actualite.titre">
    <img
      [src]="actualite.image ? actualite.image : '/assets/imgs/actualite-era-default.jpg'"
      (error)="onImgError($event)"
      [alt]="actualite.titre"
    />
  </a>

  <div class="tags">
    @for (tag of actualite.tags; track tag.id) {
      <div class="tag display-text-12px mb-5px" [ngClass]="page === 'home' ? 'home' : ''">
        {{ tag.nom }}
      </div>
    }
  </div>

  <div class="publication-date-tag display-text-12px">
    {{ actualite.published_at | date }}
  </div>

  <div class="social-network-tag">
    <div class="network">
      <div class="network-item" (click)="shareArticleDetails(actualite)">
        <span class="item"><img src="/assets/imgs/facebook.svg" alt="facebook" /></span>
      </div>
      <a
        href="https://twitter.com/share?url={{ url_front }}/blog-immobilier/article/{{ actualite.id }}"
        rel="nofollow"
        target="_blank"
        class="network-item"
      >
        <span class="item"><img src="/assets/imgs/twitter.svg" alt="twitter" /></span>
      </a>
      <a
        href="mailto:?subject=Actualit&eacute;%20&body={{ url_front }}/blog-immobilier/article/{{ actualite.id }}"
        rel="nofollow"
        target="_blank"
        class="network-item"
      >
        <span class="item"><img src="/assets/imgs/mail.svg" alt="mail" /></span>
      </a>
    </div>
  </div>
</div>

<a class="details" [routerLink]="[MainRoutes.Blog + '/article/' + actualite.id]">
  <p class="title">
    {{ actualite.titre }}
  </p>
  @if (actualite.accroche && (index !== 0 && index !== 1 && index !== 2)) {
    <p [hidden]="index === 3 && page === 'home'" class="display-text-16px accroche">
      <span>{{ actualite.accroche.slice(0, 75) + "..." }}</span>
    </p>
  }
</a>
