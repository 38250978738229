import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-rating-bars',
  templateUrl: './rating-bars.component.html',
  styleUrl: './rating-bars.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RatingBarsComponent {
  @Input() notes!: number[];
  @Input() total!: number;

  @Output() barClicked = new EventEmitter<number>();

  handleBarClick(index: number): void {
    this.barClicked.emit(index);
  }
}
