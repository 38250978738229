import { AfterViewInit, Component, ElementRef, Inject, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-modal-container',
  templateUrl: './modal-container.component.html',
  styleUrl: './modal-container.component.scss'
})
export class ModalContainerComponent implements AfterViewInit, OnInit, OnDestroy {
  @Input() color = '';

  @ViewChild('container') container: ElementRef;

  private body: HTMLElement;

  constructor(@Inject(DOCUMENT) document: Document) {
    this.body = document.getElementsByTagName('body')[0];
  }

  ngOnInit(): void {
    this.body.classList.add('no-scroll');
  }

  ngOnDestroy(): void {
    this.body.classList.remove('no-scroll');
  }

  ngAfterViewInit(): void {
    this.container.nativeElement.style['background-color'] = this.color || 'white';
  }
}
