import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { Actualite, Agence } from '@/models';
import { BlogPageMode } from '../blog-card/blog-card.component';

@Component({
  selector: 'app-actualites-container',
  templateUrl: './actualites-container.component.html',
  styleUrl: './actualites-container.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ActualitesContainerComponent {
  @Input() actualites?: Actualite[];
  @Input() agence?: Agence;
  @Input() groupe = false;
  @Input() page: BlogPageMode;
}
