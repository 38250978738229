import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

import { Agence, Annonce, GroupeAgencesAvis, Negociateur } from '@/models';
import { AgencesService } from '@/services/agences.service';
import { MainRoutes } from '@/constants';

@Component({
  selector: 'app-estimation-card',
  templateUrl: './estimation-card.component.html',
  styleUrls: ['./estimation-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EstimationCardComponent implements OnInit, OnDestroy {
  @Input() type: 'agence' | 'annonces' | 'contact' | 'portes-ouvertes';
  @Input() agenceSlug: string;
  @Input() agence: Agence;
  @Input() groupeAgence: GroupeAgencesAvis;
  @Input() annonce: Annonce;
  @Input() team: Negociateur;

  private subscriptions = new Subscription();

  public MainRoutes = MainRoutes;
  public queryParams = {};
  public pathUrl: string;

  constructor(
    private agancesService: AgencesService,
    private cd: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.subscriptions.add(
      this.agancesService.agence$.subscribe((agence) => {
        if (agence) {
          const { type, data } = agence;
          this.agence = data;
          this.queryParams = (type === 'groupe') ? { groupe_id: this.agence.id } : { agence_id: this.agence.id };
          this.cd.markForCheck();
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
