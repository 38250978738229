<div class="input-field">
  @let textarea = inputType === 'textarea';

  <div class="input-field-label" [ngClass]="{ error: error && validationErrors, textarea }">

    @if (textarea) {
      <textarea [id]="id" [(ngModel)]="value" [disabled]="disabled" (focus)="focus = true" (blur)="focus = false"></textarea>
    } @else {
      <input [id]="id" [type]="inputType" [(ngModel)]="value" [disabled]="disabled" (focus)="focus = true" (blur)="focus = false" />
    }

    @let noValue = value === '' || value === undefined || value === null;

    <label [for]="id" [ngClass]="{ transformed: noValue && !focus }">
      @if (icon) {
        <span class="{{ icon }} not-color"></span>
      }

      {{ label | transloco }}

      @if (required) {
        <span>*</span>
      }
    </label>
  </div>

  @if (eye) {
    <span
      class="cursor-pointer"
      [ngClass]="{ 'icon-eye': inputType === 'password', 'icon-eye-off': inputType === 'text' }"
      (click)="togglePasswordVisibility()"
    ></span>
  } @else if (suffix) {
    <p class="suffix" [ngClass]="{ error: error && validationErrors }">{{ suffix }}</p>
  }
</div>

@if (error && validationErrors) {
  <app-form-errors [field]="ngControl" />
}

@if (passwordTips) {
  <app-password-tips [field]="ngControl" />
}
