import { ChangeDetectionStrategy, Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-form-switch',
  templateUrl: './form-switch.component.html',
  styleUrl: './form-switch.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => FormSwitchComponent),
    multi: true
  }]
})
export class FormSwitchComponent implements ControlValueAccessor {
  @Input() label?: string;
  @Input() translate = true;

  private _value = false;

  private onChange: any = () => {};
  private onTouched: any = () => {};

  @Input()
  get value(): boolean {
    return this._value;
  }

  set value(val: boolean) {
    if (this.value !== val) {
      this._value = val;
      this.onChange(val);
      this.onTouched();
    }
  }

  // ControlValueAccessor methods

  writeValue(val: boolean): void {
    this._value = val;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
}
