<div class="input">
  <div class="input-label" [ngClass]="[showError && address.errors ? 'error' : '']">
    <input
      [ngClass]="!address.errors?.['required'] ? 'transformed' : ''"
      [formControl]="address"
      id="address"
      type="text"
    />
    <label for="address">
      <span class="icon-location not-color"></span> {{ mode | transloco }}<span>*</span>
    </label>

    @if (showCloseButton) {
      <span class="close icon-close" (click)="handleClose('input')"></span>
    }
  </div>

  @if (showError && address.errors) {
    <app-form-errors [field]="address" />
  }

  @if (showDropdown()) {
    @let list = suggestions();

    @if (list.departements || list.villes) {
      @if (mode === 'where') {

        <div class="dropdown" (clickOutside)="handleClose('dropdown')">
          <ul>
            @if (list.villes) {
              @if (list.departements) {
                <li class="separator">{{ 'cities' | transloco }}</li>
              }
              @for (item of list.villes; track item.properties.id) {
                <li
                  class="suggest font-sfText font-semibold fs-18px lh-21px flex mb-10px"
                  (click)="handleSelect(item)"
                >
                  {{ item.properties.nom ?? item.properties.label }}
                  <app-text-round-separator />
                  <span class="font-sfText fs-18px lh-21px" style="letter-spacing: 0.02em">
                    ({{ item.properties.code }})
                  </span>
                </li>
              }
            }
            @if (list.departements) {
              <li class="separator">{{ 'departments' | transloco }}</li>
              @for (item of list.departements; track item.properties.id) {
                <li
                  class="suggest font-sfText font-semibold fs-18px lh-21px flex mb-10px"
                  (click)="handleSelect(item)"
                >
                  {{ item.properties.nom ?? item.properties.label }}
                  <app-text-round-separator />
                  <span class="font-sfText fs-18px lh-21px" style="letter-spacing: 0.02em">
                    ({{ item.properties.code }})
                  </span>
                </li>
              }
            }
          </ul>
        </div>

      } @else {

        <div class="dropdown" (clickOutside)="handleClose('dropdown')">
          <ul>
            @if (list.departements) {
              <li class="separator">{{ 'departments' | transloco }}</li>
              @for (item of list.departements; track item.properties.id) {
                <li class="suggest" (click)="handleSelect(item)">
                  {{ item.properties.nom ?? item.properties.label }}
                  <app-text-round-separator />
                </li>
              }
            }
            @if (list.villes) {
              @if (list.departements) {
                <li class="separator">{{ 'cities' | transloco }}</li>
              }
              @for (item of list.villes; track item.properties.id) {
                <li class="suggest" (click)="handleSelect(item)">
                  {{ item.properties.nom ?? item.properties.label }}
                  <app-text-round-separator />
                </li>
              }
            }
          </ul>
        </div>

      }
    }
  }
</div>
