<div class="rating-bars">
  @for (note of notes; track i; let i = $index) {
    <div (click)="handleBarClick(i)" class="rating-bar">
      <p>{{ i }} <span class="icon-star-fill"></span></p>
      <div class="percentage">
        <span [style]="{ width: (note * 100) / total + '%' }"></span>
      </div>
      <p class="note">({{ note }})</p>
    </div>
  }
</div>
