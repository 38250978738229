import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-button-tag',
  templateUrl: './button-tag.component.html',
  styleUrl: './button-tag.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ButtonTagComponent {
  @Input() active = false;
}
