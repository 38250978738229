<app-opinion-title [total]="total" [rate]="rate" />

<div class="avis-clients">
  <div class="avis-clients-item">
    <app-donut size="small" [text]="satisfactionText" [first]="first_slice_satisfaction" [second]="second_slice_satisfaction" />
    <p class="display-text-14px mx-40px">
      {{ 'index of' | transloco }} <br />
      {{ 'general satisfaction' | transloco }}
    </p>
  </div>

  <div class="avis-clients-item">
    <div>
      <p class="display-h2">{{ total | number:'':'fr-FR' }}</p>
      <p class="display-text-14px">{{ 'opinions collected' | transloco }}</p>
    </div>
  </div>

  <div class="avis-clients-item">
    <app-donut size="small" [text]="recommandationText" [first]="first_slice_recommandation" [second]="second_slice_recommandation" />
    <p class="display-text-14px">
      {{ 'level' | transloco }} <br />
      {{ 'recommandation' | transloco }}s
    </p>
  </div>
</div>

<p class="display-text-12px text-center">
  {{ 'opinions collected standard' | transloco }}
</p>
