import { ChangeDetectionStrategy, Component, Input, OnInit, Self } from '@angular/core';
import { AbstractControl, ControlContainer, ControlValueAccessor, NgControl } from '@angular/forms';
import { TranslocoService } from '@jsverse/transloco';

type Civility = {
  id: number;
  value: string
};

@Component({
  selector: 'app-form-civility',
  templateUrl: './form-civility.component.html',
  styleUrl: './form-civility.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormCivilityComponent implements OnInit, ControlValueAccessor {
  @Input() validationErrors = true;

  public parentForm?: AbstractControl<any, any>;
  public formControlName?: string | number;
  public civilities: Civility[];

  constructor(
    @Self() public ngControl: NgControl,
    private controlContainer: ControlContainer,
    translate: TranslocoService
  ) {
    ngControl.valueAccessor = this;
    this.civilities = [
      { id: 1, value: translate.translate('civility_male') },
      { id: 2, value: translate.translate('civility_female') }
    ];
  }

  ngOnInit(): void {
    this.parentForm = this.controlContainer.control;
    this.formControlName = this.ngControl.name;
  }

  // ControlValueAccessor methods
  writeValue(): void {}
  registerOnChange(): void {}
  registerOnTouched(): void {}
}
