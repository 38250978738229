import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-lightbox-container',
  templateUrl: './lightbox-container.component.html',
  styleUrl: './lightbox-container.component.scss'
})
export class LightboxContainerComponent {
  @Input() contentType: 'video' | 'images' = 'images';

  @Output() closeLightbox = new EventEmitter();

  handleClose(): void {
    this.closeLightbox.emit();
  }
}
