import { ChangeDetectionStrategy, Component, Input, signal } from '@angular/core';

import { GeolocFeature, SearchLocation } from '@/models';

@Component({
  selector: 'app-search-bar-suggestion',
  templateUrl: './search-bar-suggestion.component.html',
  styleUrl: './search-bar-suggestion.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SearchBarSuggestionComponent {
  @Input() location?: SearchLocation;
  @Input() address?: GeolocFeature;

  selected = signal(false);

  select(select: boolean): void {
    this.selected.set(select);
  }
}
