import { Injectable } from '@angular/core';
import { BehaviorSubject, map, Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';

import {
  ApiResponse, ApiAgencesResponse, ApiAgenceResponse, ApiAgenceGroupeResponse,
  Agence, agenceGeoloc, SearchFilters, CurrentAgence, GroupeAgencesAvis, AgencesAvis
} from '@/models';

import { ToolsService } from './tools.service';
import { ConfigService } from './config.service';

@Injectable({
  providedIn: 'root'
})
export class AgencesService {
  private baseUrl: string;

  private agenceSubject = new BehaviorSubject<CurrentAgence>(undefined);

  get agence$() {
    return this.agenceSubject.asObservable();
  }

  get agence() {
    return this.agenceSubject.value;
  }

  constructor(
    private http: HttpClient,
    private toolsService: ToolsService,
    configService: ConfigService
  ) {
    this.baseUrl = `${configService.config.url}/agences`;
  }

  clearAgence(): void {
    this.agenceSubject.next(undefined);
  }

  getAgenceById(id: number, store = false): Observable<ApiAgenceResponse> {
    return this.http.get<ApiAgenceResponse>(`${this.baseUrl}/${id}`).pipe(
      map((result) => {
        if (store) {
          this.agenceSubject.next({ type: 'agence', data: result.data });
        }
        return result;
      })
    );
  }

  getAgenceGroupesById(id: number, store = false): Observable<ApiAgenceGroupeResponse> {
    return this.http.get<ApiAgenceGroupeResponse>(`${this.baseUrl}/groupes/${id}`).pipe(
      map((result) => {
        if (store) {
          this.agenceSubject.next({ type: 'groupe', data: result.data });
        }
        return result;
      })
    );
  }

  getTopAvisAllAgences(agenceId?: number): Observable<any> {
    return this.http.get<any>(agenceId ?
      `${this.baseUrl}/avis/top?agence_id=${agenceId}` :
      `${this.baseUrl}/avis/top`
    );
  }

  getAllAgenceAvis(): Observable<AgencesAvis> {
    return this.http.get<AgencesAvis>(`${this.baseUrl}/avis`);
  }

  getTopAgencies(): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(`${this.baseUrl}/top`);
  }

  getAgences(itemsPerPage: number, filters: SearchFilters): Observable<ApiAgencesResponse> {
    const searchFilters = { ...filters };

    if (!searchFilters.page) {
      searchFilters.page = 1;
    }

    const { searchLocations, ...keepFilters } = searchFilters;

    keepFilters.per_page = itemsPerPage;

    // Search locations parsing
    if (searchLocations?.length) {
      keepFilters.geo_ville_id = this.toolsService.arrayValueToString(searchLocations, 'id_city');
      keepFilters.geo_departement_id = this.toolsService.arrayValueToString(searchLocations, 'id_dep');
      keepFilters.geo_region_id = this.toolsService.arrayValueToString(searchLocations, 'id_reg');
    }

    const params = new HttpParams({
      fromObject: this.toolsService.sanityzeObject(keepFilters)
    });

    return this.http.get<ApiAgencesResponse>(this.baseUrl, { params }).pipe(
      map((response) => ({
        ...response,
        data: this.mapAgences(response.data)
      }))
    );
  }

  getNearestAgency(location: string, statut: number): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(`${this.baseUrl}/nearest?location=${location}&statut=${statut}`);
  }

  getAgencesGeoloc(codepostal?: string): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(`${this.baseUrl}/geoloc?codepostal=${codepostal}`);
  }

  getAgencesReviewById(id: number): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(`${this.baseUrl}/${id}/avis`);
  }

  getGroupeAgencesReviewById(id: number): Observable<GroupeAgencesAvis> {
    return this.http.get<GroupeAgencesAvis>(`${this.baseUrl}/groupes/${id}/avis`);
  }

  private mapAgences = (agences: Agence[]): Agence[] => (
    agences.map(this.mapAgence)
  );

  private mapAgence = (agence: Agence): Agence => ({
    ...agence,
    geoloc: agenceGeoloc(agence)
  });
}
