<div
	[ngClass]="[type === 'agence' || type === 'contact' || type === 'portes-ouvertes' ? 'agence' : '']"
	class="bg-red-400 estimation relative br-10px"
>
	<div class="overlay"></div>

	<div class="estimation-left">
		<div class="estimation-text">
			@if((type !== 'contact') && (type !== 'portes-ouvertes')) {
				<h2 class="display-h2 mb-18px">
					{{ 'estimate_your_property' | transloco }}.
				</h2>
			}
			@switch (type) {
				@case ('annonces') {
					<span class="display-text-18px lh-28px mb-18px">
						{{ 'supporting_and_advising_its_clients' | transloco }}.
					</span>
				}
				@case ('portes-ouvertes') {
					<h2 class="display-h2 mb-18px">
						{{ 'discover_the_agency' | transloco }}.
					</h2>
					@if (agence && !agenceSlug) {
						@if (team) {
							<app-button
								text="see my open house properties"
								[link]="['/'+ agence.slug + '-' + agence.id + '/portes-ouvertes']"
								fragment="{{ team.id }}"
							/>
						} @else {
							<app-button
								text="see my open house properties"
								[link]="['/'+ agence.slug + '-' + agence.id + '/portes-ouvertes']"
							/>
						}
					} @else if (groupeAgence && !agenceSlug) {
						<app-button
							text="see my open house properties"
							[link]="[MainRoutes.Groupes + '/' + agenceGroupement.slug + '-' + agenceGroupement.id + '/portes-ouvertes']"
						/>
					}
				}
				@case ('contact') {
					<h2 class="display-h2 mb-18px">
						{{ 'at_your_disposal' | transloco }}.
					</h2>
					@if (agenceSlug) {
						<app-button
							[link]="['/' + agenceSlug] + '/contact'"
							text="contact_us"
						/>
					} @else {
						<app-button
							text="contact_us"
							[link]="MainRoutes.Contact"
						/>
					}
				}
			}
			@if((type !== 'contact') && (type !== 'portes-ouvertes')) {
				<app-button
					[link]="MainRoutes.Vendre"
					[queryParams]="queryParams"
					text="estimate_my_property"
					id="btn_esti_card"
				/>
			}
		</div>
	</div>

	<div class="estimation-img">
		@switch (type) {
			@case ('annonces') {
				<img src="/assets/imgs/estimation-homePage.webp" alt="" class="w-9/12 object-cover" />
			}
			@case ('portes-ouvertes') {
				<img src="/assets/imgs/contact_card.png" alt="" />
			}
			@case ('contact') {
				<img src="/assets/imgs/contact_card.png" alt="" />
			}
			@case ('agence') {
				<img src="/assets/imgs/estimation-agence.png" alt="" />
			}
		}
	</div>
</div>
