import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { ButtonAspect, ButtonSize } from '../button/button.component';

@Component({
  selector: 'app-button-loading',
  templateUrl: './button-loading.component.html',
  styleUrl: './button-loading.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ButtonLoadingComponent {
  @Input() aspect?: ButtonAspect = 'arrow-red-dark';
  @Input() extraClasses = '';
  @Input() disabled = false;
  @Input() loading = false;
  @Input() text = '';
  @Input() size: ButtonSize = 'large';
  @Input() type = 'submit';
}
