import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, signal } from '@angular/core';
import { Subscription } from 'rxjs';

import { FilterTypeAnnonce, TopDatum } from '@/models';
import { FilterService } from '@/services/filter.service';
import { AnnoncesService } from '@/services/annonces.service';

type TypeBien = 'appartement' | 'maison';
type RentOrSell = 'rent' | 'sell';

type City = {
  id: number;
  nom: string;
  type: RentOrSell;
  type_bien: TypeBien;
};

const SCROLL_AMOUNT = 310;

@Component({
  selector: 'app-top-city',
  templateUrl: './top-city.component.html',
  styleUrls: ['./top-city.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TopCityComponent implements OnInit, OnDestroy {
  private subscriptions = new Subscription();

  public topCities = signal<any[]>([]);

  constructor(
    private annoncesService: AnnoncesService,
    private filterService: FilterService
  ) {}

  ngOnInit(): void {
    this.subscriptions.add(
      this.annoncesService.getTopCities('ville', 'Maison,Appartement').subscribe(({ Location, Vente }) => {
        const locationFlat = Location['Appartement'].ville.map((ville) => this.mapVille(ville, 'appartement', 'rent'));
        const locationHouse = Location['Maison'].ville.map((ville) => this.mapVille(ville, 'maison', 'rent'));
        const sellingFlat = Vente['Appartement'].ville.map((ville) => this.mapVille(ville, 'appartement', 'sell'));
        const sellingHouse = Vente['Maison'].ville.map((ville) => this.mapVille(ville, 'maison', 'sell'));

        this.topCities.set([...sellingFlat, ...sellingHouse, ...locationFlat, ...locationHouse]);
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  scrollLeft() {
    // NOT WORKING BECAUSE OF TOO LARGE SCROLL WIDTH
    // document.getElementById('top-ten').scrollTo({left: this.positionLeft + 310, behavior: 'smooth'})
    // this.positionLeft = this.positionLeft + 309 < document.getElementById('top-ten').scrollWidth ?
    //   this.positionLeft + 310 :
    //   document.getElementById('top-ten').scrollWidth;

    document.getElementById('top-ten').scrollLeft += SCROLL_AMOUNT;
  }

  scrollRight() {
    // document.getElementById('top-ten').scrollTo({left: this.positionLeft - 310, behavior: 'smooth'})
    // this.positionLeft = this.positionLeft > 309 ? this.positionLeft - 310 : 0
    document.getElementById('top-ten').scrollLeft -= SCROLL_AMOUNT;
  }

  onImgError(event) {
    event.target.src = '/assets/imgs/photo_villes/default.jpg';
  }

  getUrl(city): void {
    const type: FilterTypeAnnonce = city.type === 'rent' ? 'location' : 'vente';

    const searchLocation = {
      ...city,
      id_city: city.id,
      name: city.nom
    };

    this.filterService.updateFilters('annonces', { searchLocations: [searchLocation], type_annonce: type, typeBien: [city.type_bien] });
    this.filterService.buildRouteFromFilters({ mode: 'annonces' });
  }

  private mapVille = (ville: TopDatum, type_bien: TypeBien, type: RentOrSell): City => ({
    ...ville,
    type_bien,
    type
  });
}
