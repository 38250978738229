import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, HostBinding, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';

export type ButtonAspect =
  'red' | 'black' | 'dark-border' | 'red-border' | 'transparent' | 'blue' | 'blue-border' |
  'arrow-red-dark' | 'arrow-red' | 'arrow-blue' | 'arrow-white';

export type ButtonSize = 'fit' | 'large';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrl: './button.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ButtonComponent implements AfterViewInit, OnChanges {
  @Input() aspect: ButtonAspect = 'arrow-red-dark';
  @Input() icon = 'icon-arrow-right';
  @Input() extraClasses = '';
  @Input() disabled = false;
  @Input() text = '';
  @Input() link = '';
  @Input() size: ButtonSize = 'fit';
  @Input() wrap = false;
  @Input() translate = true;
  @Input() target = '_blank';
  @Input() fragment?: string;
  @Input() href?: string;
  @Input() queryParams?: Record<string, any>;
  @Input() id?: string;
  @Input() type?: 'button' | 'submit';

  @ViewChild('ref') private ref: ElementRef;
  @HostBinding('style.width') width: string;

  ngOnChanges(changes: SimpleChanges): void {
    const { size } = changes;

    if (size && (size.currentValue !== size.previousValue)) {
      this.width = (this.size === 'large') ? '100%' : 'fit-content';
    }
  }

  ngAfterViewInit(): void {
    if (this.ref) {
      if (this.id) {
        this.ref.nativeElement.id = this.id;
      }
      if (this.type) {
        this.ref.nativeElement.type = this.type;
      }
    }
  }
}
