import { Inject, Injectable, makeStateKey, Optional, PLATFORM_ID, TransferState } from '@angular/core';
import { BehaviorSubject, catchError, filter, map, Observable, of, take } from 'rxjs';
import { isPlatformBrowser } from '@angular/common';

import { Environment } from '@/models';
import { APP_CONFIGURATION } from '@/utilities/app-configuration.token';
import { ConfigHttpClient } from './config-http-client.service';

const CONFIG_KEY = makeStateKey<Environment>('config');

@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  private _config$ = new BehaviorSubject<Environment>(undefined);
  private _isBrowser = false;

  constructor(
    private http: ConfigHttpClient,
    private transferState: TransferState,
    @Inject(PLATFORM_ID) private platformId: any,
    @Inject(APP_CONFIGURATION) @Optional() private url: string
  ) {}

  loadConfig(): Observable<Environment> {
    this._isBrowser = isPlatformBrowser(this.platformId);

    if (this.isBrowser) {
      const config = this.transferState.get<Environment>(CONFIG_KEY, null);
      if (config) {
        this._config$.next(config);
        return of(config);
      }
      return this.fetchConfig();
    }

    if (this.config) {
      this._config$.next(this.config);
      this.transferState.set(CONFIG_KEY, this.config);
      return of(this.config);
    }

    return this.fetchConfig(this.url).pipe(
      map((config) => {
        this.transferState.set(CONFIG_KEY, config);
        return config;
      })
    );
  };

  get config$(): Observable<Environment> {
    return this._config$.asObservable().pipe(
      filter((config) => !!config),
      take(1)
    );
  }

  get config(): Environment {
    return this._config$.value;
  }

  get isBrowser(): boolean {
    return this._isBrowser;
  }

  get isServer(): boolean {
    return !this._isBrowser;
  }

  private fetchConfig(baseUrl = ''): Observable<Environment> {
    return this.http.getFile<Environment>(`${baseUrl}/assets/config/config.json`).pipe(
      map((config) => {
        this._config$.next(config);
        return config;
      }),
      catchError((error) => {
        console.error('Error retrieving application configuration file', error);
        return of(undefined);
      })
    );
  }
}
