@let phoneVisible = (showTel && phone);

<app-button
  [text]="showTel ? (phone ?? 'not_specified') : 'tel'"
  [href]="phoneVisible ? ('tel:' + phone) : undefined"
  target=""
  [translate]="!phoneVisible"
  icon="icon-call"
  [aspect]="aspect"
  [size]="size"
  [extraClasses]="extraClasses"
  (click)="show()"
/>
