import { ChangeDetectionStrategy, Component, EventEmitter, HostListener, Inject, Input, Output } from '@angular/core';
import { DOCUMENT } from '@angular/common';

import { HeaderService } from '@/services/header.service';
import { Agence, AgenceGroupe } from '@/models';
import { MainRoutes } from '@/constants';

@Component({
  selector: 'app-agence-presentation',
  templateUrl: './agence-presentation.component.html',
  styleUrls: ['./agence-presentation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AgencePresentationComponent {
  @Input() agenceGroup?: AgenceGroupe;
  @Input() agence?: Agence;

  @Output() network = new EventEmitter();

  public MainRoutes = MainRoutes;
  public isHoraireModalVisible = false;
  public isVideoModalVisible = false;
  public avis_total: number;
  public percent: number;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private headerService: HeaderService
  ) {
    this.headerService.setHeaderType('agence');
  }

  playVideo(): void {
    this.isVideoModalVisible = true;
    this.document.documentElement.scrollTop = 0;
  }

  closeVideo(): void {
    this.isVideoModalVisible = false;
  }

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent): void {
    const keyPressed = event.key;
    if (keyPressed === 'Escape') {
      this.isVideoModalVisible = false;
    }
  }

  videoEnd(): void {
    this.isVideoModalVisible = false;
  }

  averageStar(): string | undefined {
    if (this.agence.avis) {
      const total = this.agence.avis.map((score) => score.avg_score).reduce((a, b) => a + b, 0);
      const sum = total / this.agence.avis.length;

      this.percent = Math.round((sum / 5) * 100);

      return sum.toFixed(2), this.percent.toFixed(0);
    }
    return undefined;
  }

  getVideoId(): string {
    // eslint-disable-next-line @stylistic/max-len
    const regex = /(?:https?:)?(?:\/\/)?(?:[0-9A-Z-]+\.)?(?:youtu\.be\/|youtube(?:-nocookie)?\.com\S*?[^\w\s-])([\w-]{11})(?=[^\w-]|$)(?![?=&+%\w.-]*(?:['"][^<>]*>|<\/a>))[?=&+%\w.-]*/;

    if (this.agence) {
      return this.agence.video_presentation.match(regex)
        ? this.agence.video_presentation.match(regex)[1]
        : this.agence.video_presentation;
    } else if (this.agenceGroup) {
      return this.agenceGroup.video_presentation.match(regex)
        ? this.agenceGroup.video_presentation.match(regex)[1]
        : this.agenceGroup.video_presentation;
    }

    return '';
  }

  onImgError(event: any): void {
    event.target.src = '/assets/imgs/agence.jpg';
  }

  showHoraireModal(show: boolean): void {
    this.isHoraireModalVisible = show;
  }
}
