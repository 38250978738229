import { Location } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { Subscription } from 'rxjs';

import { SeoPipe } from '@/modules/shared/pipes/seo.pipe';
import { AgencesService } from '@/services/agences.service';
import { MetadataService } from '@/services/metadata.service';
import { Agence, Avis, CurrentAgence, Negociateur, SeoCrossLink, SeoFaqEntry } from '@/models';
import { MainRoutes } from '@/constants';

@Component({
  selector: 'app-negociator',
  templateUrl: './negociator.component.html',
  styleUrls: ['./negociator.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NegocitatorComponent implements OnInit, OnDestroy {
  private subscriptions = new Subscription();

  public MainRoutes = MainRoutes;

  public agence?: Agence;
  public agenceAvis: Avis[] = [];
  public percent: number;
  public teams: Negociateur[] = [];
  public slug: string;
  public seoType = 'ACHAT';
  public typeBien = '';
  public geoType = 'country';
  public crosslinks: SeoCrossLink[];
  public faqs: SeoFaqEntry[] = [];
  public singleAgence = true; // Determine if template is for one or all agencies
  public allTeam = false;

  public currentLocationObject: any = {
    nom: 'France'
  };

  constructor(
    private gtmService: GoogleTagManagerService,
    private agencesService: AgencesService,
    private meta: MetadataService,
    private route: ActivatedRoute,
    private cd: ChangeDetectorRef,
    private location: Location,
    private seoPipe: SeoPipe
  ) {}

  ngOnInit(): void {
    this.crosslinks = this.getCrossLinks();
    this.faqs = this.getFaqs();

    this.subscriptions.add(
      this.agencesService.agence$.subscribe((agence) => {
        this.setAgence(agence);
        this.cd.markForCheck();
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  backButton(): void {
    this.location.back();
  }

  private setAgence(agence: CurrentAgence) {
    if (agence) {
      this.agence = agence.data;
      this.slug = this.agence.slug;
      this.teams = this.agence.effectifs;

      const { title, description, ogTitle, ogDescription, ogUrl, allTeam } = this.route.snapshot.data;
      const { slug, enseigne, ville, codepostal } = this.agence;

      this.allTeam = allTeam;

      this.gtmService.pushTag({
        'event': 'Pageview',
        'id-agence': this.agence.id,
        'nom-agence': this.agence.enseigne,
        'pagetype': 'Equipe'
      });

      // SEO
      this.meta.setMetadata({
        title: title,
        description: description,
        ogTitle: ogTitle,
        ogDescription: ogDescription,
        ogUrl: ogUrl,
        params: {
          nom_agence: enseigne,
          slug_agence: slug,
          ville: ville,
          code_postal: codepostal
        }
      });
    }
  }

  private getCrossLinks(): SeoCrossLink[] {
    return [{
      title: 'Liste 1',
      content: [
        { label: 'Element 1', route: '/' },
        { label: 'Element 2', route: '/' },
        { label: 'Element 3', route: '/' },
        { label: 'Element 4', route: '/' },
        { label: 'Element 5', route: '/' },
        { label: 'Element 6', route: '/' }
      ]
    }, {
      title: 'Liste 2',
      content: [
        { label: 'Element 1', route: '/' },
        { label: 'Element 2', route: '/' },
        { label: 'Element 3', route: '/' },
        { label: 'Element 4', route: '/' },
        { label: 'Element 5', route: '/' },
        { label: 'Element 6', route: '/' }
      ]
    }, {
      title: 'Liste 3',
      content: [
        { label: 'Element 1', route: '/' },
        { label: 'Element 2', route: '/' },
        { label: 'Element 3', route: '/' },
        { label: 'Element 4', route: '/' },
        { label: 'Element 5', route: '/' },
        { label: 'Element 6', route: '/' }
      ]
    }, {
      title: 'Liste 4',
      content: [
        { label: 'Element 1', route: '/' },
        { label: 'Element 2', route: '/' },
        { label: 'Element 3', route: '/' },
        { label: 'Element 4', route: '/' },
        { label: 'Element 5', route: '/' },
        { label: 'Element 6', route: '/' }
      ]
    }];
  }

  private getFaqs(): SeoFaqEntry[] {
    const seoPath = this.seoPath();

    const interpolations = {
      geo_object_name: this.currentLocationObject.nom
    };

    return [{
      question: this.seoPipe.transform(seoPath + '.FAQ.0.title', interpolations),
      reponse: this.seoPipe.transform(seoPath + '.FAQ.0.content', interpolations)
    }, {
      question: this.seoPipe.transform(seoPath + '.FAQ.1.title', interpolations),
      reponse: this.seoPipe.transform(seoPath + '.FAQ.1.content', interpolations)
    }, {
      question: this.seoPipe.transform(seoPath + '.FAQ.2.title', interpolations),
      reponse: this.seoPipe.transform(seoPath + '.FAQ.2.content', interpolations)
    }, {
      question: this.seoPipe.transform(seoPath + '.FAQ.3.title', interpolations),
      reponse: this.seoPipe.transform(seoPath + '.FAQ.3.content', interpolations)
    }];
  }

  private seoPath(): string {
    let path = this.seoType;
    if (this.typeBien) {
      path += `.${this.typeBien.toUpperCase()}`;
    }
    return path + `.${this.geoType.toUpperCase()}`;
  }
}
