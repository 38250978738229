<form (clickOutside)="close()" autocomplete="off">
	<nav>
		<button (click)="changeSearchType('location')" [ngClass]="{ 'active-search': searchType === 'location' }">
			{{ 'rent_verb' | transloco }}
		</button>
		<button (click)="changeSearchType('vente')" [ngClass]="{ 'active-search': searchType === 'vente' }">
			{{ 'buy_verb' | transloco }}
		</button>
		<button (click)="changeSearchType('estimer')" [ngClass]="{ 'active-search': searchType === 'estimer' }">
			{{ 'estimate_verb' | transloco }}
		</button>
	</nav>

	<div class="input-container">
		@if (searchType !== 'estimer') {
			<div class="geoloc-button" (click)="handleAskForGeoloc()">
				<img src="/assets/imgs/position.svg" [alt]="'SEARCH.GEOLOCALIZE' | transloco" />
			</div>
		}
		<input
			[placeholder]="((searchType === 'estimer') ? 'type address' : 'type_city_or_dept') | transloco"
			[formControl]="searchInput"
			name="geoCoder"
			type="text"
		/>
		<button type="submit" (click)="onSubmitButton()" [attr.aria-label]="'to_search' | transloco">
			<img src="/assets/imgs/loupe.svg" alt="" />
		</button>
	</div>

	@if (showDropdown) {
		<div (appKeydown)="handleKeydown($event)">
			@if (searchType !== 'estimer') {
				<div class="suggestions locations">

					@if (autocompleteCities.length) {
						<section>
							@if (hasLastSearch(searchType)) {
								<div class="last-search">
									<h4>
										<i class="icon icon-clock"></i> {{ 'last_search' | transloco | uppercase }}
									</h4>
									<p (click)="goToLastSearch(searchType)">
										@for (t of lastSearchTextFromParams(searchType); track t; let last = $last) {
											<span>
												{{ t }}
												@if (!last) {
													<app-text-round-separator />
												}
											</span>
										}
									</p>
								</div>
							}

							@if ((searchType === 'vente') && savedSearchTypeSell.length) {
								<div class="saved-search">
									<h4>
										<i class="icon icon-heart-empty"></i> {{ 'saved_search' | transloco }}
									</h4>
									<div class="items">
										@for (t of savedSearchTypeSell | slice:-3; track t; let i = $index) {
											<app-search-bar-details [item]="t" (click)="searchSavedSearches(t)" />
										}
									</div>
								</div>
							}

							@if ((searchType === 'location') && savedSearchTypeRent.length) {
								<div class="saved-search">
									<h4>
										<i class="icon icon-heart-empty"></i> {{ 'saved_search' | transloco }}
									</h4>
									<div class="items">
										@for (t of savedSearchTypeRent | slice:-3; track t; let i = $index) {
											<app-search-bar-details [item]="t" (click)="searchSavedSearches(t)" />
										}
									</div>
								</div>
							}

							<header>{{ 'cities' | transloco }}</header>
							@for (city of autocompleteCities | slice:0:5; track city.id) {
								<app-search-bar-suggestion [location]="city" (click)="selectLocation(city)" />
							}
						</section>
					}

					@if (autocompleteDepartments.length) {
						<section>
							<header>{{ 'departments' | transloco }}</header>
							@for (dep of autocompleteDepartments | slice:0:5; track dep.id) {
								<app-search-bar-suggestion [location]="dep" (click)="selectLocation(dep)" />
							}
						</section>
					}

					@if (autocompleteCities.length + autocompleteDepartments.length === 0) {
						<span class="empty">{{ 'SEARCH.EMPTY' | transloco }}</span>
					}

					<div
						[routerLink]="[(this.searchType === 'vente') ? MainRoutes.Acheter : MainRoutes.Louer]"
						[state]="{ draw: true }"
						class="search-draw"
					>
						<span>{{ 'search_by_draw' | transloco }}</span> <i class="icon icon-edit"></i>
					</div>

				</div>

			} @else if (autocompleteAddresses.length) {

				<div class="suggestions addresses">
					<section>
						@for (address of autocompleteAddresses; track address) {
							<app-search-bar-suggestion [address]="address" (click)="selectAddress(address)" />
						}
					</section>
				</div>

			}

		</div>
	}

</form>