import { Component, Input, OnInit, OnDestroy, ChangeDetectionStrategy, Inject, ChangeDetectorRef } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Subscription } from 'rxjs';

import { RouteObserverService } from '@/services/route-observer.service';
import { ToolsService } from '@/services/tools.service';

@Component({
  selector: 'app-pagination-link',
  templateUrl: './pagination-link.component.html',
  styleUrls: ['./pagination-link.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PaginationLinkComponent implements OnInit, OnDestroy {
  @Input() itemsPerPage = 1;
  @Input() prompt?: string;

  private subscriptions = new Subscription();

  constructor(
    @Inject(DOCUMENT) private document: any,
    private routeObserver: RouteObserverService,
    private toolsService: ToolsService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.subscriptions.add(
      this.routeObserver.currentRoute$.subscribe(() => {
        this.cd.markForCheck();
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  getUrlPage(page: number): { route: string, queryParams: Record<string, string> } {
    return this.toolsService.updateSearchParams(this.document, { page: page.toString() });
  }
}
