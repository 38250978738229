import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';

import { Agence, Annonce, CurrentRoute, Negociateur, Source } from '@/models';
import { RouteObserverService } from '@/services/route-observer.service';
import { ToastService } from '@/modules/toast/services/toast.service';
import { AgencesService } from '@/services/agences.service';
import { GeolocService } from '@/services/geoloc.service';
import { LeadsService } from '@/services/leads.service';
import { MainRoutes } from '@/constants';

@Component({
  selector: 'app-contact-us-card',
  templateUrl: './contact-us-card.component.html',
  styleUrls: ['./contact-us-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContactUsCardComponent implements OnInit, OnDestroy {
  @Input() annonce?: Annonce;
  @Input() agence?: Agence;
  @Input() team?: Negociateur;
  @Input() estimation;
  @Input() source?: Source;
  @Input() tag?: string;

  public personnalDetails!: FormGroup;
  public submitted = false;
  public loading = false;
  public messageSuccess = false;
  public noAgencies = false;
  public agenciesOfDepartment: Agence[] = [];
  public showCivility = false;
  public showInputGeoloc = false;
  public showSwitchPropertyToSell = false;
  public showSubmitButtonAnnonceAgence = false;
  public showSubmitButtonMetier = false;
  public currentRoute?: CurrentRoute;
  public MainRoutes = MainRoutes;

  private subscriptions = new Subscription();
  private geoCoder: string;

  constructor(
    private routeObserver: RouteObserverService,
    private toastService: ToastService,
    private leadsService: LeadsService,
    private geolocService: GeolocService,
    private agencesService: AgencesService,
    private cd: ChangeDetectorRef
  ) {}

  get firstname() {
    return this.personnalDetails.get('firstname') as FormControl;
  }

  get lastname() {
    return this.personnalDetails.get('lastname') as FormControl;
  }

  get phone() {
    return this.personnalDetails.get('phone') as FormControl;
  }

  get email() {
    return this.personnalDetails.get('email') as FormControl;
  }

  get agenceId() {
    return this.personnalDetails.get('agence_id') as FormControl;
  }

  get address() {
    return this.personnalDetails.get('address') as FormControl;
  }

  ngOnInit(): void {
    this.currentRoute = this.routeObserver.currentRoute;
    const { type, url } = this.currentRoute;

    const isPro = url.includes(MainRoutes.GestionLocative) || url.includes(MainRoutes.Syndic) || url.includes(MainRoutes.LocauxCommerciaux);

    if (isPro) {
      this.showCivility = true;
    }

    if (
      type !== MainRoutes.Effectifs &&
      type !== MainRoutes.Annonce &&
      type !== MainRoutes.AgenceImmobiliere &&
      type !== MainRoutes.Groupes
    ) {
      this.showInputGeoloc = true;
    }

    if (!isPro) {
      this.showSwitchPropertyToSell = true;
    }

    if (!isPro && !this.estimation) {
      this.showSubmitButtonAnnonceAgence = true;
    }

    if (isPro && !this.annonce && !this.estimation) {
      this.showSubmitButtonMetier = true;
    }

    this.geoCoder = this.geolocService.geoCoder;

    this.personnalDetails = new FormGroup({
      civilite: new FormControl(1),
      firstname: new FormControl('', Validators.required),
      lastname: new FormControl('', Validators.required),
      email: new FormControl('', [Validators.required, Validators.email]),
      phone: new FormControl('', Validators.required),
      address: new FormControl(this.geoCoder),
      transaction_type: new FormControl('TRANSAC_TYPE.FOR_SELL'),
      has_property_to_sell: new FormControl(false),
      contact_agreement: new FormControl(false),
      partner_agreement: new FormControl(false),
      api_source: new FormControl(this.source),
      agence_id: new FormControl(''),
      project: new FormControl(''),
      message: new FormControl(''),
      debug: new FormControl(false)
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  handleAutocompleteSelect({ properties: { label, postcode } }: { properties: { label: string, postcode: string } }) {
    this.personnalDetails.patchValue({
      address: label
    });

    this.getAgenciesByDepartment(postcode);
  }

  sendApplicationFormMetier(): void {
    this.submitted = true;

    if (this.personnalDetails.valid) {
      this.loading = true;

      const { type, url } = this.currentRoute;

      const allDetails = {
        ...this.personnalDetails.value,
        phone: this.personnalDetails.value.phone.e164Number,
        call_back: true
      };

      if (type === MainRoutes.AgenceImmobiliere || type === MainRoutes.Groupes) {
        allDetails.api_source = 'SOURCE.SITE_AGENCE';
        allDetails.agence_id = this.agence?.era_id;
      } else {
        allDetails.api_source = 'SOURCE.SITE_NATIONAL';
      }

      if (this.personnalDetails.value.agence_id) {
        allDetails.agence_id = parseInt(this.personnalDetails.value.agence_id);
      }

      let metier: string;
      if (url.includes(MainRoutes.Syndic)) {
        allDetails.contact_type = 'CONTACT_TYPE.SYNDIC';
        metier = 'syndic';
      } else if (url.includes(MainRoutes.GestionLocative)) {
        allDetails.contact_type = 'CONTACT_TYPE.RENTAL_MANAGEMENT';
        metier = 'gestion';
      } else if (url.includes(MainRoutes.LocauxCommerciaux)) {
        allDetails.contact_type = 'CONTACT_TYPE.COMMERCIAL_SPACE';
        metier = 'entreprise-commerce';
        allDetails.location = this.agence ? this.agence.adresse : this.personnalDetails.value.address;
      }

      this.subscriptions.add(
        this.leadsService.customMetier(allDetails, metier).subscribe({
          next: (success: boolean) => this.successCallback(success),
          error: (error) => this.errorCallBack(error, 'sendApplicationFormMetier')
        })
      );
    }
  }

  sendApplicationFormAgence(): void {
    this.submitted = true;

    if (this.personnalDetails.valid) {
      this.loading = true;

      let agenceId: number;

      if (this.source === 'SOURCE.SITE_GROUPE') {
        agenceId = this.agence?.['era_agence_principale_id'];
      } else {
        agenceId = this.agence?.era_id;
      }

      const allDetails = {
        ...this.personnalDetails.value,
        agence_id: agenceId,
        city: this.agence?.ville,
        phone: this.personnalDetails.value.phone.e164Number,
        contact_type: 'CONTACT_TYPE.BUY_PROPERTY',
        call_back: true,
        message: 'Souhaite être contacté'
      };

      if (this.team) {
        allDetails.effectif_id = this.team.id;
      }

      this.subscriptions.add(
        this.leadsService.contact(allDetails).subscribe({
          next: (success: boolean) => this.successCallback(success),
          error: (error) => this.errorCallBack(error, 'sendApplicationFormAgence')
        })
      );
    }
  }

  sendApplicationFormEstimation(): void {
    // TODO-EL: UNUSED ??
    this.submitted = true;

    if (this.personnalDetails.valid) {
      this.loading = true;

      const { agence_id, city_name, address, city_zip } = this.estimation;
      const formValue = this.personnalDetails.value;

      const allDetails = {
        ...formValue,
        agence_id: agence_id,
        api_source: this.source,
        city: city_name,
        address: address,
        zip_code: city_zip,
        phone: formValue.phone.e164Number,
        contact_type: 'CONTACT_TYPE.SELL_PROPERTY',
        call_back: true,
        message: 'Souhaite un rendez vous au sujet de la vente de mon bien immobilier'
      };

      this.subscriptions.add(
        this.leadsService.contact(allDetails).subscribe({
          next: (success: boolean) => this.successCallback(success),
          error: (error) => this.errorCallBack(error, 'sendApplicationFormEstimation')
        })
      );
    }
  }

  sendApplicationFormAnnonce(): void {
    this.submitted = true;

    if (this.personnalDetails.valid) {
      this.loading = true;

      const allDetails = {
        ...this.personnalDetails.value,
        property_id: this.annonce ? this.annonce.era_id : this.agence?.era_id,
        agence_id: this.annonce ? this.annonce.agence.era_id : this.agence?.era_id,
        api_source: this.source,
        phone: this.personnalDetails.value.phone.e164Number,
        contact_type: this.annonce ? 'CONTACT_TYPE.BUY_PROPERTY' : 'CONTACT_TYPE.OTHER',
        message: this.annonce ? 'Souhaite un rendez vous au sujet de ce bien immobilier' : 'Souhaite un renseignement',
        call_back: true
      };

      if (this.annonce) {
        this.subscriptions.add(
          this.leadsService.annonceContact(allDetails).subscribe(
            (success: boolean) => this.successCallback(success)
          )
        );
      } else if (this.agence) {
        this.subscriptions.add(
          this.leadsService.contact(allDetails).subscribe({
            next: (success: boolean) => this.successCallback(success),
            error: (error) => this.errorCallBack(error, 'sendApplicationFormAnnonce')
          })
        );
      }
    }
  }

  private successCallback(success: boolean): void {
    if (success) {
      this.messageSuccess = true;
      this.resetForm();
      this.agenciesOfDepartment = [];

      setTimeout(() => {
        this.messageSuccess = false;
        this.cd.markForCheck();
      }, 3000);
    }

    this.loading = false;
    this.submitted = false;
    this.cd.markForCheck();
  }

  private errorCallBack(error: any, title: string): void {
    console.error('error', error);
    this.toastService.show({
      title,
      body: 'ERRORS.404',
      type: 'error'
    });

    this.loading = false;
    this.submitted = false;
    this.cd.markForCheck();
  }

  private resetForm() {
    this.personnalDetails.reset({
      civilite: 1,
      firstname: '',
      lastname: '',
      email: '',
      phone: '',
      address: this.geoCoder,
      transaction_type: 'TRANSAC_TYPE.FOR_SELL',
      has_property_to_sell: false,
      contact_agreement: false,
      partner_agreement: false,
      api_source: this.source,
      agence_id: '',
      project: '',
      message: '',
      debug: false
    });
  }

  private getAgenciesByDepartment(codepostal: string): void {
    const { url } = this.currentRoute;

    this.subscriptions.add(
      this.agencesService.getAgencesGeoloc(codepostal).subscribe((data) => {
        let metier: string;

        if (url.includes(MainRoutes.Syndic)) {
          metier = 'syndic_copropriete';
        } else if (url.includes(MainRoutes.GestionLocative)) {
          metier = 'gestion_locative';
        } else {
          metier = 'entreprise_commerce';
        }
        this.agenciesOfDepartment = data.data.filter((dep) => dep.feature_display[metier] === true);
        this.noAgencies = this.agenciesOfDepartment.length === 0;

        this.cd.markForCheck();
      })
    );
  }
}
