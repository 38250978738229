import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { SeoRouteName } from '@/models';

@Component({
  selector: 'app-landing-header',
  templateUrl: './landing-header.component.html',
  styleUrls: ['./landing-header.component.scss']
})
export class LandingHeaderComponent implements OnInit, OnDestroy {
  @Input() title: string = '';
  @Input() edito: string = '';
  @Input() image: string = 'assets/imgs/landing-header.png';
  @Input() selectPlaceholder: string = 'Sélectionner une région';
  @Input() options: SeoRouteName[] = [];

  private subscriptions = new Subscription();
  public route = new FormControl('');

  constructor(private router: Router) {}

  ngOnInit(): void {
    this.subscriptions.add(
      this.route.valueChanges.subscribe((newRoute) => {
        this.router.navigateByUrl(newRoute);
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
