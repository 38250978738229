import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { Agence } from '@/models';

@Component({
  selector: 'app-agence-group-compose',
  templateUrl: './agence-groupement.component.html',
  styleUrls: ['./agence-groupement.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AgenceGroupComposeComponent {
  @Input() agence?: Agence;
}
