import { TranslocoService } from '@jsverse/transloco';
import { BehaviorSubject, filter } from 'rxjs';
import { Injectable } from '@angular/core';

import { Toast, ToastType } from '../models/toast';

export type ShowToast = {
  type: ToastType;
  title: string;
  body: string;
  delay?: number;
  translate?: 'all' | 'title' | 'body';
};

@Injectable({
  providedIn: 'root'
})
export class ToastService {
  private _subject = new BehaviorSubject<Toast>(undefined);
  private count = 0;

  public get toast$() {
    return this._subject.asObservable().pipe(
      filter((toast) => !!toast)
    );
  }

  constructor(private translate: TranslocoService) {}

  show(toast: ShowToast): void {
    const { type, title, body, delay = 6000, translate = 'all' } = toast;

    this._subject.next({
      id: this.count++,
      type,
      title: (translate === 'all' || translate === 'title') ? this.translate.translate(title) : title,
      body: (translate === 'all' || translate === 'body') ? this.translate.translate(body) : body,
      delay
    });
  }
}
