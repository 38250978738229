<span>{{ item.city }}</span>
@if (item.typeBien) {
  <app-text-round-separator />
  <span class="type-bien">{{ item.type_bien }} </span>
}
@if (item.nb_pieces) {
  <app-text-round-separator />
  <span>{{ item.nb_pieces }} pièces</span>
}
@if (item.surface_from || item.surface_to) {
  <app-text-round-separator />
}
@if (item.surface_from) {
  <span>{{ item.surface_from }}m²</span>
}
@if (item.surface_from && item.surface_to) {
  <span> - </span>
}
@if (item.surface_to) {
  <span>{{ item.surface_to }}m²</span>
}
