import { Component, Input } from '@angular/core';

import { MainRoutes } from '@/constants';

@Component({
  selector: 'app-poste-card',
  templateUrl: './poste-card.component.html',
  styleUrls: ['./poste-card.component.scss']
})
export class PosteCardComponent {
  @Input() poste;
  @Input() emploi;
  @Input() siege;

  public MainRoutes = MainRoutes;
}
