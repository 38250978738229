import { SeoRouteName } from '@/models';

export const VILLES: SeoRouteName[] = [
  { label: 'Paris', route: '/achat/ile-de-france-1/paris-80' },
  { label: 'Marseille', route: '/achat/provence-alpes-cote-dazur-17/bouches-du-rhone-19/marseille-36745' },
  { label: 'Lyon', route: '/achat/auvergne-rhone-alpes-16/rhone-74/lyon-36744' },
  { label: 'Toulouse', route: '/achat/occitanie-15/haute-garonne-36/toulouse-13009' },
  { label: 'Nice', route: '/achat/provence-alpes-cote-dazur-17/alpes-maritimes-10/nice-13662' },
  { label: 'Nantes', route: '/achat/pays-de-la-loire-7/undefined-undefined/nantes-32185' },
  { label: 'Montpellier', route: '/achat/occitanie-15/herault-39/montpellier-23269' },
  { label: 'Strasbourg', route: '/achat/grand-est-6/bas-rhin-72/strasbourg-29907' },
  { label: 'Bordeaux', route: '/achat/nouvelle-aquitaine-9/gironde-38/bordeaux-16976' },
  { label: 'Lille', route: '/achat/hauts-de-france-5/nord-64/lille-31234' }
] as const;

export const DEPARTEMENTS: SeoRouteName[] = [
  { label: 'Vendée', route: '/achat/pays-de-la-loire-7/vendee-90' },
  { label: 'Hautes-Alpes', route: '/achat/provence-alpes-cote-dazur-17/hautes-alpes-9' },
  { label: 'Ile‑et‑Vilaine', route: '/achat/bretagne-8/ille-et-vilaine-40' },
  { label: 'Haute‑Loire', route: '/achat/auvergne-rhone-alpes-16/haute-loire-48' },
  { label: 'Maine‑et‑Loire', route: '/achat/pays-de-la-loire-7/maine-et-loire-54' },
  { label: 'Morbihan', route: '/achat/bretagne-8/morbihan-61' },
  { label: 'Indre‑et‑Loire', route: '/achat/centre-val-de-loire-2/indre-et-loire-42' },
  { label: 'Corrèze', route: '/achat/nouvelle-aquitaine-9/correze-25' },
  { label: 'Haute‑Vienne', route: '/achat/nouvelle-aquitaine-9/haute-vienne-92' },
  { label: 'Doubs', route: '/achat/bourgogne-franche-comte-3/doubs-30' }
] as const;

export const REGIONS: SeoRouteName[] = [
  { label: 'Auvergne-Rhône-Alpes', route: '/achat/auvergne-rhone-alpes-16/' },
  { label: 'Bourgogne-Franche-Comté', route: '/achat/bourgogne-franche-comte-3/' },
  { label: 'Bretagne', route: '/achat/bretagne-8/' },
  { label: 'Centre-Val de loire', route: '/achat/centre-val-de-loire-2/' },
  { label: 'Provence-Alpes-Côte d\'Azur', route: '/achat/provence-alpes-cote-dazur-17/' },
  { label: 'Grand Est', route: '/achat/grand-est-6/' },
  { label: 'Hauts-de-France', route: '/achat/hauts-de-france-5/' },
  { label: 'Île-de-France', route: '/achat/ile-de-france-1/' },
  { label: 'Normandie', route: '/achat/normandie-4/' },
  { label: 'Nouvelle Aquitaine', route: 'achat/nouvelle-aquitaine-9/' }
] as const;
