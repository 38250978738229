import { Component, Input, Output, EventEmitter, ChangeDetectionStrategy, OnInit } from '@angular/core';

import { Actualite, ActualiteTag, Agence, CurrentRoute } from '@/models';
import { RouteObserverService } from '@/services/route-observer.service';
import { ConfigService } from '@/services/config.service';
import { MainRoutes } from '@/constants';

export type BlogPageMode = 'home' | 'blog' | 'agence';

@Component({
  selector: 'app-blog-card',
  templateUrl: './blog-card.component.html',
  styleUrls: ['./blog-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BlogCardComponent implements OnInit {
  @Input() page: BlogPageMode;
  @Input() actualite!: Actualite;
  @Input() tag: ActualiteTag;
  @Input() agence?: Agence;
  @Input() groupe = false;
  @Input() index = 0;

  @Output() actualiteEmt = new EventEmitter();

  public MainRoutes = MainRoutes;
  public route: CurrentRoute;
  public url_front: string;
  public link: string;

  constructor(
    routeObserver: RouteObserverService,
    configService: ConfigService
  ) {
    this.url_front = configService.config.url_front;
    this.route = routeObserver.currentRoute;
  }

  ngOnInit(): void {
    if (!this.agence) {
      this.link = `${MainRoutes.Blog}/article/${this.actualite.id}`;
    } else if (this.route.type === MainRoutes.Groupes) {
      this.link = `${MainRoutes.Groupes}/${this.agence.slug}${MainRoutes.Blog}/article/${this.actualite.id}`;
    } else {
      this.link = `/${this.agence?.slug}${MainRoutes.Blog}/article/${this.actualite.id}`;
    }
  }

  shareArticleDetails(article) {
    const urlToShare = `https://www.facebook.com/sharer/sharer.php?u=${this.url_front}/blog-immobilier/article/${this.actualite.id};src=sdkpreparse`;
    this.actualiteEmt.emit({ article, urlToShare });
  }

  onImgError(event: any) {
    event.target.src = '/assets/imgs/actualite-era-default.jpg';
  }
}
