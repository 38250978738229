import { Directive, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({
  selector: '[appKeydown]'
})
export class KeydownDirective {
  @Output() appKeydown = new EventEmitter<any>();

  @HostListener('document:keydown', ['$event'])
  onKeyDown(event: any): void {
    if (['Enter', 'ArrowDown', 'ArrowUp'].includes(event.code)) {
      event.preventDefault();
      event.stopPropagation();

      this.appKeydown.emit(event);
    }
  }
}
