<a [routerLink]="[MainRoutes.Annonce, annonce.id]">
	<div class="br-10px relative img-container">
		<div class="red-tags">
			<div class="{{ annonce.mandat_exclusif === 1 && annonce.statut === 0 ? 'red-tag display-text-12px' : 'hidden' }}">
				<span>{{ 'exclusivity' | transloco }}</span>
			</div>
			<div class="{{ annonce.statut === 1 ? 'red-tag display-text-12px' : 'hidden' }}">
				<span>{{ 'preliminary_sale_agreement_c' | transloco }}</span>
			</div>
			<div class="{{ annonce.statut === 11 ? 'red-tag display-text-12px' : 'hidden' }}">
				<span>{{ 'preliminary_sale_agreement' | transloco }}</span>
			</div>
			<div class="{{ annonce.coup_coeur === 1 ? 'red-tag display-text-12px heart-throb' : 'hidden' }}">
				<span>{{ 'heart_throb' | transloco }}</span>
			</div>
		</div>

		<img
			class="h-full w-full object-cover img-bien"
			[src]="annonce.photo[0]"
			(error)="onImgError($event)"
			alt=""
		/>
	</div>
</a>

<div class="typeBien mt-12px">
	<a [routerLink]="[MainRoutes.Annonce, annonce.id]">
		<div>
			<div class="display-text-16px">{{ annonce.type_bien }}</div>
			<!-- <div class="display-text-16px date">
				depuis le <span>{{ annonce.date_publication | date }}</span>
			</div> -->
		</div>
	</a>
	<button (click)="onRemoveFromfavoris(annonce.id)">
		<div class="button-favoris">
			<img src="/assets/imgs/heart-fill-icon.png" height="12px" width="16px" />
		</div>
	</button>
</div>

<a class="adresses" [routerLink]="[MainRoutes.Annonce, annonce.id]">
	<div class="adresse-bien">
		<span class="icon-location color-location"></span>
		<span class="display-text-16px ville">
			{{ annonce.ville }} ({{ annonce.code_postal }})
		</span>
	</div>
	<div class="mt-30px mb-30px">
		<span class="display-text-24px prix">
			{{ numberWithSpaces(annonce.prix) }}&nbsp;€
		</span>
	</div>
	<div class="display-text-18px enseigne">
		{{ annonce.agence?.enseigne }}
	</div>
	<div class="adresse-agence">
		<span class="icon-location color-location"></span>
		<span class="display-text-16px ville">
			{{ annonce.agence?.adresse }}, {{ annonce.agence?.codepostal }} {{ annonce.agence?.ville }}
		</span>
	</div>
</a>

<div class="contact-content">
	<app-button-telephone [telephone]="annonce.agence?.telephone" aspect="arrow-blue" size="large" />
	<app-button [id]="index" [link]="'/' + annonce.agence?.slug + '/contact'" text="contact" aspect="arrow-blue" size="large" />
</div>
