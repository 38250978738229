import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// MODULES
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { TranslocoModule } from '@jsverse/transloco';

import { DirectivesModule } from '@/modules/directives/directives.module';

// PIPES
import { MapTypebienApiToDisplayPipe } from './pipes/mapTypebienApiToDisplay.pipe';
import { Convert, AddSpaceAfterComa } from './pipes/enhancer.pipe';
import { SanitizeUrlPipe } from './pipes/sanitizeUrl.pipe';
import { TruncatePipe } from './pipes/truncate.pipe';
import { SafeHtmlPipe } from './pipes/safeHtml.pipe';
import { SortBy } from './pipes/sort-by.pipe';
import { SeoPipe } from './pipes/seo.pipe';

// COMPONENTS
import { LandingHeaderComponent } from './components/landing-header/landing-header.component';
import { ContactFormComponent } from './components/contact-form/contact-form.component';
import { OpenHousesCardComponent } from './components/open-houses-card/open-houses-card.component';
import { OpenHousesComponent } from './components/open-houses/open-houses.component';
import { MetiersComponent } from './components/metiers/metiers.component';
import { AnnonceCardComponent } from './components/annonce-card/annonce-card.component';
import { ContactUsCardComponent } from './components/contact-us-card/contact-us-card.component';
import { BlogCardComponent } from './components/blog-card/blog-card.component';
import { StatementCardComponent } from './components/statement-card/statement-card.component';
import { VenduComponent } from './components/vendu/vendu.component';
import { EmploiComponent } from './components/emploi/emploi.component';
import { ContactCardComponent } from './components/contact-card/contact-card.component';
import { ContactAgencyCardComponent } from './components/contact-agency-card/contact-agency-card.component';
import { OpinionComponent } from './components/opinion/opinion.component';
import { OpinionGlobalComponent } from './components/opinion-global/opinion-global.component';
import { OpinionTitleComponent } from './components/opinion-title/opinion-title.component';
import { TeamCardComponent } from './components/team-card/team-card.component';
import { EstimationCardComponent } from './components/estimation-card/estimation-card.component';
import { NetworkComponent } from './components/network/network.component';
import { StatementAllComponent } from './components/statement-all/statement-all.component';
import { StatementComponent } from './components/statement/statement.component';
import { NegocitatorComponent } from './components/negociator/negociator.component';
import { TopCityComponent } from './components/top-city/top-city.component';
import { ModalLegalNoticeComponent } from './components/modal-legal-notice/modal-legal-notice.component';
import { LegalNoticeComponent } from './components/legal-notice/legal-notice.component';
import { SearchBarComponent } from './components/search-bar/search-bar.component';
import { FaqContainerComponent } from './components/faq-container/faq-container.component';
import { FaqComponent } from './components/faq/faq.component';
import { BottomContentComponent } from './components/bottom-content/bottom-content.component';
import { AgencePresentationComponent } from './components/agence-presentation/agence-presentation.component';
import { PresentationAgencyCardComponent } from './components/presentation-agency-card/presentation-agency-card.component';
import { BlogComponent } from './components/blog/blog.component';
import { NothingFoundComponent } from './components/nothing-found/nothing-found.component';
import { StatementAgenceCardComponent } from './components/statement-agence-card/statement-agence-card.component';
import { FavorisCardComponent } from './components/favoris-card/favoris-card.component';
import { AgenceGroupComposeComponent } from './components/agence-groupement/agence-groupement.component';
import { AgenceHorairesComponent } from './components/agence-horaires/agence-horaires.component';
import { AgenceCardComponent } from './components/agence-card/agence-card.component';
import { AgencesPropertiesComponent } from './components/agences-properties/agences-properties.component';
import { AgenceCardLoaderComponent } from './components/agence-card-loader/agence-card-loader.component';
import { PosteCardComponent } from './components/poste-card/poste-card.component';
import { ActualitesContainerComponent } from './components/actualites-container/actualites-container.component';
import { SearchBarSuggestionComponent } from './components/search-bar/search-bar-suggestion/search-bar-suggestion.component';
import { SearchBarDetailsComponent } from './components/search-bar/search-bar-details/search-bar-details.component';

// INTERNAL USE ONLY
import { UiModule } from '@/modules/ui/ui.module';

const MODULES = [
  RouterModule,
  FormsModule,
  ReactiveFormsModule,
  TranslocoModule,
  DirectivesModule
] as const;

const PIPES = [
  SeoPipe,
  TruncatePipe,
  SortBy,
  SanitizeUrlPipe,
  MapTypebienApiToDisplayPipe,
  SafeHtmlPipe,
  Convert,
  AddSpaceAfterComa
] as const;

const COMPONENTS = [
  LandingHeaderComponent,
  ContactFormComponent,
  OpenHousesComponent,
  MetiersComponent,
  AnnonceCardComponent,
  ContactUsCardComponent,
  BlogCardComponent,
  StatementCardComponent,
  VenduComponent,
  EmploiComponent,
  ContactCardComponent,
  ContactAgencyCardComponent,
  OpinionComponent,
  OpinionGlobalComponent,
  TeamCardComponent,
  EstimationCardComponent,
  NetworkComponent,
  StatementAllComponent,
  StatementComponent,
  NegocitatorComponent,
  TopCityComponent,
  ModalLegalNoticeComponent,
  LegalNoticeComponent,
  SearchBarComponent,
  FaqContainerComponent,
  FaqComponent,
  BottomContentComponent,
  AgencePresentationComponent,
  PresentationAgencyCardComponent,
  BlogComponent,
  NothingFoundComponent,
  StatementAgenceCardComponent,
  FavorisCardComponent,
  AgenceGroupComposeComponent,
  AgenceHorairesComponent,
  AgenceCardComponent,
  AgencesPropertiesComponent,
  ActualitesContainerComponent
] as const;

@NgModule({
  declarations: [
    ...PIPES,
    ...COMPONENTS,
    OpenHousesCardComponent,
    AgenceCardLoaderComponent,
    PosteCardComponent,
    OpinionTitleComponent,
    SearchBarSuggestionComponent,
    SearchBarDetailsComponent
  ],
  imports: [
    CommonModule,
    UiModule,
    ...MODULES
  ],
  exports: [
    ...PIPES,
    ...MODULES,
    ...COMPONENTS
  ],
  providers: [
    SeoPipe
  ]
})
export class SharedModule { }
