@let s1 = first * 100;
@let s2 = second * 100;
@let x = 20;
@let r = 15.91549430918954;
@let stroke = (size === 'large') ? 5 : (size === 'small') ? 3.5 : 2.5;

<div class="svg-item {{size}}">
  <svg width="100%" height="100%" viewBox="0 0 40 40" style="--first: {{s1}}; --second: {{s2}};">
    <circle
			class="donut-hole"
			[attr.cx]="x"
			[attr.cy]="x"
			[attr.r]="r"
			fill="#fff"
		></circle>

    <circle
			class="donut-ring"
			[attr.cx]="x"
			[attr.cy]="x"
			[attr.r]="r"
			fill="transparent"
			[attr.stroke-width]="stroke"
		></circle>

    <circle
			class="donut-segment"
			[attr.cx]="x"
			[attr.cy]="x"
			[attr.r]="r"
			fill="transparent"
			[attr.stroke-width]="stroke"
			attr.stroke-dasharray="{{s1}} {{s2}}"
			stroke-dashoffset="25"
		></circle>

		<g class="donut-text">
      <text y="50%" transform="translate(0, 2)">
        <tspan x="50%" text-anchor="middle" class="donut-percent">{{text}}</tspan>
      </text>
      <text y="60%" transform="translate(0, 2)">
        <tspan x="50%" text-anchor="middle" class="donut-data">{{textSub}}</tspan>
      </text>
    </g>

  </svg>
</div>
