import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-tab',
  templateUrl: './tab.component.html',
  styleUrl: './tab.component.scss',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TabComponent {
  @Input() text: string;

  public active = false;

  constructor(private cd: ChangeDetectorRef) {}

  setActive(active: boolean): void {
    this.active = active;
    this.cd.markForCheck();
  }
}
