import { BoundingBox } from './geoloc.model';

export type ListDisplayMode = 'list' | 'map';

export const DEFAULT_DISPLAY_MODE: ListDisplayMode = 'list';

export type FilterTypeVendeur = 'particulier' | 'professionnel';
export type FilterTypeAnnonce = 'vente' | 'location' | 'estimer';
export type FilterTypeAction = 'acheter' | 'louer';

export type SavedSearchType = 'vente' | 'location';
export type SearchMode = 'agences' | 'annonces';

export type SearchFilters = {
  type_annonce?: FilterTypeAnnonce;
  type?: FilterTypeAction;
  exclusif?: boolean;
  coup_coeur?: boolean;
  city?: string;
  code_postal?: string;
  geo_ville_id?: number | string;
  geo_departement_id?: number | string;
  geo_region_id?: number | string;
  polygon?: string;
  distance?: string;
  location?: string;
  typeBien?: string[];
  prix_from?: string;
  prix_to?: string;
  surface_from?: string;
  surface_to?: string;
  terrain_from?: string;
  terrain_to?: string;
  nb_pieces?: string;
  nb_chambres?: string;
  shouldTriggerLoad?: boolean;
  criteres?: string[];
  agence_id?: number;
  era_groupe_id?: number;
  fields?: string;
  searchLocations?: SearchLocation[];
  page?: number;
  per_page?: number;
  display?: ListDisplayMode;
};

export type SearchLocation = {
  id: string;
  name: string;
  code: string;
  id_city?: string;
  id_dep?: string;
  id_reg?: string;
  bbox1: BoundingBox;
  bbox2: BoundingBox;
  lat?: string;
  lng?: string;
};

export type SearchGeoAutoCompletion = {
  departements: SearchLocation[];
  villes: SearchLocation[];
};
