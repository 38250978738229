import { inject, Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, filter, Observable } from 'rxjs';
import { Location } from '@angular/common';

import { MainRoutes } from '@/constants';
import { CurrentRoute, DEFAULT_DISPLAY_MODE, ListDisplayMode, SearchFilters, SearchLocation, SearchMode } from '@/models';
import { StorageKey, StorageService } from './storage.service';
import { ToolsService } from './tools.service';

// Params we must remove from the querystring since they are in the path of the url
const IGNORED_PARAMS = ['typeBien', 'searchLocations', 'type_annonce', 'type'];

export type BuildRouteOptions = {
  page?: number;
  mode?: SearchMode;
  navigate?: boolean;
};

export type RouteResult = {
  route: string | string[];
  queryParams: SearchFilters;
};

@Injectable({
  providedIn: 'root'
})
export class FilterService {
  private storageService = inject(StorageService);
  private toolsService = inject(ToolsService);
  private location = inject(Location);
  private router = inject(Router);

  private _agencesFilters = new BehaviorSubject<SearchFilters>(this.defaultFilters());
  private _filters = new BehaviorSubject<SearchFilters>(this.defaultFilters());
  private _displayMode: ListDisplayMode;

  /**
   * The annonces observable filters.
   * Do not produce any value if buildFiltersFromRoute() has not been called at least one time.
   * The route must have been detected in order to transform queryParams into filters params.
   * Even if no query params is on the current route, the type_annonce will be set.
   */
  get filters$(): Observable<SearchFilters> {
    return this._filters.asObservable().pipe(
      filter((f) => !!f.type_annonce)
    );
  }

  get agencesFilters$(): Observable<SearchFilters> {
    return this._agencesFilters.asObservable();
  }

  get filters(): SearchFilters {
    return this._filters.value;
  }

  get agencesFilters(): SearchFilters {
    return this._agencesFilters.value;
  }

  get displayMode(): ListDisplayMode {
    return this._displayMode;
  }

  constructor() {
    // Initialize list display mode from local storage
    const mode = this.storageService.getString(StorageKey.DisplayMode);
    this._displayMode = (mode === 'list' || mode === 'map') ? mode : DEFAULT_DISPLAY_MODE;
  }

  /**
   * Restore default filters
   */
  resetFilters(): void {
    this._filters.next(this.defaultFilters());
  }

  /**
   * Compare the given filters object with the current active one.
   * Exclude page number and map display mode attributes.
   * @param filters The filters to compare
   * @returns false if objects are dequals, else true.
   */
  filtersChanged(filters: SearchFilters, reference?: SearchFilters): boolean {
    reference ??= this.filters;

    return !this.toolsService.deepEqual(
      { ...reference, page: undefined },
      { ...filters, page: undefined }
    );
  }

  /**
   * Update active filters with the given partial parameters and push them to the
   * dedicated observable
   * @param params Filters that must be updated
   * @returns The new active filters
   */
  updateFilters(mode: SearchMode, params: SearchFilters): SearchFilters {
    let newFilters: SearchFilters;

    switch (mode) {
      case 'annonces':
        newFilters = { ...this._filters.value, ...params };

        if (!this.toolsService.deepEqual(this._filters.value, newFilters)) {
          if (newFilters.searchLocations.length) {
            // Only consider search saved if search location exists
            this.updateLastSearch(newFilters);
          }
          this._filters.next(newFilters);
        }

        break;

      case 'agences':
        newFilters = { ...this._agencesFilters.value, ...params };

        if (!this.toolsService.deepEqual(this._agencesFilters.value, newFilters)) {
          this._agencesFilters.next(newFilters);
        }

        break;
    }

    return newFilters;
  }

  /**
   * Set new list/map display mode if changed
   * @param display The new mode
   */
  updateDisplayMode(display: ListDisplayMode, relativeTo: ActivatedRoute): void {
    if (this._displayMode !== display) {
      this.storageService.setString(StorageKey.DisplayMode, display);
      this._displayMode = display;

      const urlTree = this.router.createUrlTree(['.'], {
        relativeTo,
        queryParams: { display },
        queryParamsHandling: 'merge'
      });

      this.location.replaceState(urlTree.toString());
    }
  }

  /**
   * Use the given filters to build a route url and its queryParams
   * @param page The requested page number
   * @returns An object containing the route and the queryParams
   */
  buildRouteFromFilters(options: BuildRouteOptions, filters?: SearchFilters): RouteResult {
    // Build route to be used for search from params & filters
    const activeFilters: SearchFilters = { ...(filters ?? this.filters) };
    const { page = 1, mode = 'annonces', navigate = true } = options;
    const route = [];

    if (mode === 'annonces') {
      route.push((activeFilters.type_annonce === 'location') ? MainRoutes.Louer : MainRoutes.Acheter);
    } else {
      route.push(MainRoutes.Agences);
    }

    if (activeFilters.searchLocations?.length) {
      const locationString = activeFilters.searchLocations.map((location) => {
        const { name, id_city, id_dep } = location;
        const type = id_city ? 'city' : 'dep';
        return (type === 'city') ? `${name}-c${id_city}` : `${name}-d${id_dep}`;
      }).join(',');

      route.push(locationString);
    }

    // We must now convert or add specific parameters that are not in the original SearchFilters type
    // Use an any type to achieve these convertions
    const params: any = activeFilters;

    if (activeFilters.criteres?.length > 0) {
      params.criteres = activeFilters.criteres.join(',');
    }

    if (activeFilters.typeBien?.length) {
      params.type_bien = activeFilters.typeBien.map((t: string) => t.toLocaleLowerCase()).join(',');
    }

    // Add special param map display mode (not seaved into filters object)
    if (this._displayMode) {
      params.display = this._displayMode;
    }

    // Clean unused filters
    const cleanedFilters = this.cleanFilters(params);
    const queryParams: SearchFilters = { ...cleanedFilters, page };

    if (navigate) {
      this.router.navigate(route, { queryParams });
    }

    return { route, queryParams };
  }

  /**
   * Convert url parameters to filters options
   * @param route The current active router route
   */
  buildFiltersFromRoute(route: CurrentRoute): void {
    const newFilters = this.defaultFilters();

    // Aggregate params from query string and route params
    const params: any = {
      ...route.queryParams,
      ...route.params
    };

    if (params.display) {
      if (params.display !== this.displayMode) {
        this.storageService.setString(StorageKey.DisplayMode, params.display);
        this._displayMode = params.display;
      }
    }

    if (params.page) newFilters.page = +params.page;
    if (params.code_postal) newFilters.code_postal = params.code_postal;
    if (params.prix_from) newFilters.prix_from = params.prix_from;
    if (params.prix_to) newFilters.prix_to = params.prix_to;
    if (params.surface_from) newFilters.surface_from = params.surface_from;
    if (params.surface_to) newFilters.surface_to = params.surface_to;
    if (params.terrain_from) newFilters.terrain_from = params.terrain_from;
    if (params.terrain_to) newFilters.terrain_to = params.terrain_to;
    if (params.nb_pieces) newFilters.nb_pieces = params.nb_pieces;
    if (params.nb_chambres) newFilters.nb_chambres = params.nb_chambres;
    if (params.polygon) newFilters.polygon = params.polygon;
    if (params.distance) newFilters.distance = params.distance;

    if (params.criteres) {
      newFilters.criteres = params.criteres.split(',');
    }

    if (params.type_bien) {
      newFilters.typeBien = params.type_bien.split(',');
    }

    // Setup search locations from route

    if (params.ville) {
      params.ville.split(',').forEach((location: string) => {
        const geoSplit = location.split(/[-]+/);
        const geoId = geoSplit.pop(); // Get last segment

        // Parse the first letter to know if it's a city, a region or a departement
        const geoType = geoId[0] === 'c' ? 'id_city' : geoId[0] === 'd' ? 'id_dep' : 'id_reg';
        const locationParams = { name: geoSplit.join('-') } as SearchLocation;

        // Remove first letter to only get the id
        locationParams[geoType] = geoId.substring(1);
        newFilters.searchLocations.push(locationParams);
      });
    }

    if (params.enseigne) {
      params.enseigne.split(',').forEach((enseigne) => {
        const locationParams = { name: enseigne } as SearchLocation;
        newFilters.searchLocations.push(locationParams);
      });
    }

    newFilters.type_annonce = (route.type === MainRoutes.Acheter) ? 'vente' : 'location';

    // Update filters params in service
    if (route.type === MainRoutes.Agences) {
      this._agencesFilters.next(newFilters);
    } else {
      this._filters.next(newFilters);
    }
  }

  /**
   * Returns the default filters settings
   * @returns A default SearchFilters object
   */
  private defaultFilters(): SearchFilters {
    return {
      page: 1,
      searchLocations: [],
      type_annonce: undefined,
      typeBien: [],
      code_postal: '',
      prix_from: '',
      prix_to: '',
      surface_from: '',
      surface_to: '',
      terrain_from: '',
      terrain_to: '',
      nb_pieces: '',
      nb_chambres: '',
      criteres: [],
      polygon: '',
      distance: ''
    };
  }

  /**
   * Clean unused filters (the one that values are not set)
   * @param filters The source filter object
   * @returns The cleaned filter object
   */
  private cleanFilters(filters: SearchFilters): SearchFilters {
    const cleanedFilters = {};

    Object.keys(filters).forEach((key) => {
      if (
        IGNORED_PARAMS.indexOf(key) < 0 &&
        filters[key] !== 0 &&
        filters[key] !== null &&
        filters[key] !== '' &&
        filters[key] !== false &&
        filters[key] !== 'false' &&
        (!Array.isArray(filters[key]) || filters[key].length > 0)
      ) {
        cleanedFilters[key] = filters[key];
      }
    });

    return cleanedFilters;
  }

  /**
   * Remember the last search request by storing it in the navigator storage.
   * @param newFilters The search parameters
   */
  private updateLastSearch(newFilters: SearchFilters): void {
    let lastSearch = this.storageService.getObject(StorageKey.LastSearch);
    if (!lastSearch) {
      lastSearch = { vente: null, location: null };
    }
    lastSearch[newFilters.type_annonce] = newFilters;
    this.storageService.setObject(StorageKey.LastSearch, lastSearch);
  }
}
