<!-- TO DO HH : ce composant est à reworker  -->
<div class="block-contact-agency">
  <app-presentation-agency-card type="noPhone" [team]="team" [agence]="agence" [annonce]="annonce" />

  <div class="block-contact-agency-link">
    @if (annonce || team) {
      @let text = team ? ('of my agency' | transloco) : '(' + (annoncesAgenceMere?.length ?? agence?.annonces_vente?.length ?? 0) + ')';

      <app-button
        size="large"
        [link]="MainRoutes.Acheter"
        [queryParams]="{ agence_id: agence?.id }"
        text="{{ 'see_all_properties' | transloco }} {{ text }}"
        [translate]="false"
      />

      <div (click)="onOpenModalLegalNotice()" class="link flex mt-20px">
        {{ 'legal_notice_agency' | transloco }}
      </div>

      @if (annonce?.agence?.avis?.length > 0) {
        <div class="agence-presentation-text-review">
          <div class="display-flex">
            @if (annonce.agence.avis.length > 1) {
              <p class="display-text-14px">
                {{ annonce.agence.avis.length }} {{ 'customers_opinion' | transloco }}
              </p>
            } @else if (annonce.agence.avis.length === 1) {
              <p class="display-text-14px">
                {{ annonce.agence?.avis_global?.total }} {{ 'customers_opinion' | transloco }}
              </p>
            }
            @if (averageStar(); as average) {
              <app-rating-stars [rate]="average" />
            }
          </div>
          @for (avis of annonce.agence.avis | slice: 0:1; track avis) {
            <div class="avis">
              <p class="display-text-16px">"{{ avis.comment | truncate:100 }}"</p>
            </div>
          }
          <a [routerLink]="['/' + agence?.slug + '/avis']" class="link mt-20px">
            {{ 'see_others_opinion' | transloco }}
          </a>
        </div>
      }

      @if ((pathUrl?.startsWith('/agence-') || team) && (agence?.avis?.length > 0)) {
        <div class="agence-presentation-text-review">
          <div class="display-flex">
            @if (agence.avis?.length > 1) {
              <p class="display-text-14px">
                {{ agence.avis?.length }} {{ 'customers_opinion' | transloco }}
              </p>
            } @else if (agence.avis?.length === 1) {
              <p class="display-text-14px">
                {{ agence?.avis_global?.total }} {{ 'customers_opinion' | transloco }}
              </p>
            }
            @if (averageStar(); as average) {
              <app-rating-stars [rate]="average" />
            }
          </div>
          @for (avis of agence.avis | slice: 0:1; track avis) {
            <div class="avis">
              <p class="display-text-16px">"{{ avis.comment | truncate:100 }}"</p>
            </div>
          }
          <a [routerLink]="['/' + agence?.slug + '/avis']" class="link mt-20px">
            {{ 'see_others_opinion' | transloco }}
          </a>
        </div>
      }
    }
  </div>

  <app-contact-card [agence]="agence" />

  <div class="block-contact-agency-asterisque">
    <p>
      {{ 'data' | transloco }}
      <a class="underline" target="_blank" rel="nofollow" href="{{ 'data_link' | transloco }}">
        {{ 'data_link' | transloco }}
      </a>
    </p>
  </div>
</div>
