<div class="phone-field" [formGroup]="parentForm">
  <div class="phone-field-label" [ngClass]="{ error: error && validationErrors }">

    <ngx-intl-tel-input
      [preferredCountries]="langList"
      [enablePlaceholder]="false"
      [separateDialCode]="true"
      [formControlName]="formControlName"
      [id]="id"
      appFocusBlur
      (focusEvent)="handleFocus($event)"
    />

    @let value = ngControl.value;
    @let noValue = value === '' || value === undefined || value === null;

    <label [for]="id" [ngClass]="{ transformed: noValue && !focus }">
      @if (icon) {
        <span class="{{ icon }} not-color"></span>
      }

      {{ label | transloco }}

      @if (required) {
        <span>*</span>
      }
    </label>
  </div>
</div>

@if (error && validationErrors) {
  <app-form-errors [field]="ngControl" />
}
