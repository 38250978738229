<div class="box flex flex-col">
	<div class="image-container">
		<!-- <img src="{{ opening.photo ? opening.photo : '/assets/imgs/opening-era-default.jpg' }}" alt="" /> -->
		<p class="tag display-text-12px">{{ agence?.enseigne }} {{ groupementAgence?.enseigne}} </p>
		<img src="/assets/imgs/opening-era-default.jpg" alt="" />
	</div>
	<div class="details">
		<h2 class="display-h5 mb-12px mt-25px display-text-20px">{{"open door" | transloco }} : {{ opening?.libelle }}</h2>
		<div class="flex items-center my-auto">
			<span class="icon-calendar color-location display-text-20px"></span>
			<p class="display-text-16px lh-32px mt-auto">{{ opening?.openhouse }}</p>
		</div>
		<!-- <div class="flex items-center my-auto">
			<span class="icon-clock color-location display-text-20px"></span>
			<p class="display-text-16px lh-32px mt-auto">{{ opening.time }} - {{ opening.time }}</p>
		</div> -->
		<!-- <div class="flex items-center my-auto">
			<span class="icon-location color-location display-text-20px"></span>
			<p class="display-text-16px lh-32px mt-auto">{{ opening.address2 }}, {{ opening.zipcode }} {{ opening.city }}</p>
		</div> -->
	</div>

	<div class="description mt-30px">
		<p class="display-text-16px lh-32px mt-auto whitespace-pre-wrap" [ngClass]="{ descriptif: !more }">{{ more ? opening?.descriptif : (opening?.descriptif | truncate:100) }} </p>
		<div *ngIf="opening?.descriptif.length > 100">
			<div (click)="showMore()" class="mt-10px cursor-pointer" id="opening-click">
				<p *ngIf="!more" class="display-text-16px">{{"see_more" | transloco }}</p>
				<p *ngIf="more" class="display-text-16px">{{"see_less" | transloco }}</p>
			</div>
		</div>
	</div>

	<div class="buttons flex justify-between mt-35px w-full">
		<a [routerLink]="['/annonces/'+ opening.id ]" target="_blank" rel="noopener noreferrer" class="btn-arrow-red-dark transparent w-full mr-20px">
			<p class="text-transition">{{"see_ad" | transloco }}</p>
			<span class="icon-arrow-right fs-24px opacity-transition"></span>
		</a>

		<div class="btn-arrow-red-dark" (click)="onOpenModalOpening()">
			<p class="text-transition">{{ "register_event" | transloco }}</p>
			<span class="icon-arrow-right fs-24px opacity-transition"></span>
		</div>
	</div>
</div>

<div *ngIf="openModalOpening" class="modal-opening-subscribe-overlay">
	<div class="modal-opening-subscribe">
		<div class="container">
			<div class="container-header flex mb-15px">
				<h5 class="display-h3-28px">{{ opening.libelle }}</h5>
				<div (click)="onCloseModalOpening()">
					<span class="icon-close closeDrawModal"></span>
				</div>
			</div>
			<div class="container-content">
				<div class="infos">
					<div class="date-time mr-80px">
						<div class="flex items-center my-auto mb-36px">
							<span class="icon-calendar color-location display-text-20px"></span>
							<p class="display-text-16px lh-32px mt-auto">{{ opening.openhouse }}</p>
						</div>
						<!-- <div class="flex items-center my-auto">
							<span class="icon-clock color-location display-text-20px"></span>
							<p class="display-text-16px lh-32px mt-auto">{{ opening.time }} - {{ opening.time }}</p>
						</div> -->
					</div>
					<!--
					<div class="place mb-36px">
						<div class="flex items-center my-auto">
							<span class="icon-location color-location display-text-20px"></span>
							<p class="display-text-16px lh-32px mt-auto capitalize">{{ opening.ville }}</p>
						</div>
						<div class="flex items-center my-auto">
							<img src="/assets/imgs/googlemaps.png" class="mr-8px w-5 h-5" />
							<a
								href="http://www.google.com/maps/place/{{ opening?.latitude }}+{{ opening?.longitude }}"
								target="_blank"
								class="display-text-16px lh-32px mt-auto underline"
								>{{ "see_complete_address" | transloco }}
							</a>
						</div>
					</div> -->
				</div>

				<div class="details mb-30px">
					<div class="display-text-20px mb-30px">{{"property_description" | transloco }}</div>
					<p class="display-text-16px whitespace-pre-wrap">{{ opening.descriptif }}</p>
				</div>

				<div class="user-info mt-30px">
					<div class="display-text-20px mb-46px">{{ "subscribe_event" | transloco }}</div>
					<form [formGroup]="openingForm" class="form-contact" (ngSubmit)="onSubmitOpeningsSubscribe()">
						<div class="form-inputs">
							<div class="user-info-form-fullname flex">
								<div class="input name mr-30px">
									<div class="input-label" [ngClass]="[prenom.touched && prenom.errors ? 'error' : ' ']">
										<input
											[ngClass]="!prenom.errors?.['required'] ? 'transformed ' : ''"
											id="prenom"
											type="text"
											formControlName="prenom"
										/>
										<label for="prenom"><span class="icon-user not-color"></span>{{ "firstname" | transloco }}<span>*</span>
										</label>
									</div>
									<div class="user-info-error" *ngIf="prenom.touched &&prenom.errors">
										<p *ngIf="prenom.errors?.required">{{ "field_required" | transloco }}</p>
									</div>
								</div>

								<div class="input name">
									<div class="input-label" [ngClass]="[nom.touched && nom.errors ? 'error' : ' ']">
										<input
											[ngClass]="!nom.errors?.['required'] ? 'transformed ' : ''"
											id="nom"
											type="text"
											formControlName="nom"
										/>
										<label for="nom"><span class="icon-user not-color">
											</span>{{ "name" | transloco }}<span>*</span>
										</label>
									</div>
									<div class="user-info-error" *ngIf="nom.touched && nom.errors">
										<p *ngIf="nom.errors?.required">{{ "field_required" | transloco }}</p>
									</div>
								</div>
							</div>

							<div class="input">
								<div class="input-label" [ngClass]="[email.touched && email.errors ? 'error' : ' ']">
									<input
										[ngClass]="!email.errors?.['required'] ? 'transformed ' : ''"
										id="email"
										type="email"
										formControlName="email"
									/>
									<label for="email">
										<span class="icon-email not-color"></span>{{ "email" | transloco }}<span>*</span>
									</label>
								</div>
								<div class="user-info-error" *ngIf="email.touched && email.errors">
									<p *ngIf="email.errors?.['required']">{{ "field_required" | transloco }}</p>
									<p *ngIf="email.errors?.['email']">{{ "field_incorrect" | transloco }}</p>
								</div>
							</div>

							<div class="input phone">
								<div class="input-label" [ngClass]="[telephone.touched && telephone.errors ? 'error' : ' ']">
									<ngx-intl-tel-input
										[ngClass]="!telephone.errors?.['required'] ? 'transformed ' : ''"
										[cssClass]="'phone'"
										[preferredCountries]="langList"
										[separateDialCode]="true"
										[enablePlaceholder]="true"
										id="telephone"
										formControlName="telephone"
									/>
									<label class="portable" for="telephone">
										{{ "portable" | transloco }}<span>*</span>
									</label>
								</div>
								<div class="user-info-error" *ngIf="telephone.touched && telephone.errors">
									<p *ngIf="telephone.errors?.['required']">{{ "field_required" | transloco }}</p>
									<p *ngIf="telephone.errors?.['validatePhoneNumber']?.valid === false">
										{{ "field_incorrect" | transloco }}
									</p>
								</div>
							</div>

							<div class="wrapper-button mt-40px">
								<button [ngClass]="[
										prenom.errors?.['required'] ||
										nom.errors?.['required'] ||
										email.errors?.['required'] ||
										telephone.errors?.['required'] ||
										formSubmitted
											? 'disabled'
											: ''
									]" type="submit" class="btn btn-red w-full wrapper-button-submit">
									{{ "event_form_send" | transloco }}
								</button>
							</div>

							<div *ngIf="messageSuccess" class="mt-20px">{{ "form_sent_success" | transloco }}</div>
							<div *ngIf="messageError" class="mt-20px">{{ "form_sent_error" | transloco }}</div>

							<div class="block-contact-agency-asterisque mb-20px">
								<p>{{ "data" | transloco }} {{ "data_link" | transloco }}</p>
							</div>
						</div>
					</form>
				</div>
			</div>
		</div>
	</div>
</div>