import { Component, ElementRef, ViewChild, Input, forwardRef, ChangeDetectionStrategy } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => SliderComponent),
    multi: true
  }]
})
export class SliderComponent implements ControlValueAccessor {
  @ViewChild('sliderThumb', { static: true }) sliderThumb: ElementRef<HTMLLIElement>;
  @ViewChild('sliderRange', { static: true }) sliderRange: ElementRef<HTMLLIElement>;

  @Input({ transform: (value: string) => +value }) min: number = 0;
  @Input({ transform: (value: string) => +value }) max: number = 100;
  @Input({ transform: (value: string) => +value }) step: number = 5;
  @Input({ transform: (value: string) => +value }) margin: number = 1;
  @Input() labelFormatFn: (value: number) => string;
  @Input() showLabel = true;

  private onChange: any = () => { };
  private onTouched: any = () => { };
  private _value = 0;

  get sliderThumbNative(): HTMLLIElement {
    return this.sliderThumb.nativeElement;
  }

  get sliderRangeNative(): HTMLLIElement {
    return this.sliderRange.nativeElement;
  }

  get value(): number {
    return this._value;
  }

  setNearestValue(val: number): void {
    let newValue = val;

    if (val > this.value) {
      newValue = val >= this.max ? this.max : Math.ceil(val / this.step) * this.step;
    } else {
      newValue = val <= this.min ? this.min : Math.floor(val / this.step) * this.step;
    }

    this._value = newValue;
    this.onValueChange();
  }

  setControlsValue(val: number) {
    this._value = val || 0;
    this.onValueChange(false);
  }

  onValueChange(emit = true): void {
    const percent = `${Math.floor(((this.value - this.min) / (this.max - this.min)) * 100)}%`;
    this.sliderThumbNative.style.left = percent;
    this.sliderRangeNative.style.left = percent;

    if (emit) {
      this.onChange(this.value);
      this.onTouched();
    }
  }

  onRangeClick(event: MouseEvent, track?: HTMLElement): void {
    const { clientX } = event;
    const target = track || event.target;
    const { left, width } = (target as HTMLElement).getBoundingClientRect();
    const pre = Math.floor(((clientX - left) / width) * 100);
    const val = ((pre / 100) * (this.max - this.min)) + this.min;

    this.setNearestValue(val);
  }

  labelFormat(val) {
    return (typeof this.labelFormatFn === 'function') ? this.labelFormatFn(val) : val;
  }

  trackByFn(i: number) {
    return i;
  }

  writeValue(obj: any): void {
    this.setControlsValue(obj);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(_isDisabled: boolean): void {
  }
}
