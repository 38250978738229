import { Component, Input, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';

import { MainRoutes } from '@/constants';
import { AnnonceLight } from '@/models';

@Component({
  selector: 'app-favoris-card',
  templateUrl: './favoris-card.component.html',
  styleUrls: ['./favoris-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FavorisCardComponent {
  @Input() annonce!: AnnonceLight;
  @Input() index!: number;

  @Output() toRemoveFromfavoris = new EventEmitter<number>();

  public MainRoutes = MainRoutes;
  public showCoords = [];

  onImgError(event) {
    event.target.src = '/assets/imgs/erabw.png';
  }

  numberWithSpaces(value) {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  }

  openCoords(index) {
    this.showCoords[index] = true;
  }

  addSpacePhone(phone) {
    if (!phone) {
      return 'Non renseigné';
    }
    return phone.replace(/\d{2}(?=.)/g, '$& ').replaceAll('.', '');
  }

  onRemoveFromfavoris(annonceId: number) {
    this.toRemoveFromfavoris.emit(annonceId);
  }
}
