import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

import { AnnoncesService } from '@/services/annonces.service';
import { AgencesService } from '@/services/agences.service';
import { ConfigService } from '@/services/config.service';

@Component({
  selector: 'app-open-houses-card',
  templateUrl: './open-houses-card.component.html',
  styleUrls: ['./open-houses-card.component.scss']
})
export class OpenHousesCardComponent {
  @Input() opening;
  @Input() agence;
  @Input() groupementAgence;
  @Input() team;

  public langList: string[];
  public openingForm!: FormGroup;
  public openModalOpening = false;
  public submitted = false;
  public messageSuccess = false;
  public messageError = false;
  private source = 'SOURCE.SITE_NATIONAL';
  private pathUrl: any;
  public url_front: string;
  public more = false;

  get prenom() {
    return this.openingForm.get('prenom') as FormControl;
  }

  get nom() {
    return this.openingForm.get('nom') as FormControl;
  }

  get email() {
    return this.openingForm.get('email') as FormControl;
  }

  get tekephone() {
    return this.openingForm.get('tekephone') as FormControl;
  }

  constructor(
    private annoncesService: AnnoncesService,
    public agencesService: AgencesService,
    private formBuilder: FormBuilder,
    configService: ConfigService,
    router: Router
  ) {
    this.url_front = configService.config.url_front;
    this.pathUrl = router.url;
    this.langList = ['fr'];

    this.openingForm = this.formBuilder.group({
      prenom: ['', Validators.required],
      nom: ['', Validators.required],
      email: ['', Validators.required, Validators.email],
      telephone: ['', Validators.required]
    });
  }

  showMore() {
    this.more = !this.more;
  }

  onOpenModalOpening() {
    this.openModalOpening = true;
  }

  onCloseModalOpening() {
    this.openModalOpening = false;
  }

  onSubmitOpeningsSubscribe() {
    this.submitted = true;

    if (this.openingForm.valid) {
      const { email, prenom, nom, telephone } = this.openingForm.value;

      if (this.pathUrl.includes('/agence')) {
        this.source = 'SOURCE.SITE_AGENCE';
      } else if (this.pathUrl.includes('groupes')) {
        this.source = 'SOURCE.SITE_GROUPE';
      } else {
        this.source = 'SOURCE.SITE_NATIONAL';
      }

      const allDetails = {
        telephone: telephone.nationalNumber.replace(/\s/g, ''),
        annonce_id: this.opening.id,
        api_source: this.source,
        email,
        prenom,
        nom
      };

      if (this.team) {
        allDetails['effectif_id'] = this.team;
      } else {
        allDetails['agence_id'] = this.agence.id;
      }

      this.annoncesService.subscribeToOpenHouses(allDetails).subscribe({
        next: () => {
          this.messageSuccess = true;
          this.openingForm.reset();

          setTimeout(() => {
            this.openModalOpening = false;
            this.messageSuccess = false;
          }, 3000);
        },
        error: () => {
          this.messageError = true;
        }
      });
    }
  }
}
