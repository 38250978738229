@if (now) {
  <div class="hour display-text-{{height}}px">
    <span class="icon-clock fs-20px mr-10px grey-icon"></span>
    <span>
      @if (horaires?.length) {
        @let curDay = getScheduleCurrentDay();
        {{( getScheduleDayName(curDay)) }} {{ getScheduleDayToRepr(curDay) }} {{ getScheduleDayToReprMeetOnSite(curDay) }}
      } @else {
        {{ currentDayName() }} : {{ 'not_specified' | transloco }}
      }
    </span>
  </div>
} @else {
  <div class="flex flex-col">
    @for (day of dayMap | keyvalue; track day; let index = $index) {
      <div class="grid grid-cols-3 gap-4 items-center mb-2">
        <p class="">{{ getScheduleDayName(getDayByIndex(index)) }}</p>
        <p class="whitespace-nowrap">{{ getScheduleDayToRepr(horaires[index]) }}</p>
        <p class="dayMeet">{{ getScheduleDayToReprMeetOnSite(horaires[index]) }}</p>
      </div>
    }
  </div>
}
