@if (faqs?.length) {
	<h2 class="display-h3 mb-40px">
		{{ (tag === 'locaux commerciaux' ? 'how to sell your professional property well' : 'frequently_asked_questions') | transloco }}
	</h2>
	<div class="flex flew-row flex-wrap faq">
		<app-faq
			*ngFor="let faq of faqs"
			[ngClass]="[tag === 'locaux commerciaux' ? 'full' : 'half', 'br-10px']"
			[question]="faq.question"
			[reponse]="faq.reponse"
		/>
	</div>
}
