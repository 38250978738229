import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';

import {
  ApiGeoDepartementResponse, ApiGeoDepartementsResponse, ApiGeoRegionResponse, ApiGeoRegionsResponse, ApiGeoVilleResponse,
  ApiGeoVillesResponse, GeolocSearch, SearchGeoAutoCompletion
} from '@/models';

import { ConfigService } from './config.service';

@Injectable({
  providedIn: 'root'
})
export class GeographyService {
  private baseUrl: string;

  constructor(
    private http: HttpClient,
    configService: ConfigService
  ) {
    this.baseUrl = `${configService.config.url}/geoloc`;
  }

  getRegions(): Observable<ApiGeoRegionsResponse> {
    return this.http.get<ApiGeoRegionsResponse>(`${this.baseUrl}/regions`);
  }

  getRegion(id: number): Observable<ApiGeoRegionResponse> {
    return this.http.get<ApiGeoRegionResponse>(`${this.baseUrl}/regions/${id}`);
  }

  getDepartements(): Observable<ApiGeoDepartementsResponse> {
    return this.http.get<ApiGeoDepartementsResponse>(`${this.baseUrl}/departements`);
  }

  getDepartement(id: number, withAnnonce = false): Observable<ApiGeoDepartementResponse> {
    const params = {};
    if (withAnnonce) {
      params['with_annonces'] = 1; // Add annonces into the departements and filter those without at least 1
    }
    return this.http.get<ApiGeoDepartementResponse>(`${this.baseUrl}/departements/${id}`, params);
  }

  getVilles(): Observable<ApiGeoVillesResponse> {
    return this.http.get<ApiGeoVillesResponse>(`${this.baseUrl}/villes`);
  }

  getVille(id: number, withAnnonce = false): Observable<ApiGeoVilleResponse> {
    const params = {};
    if (withAnnonce) {
      params['with_annonces'] = 1; // Add annonces into the departements and filter those without at least 1
    }
    return this.http.get<ApiGeoVilleResponse>(`${this.baseUrl}/villes/${id}`, params);
  }

  geoVilleSearch(lat: string | number, lng: string | number): Observable<ApiGeoVilleResponse> {
    return this.http.get<ApiGeoVilleResponse>(`${this.baseUrl}/villes/search?within=${lat},${lng}`);
  }

  getRandom(): Observable<any> {
    return this.http.get(`${this.baseUrl}/random`);
  }

  getAutoCompleteList(query): Observable<any> {
    return this.http.get(`${this.baseUrl}/ville-departement?query=${query}`);
  }

  getAddressAutocomplete(query: string): Observable<GeolocSearch> {
    return this.http.get<GeolocSearch>(`${this.baseUrl}/adresse?query=${query}`);
  }

  getAddressVilleAutocomplete(query: string): Observable<any> {
    return this.http.get(`${this.baseUrl}/adresse-ville?query=${query}`);
  }

  getAddressAutocompleteByStep(address: string, city_zip: number, city_name: string, _postcode: number): Observable<any> {
    return this.http.get(`${this.baseUrl}/adresse?query=${address}%20${city_zip}%20${city_name}`);
  }

  getAllAutoComplete(query: string): Observable<SearchGeoAutoCompletion> {
    return this.getAutoCompleteList(query).pipe(
      map((data) => ({
        departements: data.departements?.slice(0, 5).map((dep, i) => ({
          id: i,
          id_dep: dep.id,
          name: dep.nom,
          code: dep.code,
          bbox1: [dep.bbox[0], dep.bbox[1]],
          bbox2: [dep.bbox[2], dep.bbox[3]]
        })),
        villes: data.villes?.slice(0, 5).map((vi, i) => ({
          id: i,
          id_city: vi.id,
          name: vi.nom,
          code: vi.code_postal.split(',')[0],
          lat: vi.geoloc.lat,
          lng: vi.geoloc.lng,
          bbox1: [vi.departement.bbox[0], vi.departement.bbox[1]],
          bbox2: [vi.departement.bbox[2], vi.departement.bbox[3]]
        }))
      }))
    );
  }
}
